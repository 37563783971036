import React from "react";

interface ValueAdjusterProps {
  value: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onChange?: (newValue: number) => void;
}

const ValueAdjuster: React.FC<ValueAdjusterProps> = ({
  value,
  min,
  max,
  disabled,
  onChange,
}) => {
  return (
    <div className="number-input number-input-sm">
      {onChange !== undefined && (
        <button
          type="button"
          className="number-input-btn"
          data-field="quantity"
          disabled={min !== undefined ? value - 1 < min : false}
          onClick={() => {
            !disabled && (min !== undefined ? value - 1 >= min : true) &&
              onChange(min !== undefined ? Math.max(min, value - 1) : value - 1);
          }}
        >
          -
        </button>
      )}
      <input
        type="text"
        className="number-input-field"
        value={value}
        readOnly
      />
      {onChange !== undefined && (
        <button
          data-field="quantity"
          className="number-input-btn"
          type="button"
          onClick={() => {
            !disabled &&
              onChange(max !== undefined ? Math.min(max, value + 1) : value + 1);
          }}
        >
          +
        </button>
      )}
    </div>
  );
};

export default ValueAdjuster;
