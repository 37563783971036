/**
 * Simple hashing function
 * @param s string to be hashed
 */
export const hash = function (s: string): string {
  for (var i = 0, h = 0xdeadbeef; i < s.length; i++)
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
  return ((h ^ (h >>> 16)) >>> 0).toString();
};

/**
 * Format a phone number
 * @param value 
 * @returns 
 */
export const formatPhoneNumber = (value: string = "") => {
  if(!value) return "";
  let phone = value.replace(/\D/g, "");
  const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})(\d{0,4})$/);
  if (match) {
    phone = `(${match[1]}${match[2] ? ") " : ""}${match[2]}${
      match[3] ? "-" : ""
    }${match[3]}${match[4] ? " x" : ""}${match[4]}`;
  }
  return phone;
};
