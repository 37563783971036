import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";

interface IPaymentTypeSelectorProps {}

const PaymentTypeSelector: React.FC<IPaymentTypeSelectorProps> = () => {
  const { order } = useRestaurantContext();
  const { data, updateData } = useCheckoutContext();

  const acceptsCash =
    order?.checkout_behaviors?.allowed_payment_types?.includes("pip") ?? false;
  const acceptsCreditCards =
    order?.checkout_behaviors?.allowed_payment_types?.includes("cc") ?? false;

  if (!data) {
    return <></>;
  }

  if (!acceptsCash && !acceptsCreditCards) {
    return <>Please visit our store to complete your purchase</>;
  }

  return (
    <>
      <h5 className="mb-1">Payment Type</h5>
      
      <div className="row row-cols-md-2 g-2">
        {acceptsCash && (
          <div>
            <div className="radio-button">
              <input
                id={`paymenttype-pip`}
                className="radio-button-input"
                type="checkbox"
                value={1}
                checked={data.paymentType === "pip"}
                onChange={(e) => updateData({ paymentType: "pip" })}
              />
              <label htmlFor={`paymenttype-pip`} className="radio-button-label">
                <div className="radio-button-icon"></div>
                <div className="radio-button-content">
                  <h6 className="radio-button-title">Cash</h6>
                  <p className="radio-button-text"></p>
                </div>
                <span className="radio-button-button"></span>
              </label>
            </div>
          </div>
        )}
        {acceptsCreditCards && (
          <div>
            <div className="radio-button">
              <input
                id={`paymenttype-cc`}
                className="radio-button-input"
                type="checkbox"
                value={1}
                checked={data.paymentType === "cc"}
                onChange={(e) => updateData({ paymentType: "cc" })}
              />
              <label htmlFor={`paymenttype-cc`} className="radio-button-label">
                <div className="radio-button-icon"></div>
                <div className="radio-button-content">
                  <h6 className="radio-button-title">Card</h6>
                  <p className="radio-button-text"></p>
                </div>
                <span className="radio-button-button"></span>
              </label>
            </div>
          </div>
        )}
      </div>
      <hr />
    </>
  );
};

export default PaymentTypeSelector;
