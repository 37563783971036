const OrderWarnings: React.FC<{ restaurant: any; order: any }> = ({
  restaurant,
  order,
}) => (
  <>
    {order.view_behaviors.show_alcohol_warning && (
      <div className="alert alert-warning">
        {restaurant.alcohol_warning_title ? (
          <strong>{restaurant.alcohol_warning_title}</strong>
        ) : (
          <strong>ID Required:</strong>
        )}{" "}
        &nbsp;
        {restaurant.alcohol_warning_text ? (
          <>{restaurant.alcohol_warning_text}</>
        ) : (
          <>
            Your order contains alcoholic items, and you will be required to
            show an ID to receive your order. If you do not have ID available,
            you will not receive your order.
          </>
        )}
      </div>
    )}
    {order.view_behaviors.show_tobacco_warning && (
      <div className="alert alert-warning">
        {restaurant.tobacco_warning_text ? (
          <>{restaurant.tobacco_warning_text}</>
        ) : (
          <>
            Your order contains items with tobacco, and you will be required to
            show an ID to receive your order. If you do not have ID available,
            you will not receive your order
          </>
        )}
      </div>
    )}
  </>
);
export default OrderWarnings;
