import { getRestaurantAllowedOrderTime } from "api/endpoints";
import useRequest from "api/use-request";
import classNames from "classnames";
import OptionSelect from "components/OptionSelect";
import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import { useMemo } from "react";
import { getNext30DaysOptions } from "utils/options";

interface IOrderTimeSelectorProps {}

const OrderTimeSelector: React.FC<IOrderTimeSelectorProps> = () => {
  const { restaurant, updateOrder, setConfigBasic } = useRestaurantContext();
  const { data, updateData, updating, setUpdating } =
    useCheckoutContext();
  const { data: allowedOrderTimeResponse } = useRequest(
    getRestaurantAllowedOrderTime(
      restaurant?.id,
      data?.orderType!,
      data?.orderDate
    ),
    !!restaurant && !!data
  );
  const allowedOrderTimes = useMemo<Record<string, string>>(() => {
    return allowedOrderTimeResponse
      ? allowedOrderTimeResponse.times.reduce(
          (res: Record<string, string>, current: number) => {
            const hrs = Math.floor(current / 60);
            const mins = current % 60;
            const period = hrs < 12 ? "AM" : "PM";

            const val = `${hrs % 12 || 12}:${
              mins === 0 ? "00" : mins
            } ${period}`;

            return { ...res, [val]: val };
          },
          {}
        )
      : {};
  }, [allowedOrderTimeResponse]);

  const canSave = useMemo(() => {
    if (data?.orderType === "delivery") {
      return !!data?.primaryAddress && !!data?.zip;
    }
    if(data?.orderType === "takeout" && data?.orderingFor === "now") {
      return true;
    }
    if(data?.orderType === "takeout" && data?.orderingFor === "later") {
      return !!data?.orderDate && !!data?.eta;
    }

    return true;
  }, [data]);

  if (!data) {
    return <></>;
  }

  return (
    <>
      <div className="vstack gap-2 mt-4 p-3 bg-body-tertiary rounded-3">
        {data.orderType !== undefined && (
          <>
            {data.orderType === "takeout" && (
              <p className="mb-0 fs-md">
                When would you like to pick up your order (restaurant local
                time)?
              </p>
            )}
            {data.orderType === "delivery" && (
              <p className="mb-0 fs-md">
                When would you like us to deliver your order (restaurant local
                time)?
              </p>
            )}
            <div className="hstack flex-wrap flex-md-nowrap column-gap-3 row-gap-2">
              <div
                className={classNames(
                  "form-group",
                  data.orderingFor === "now" ? "w-100" : "w-50"
                )}
              >
                <OptionSelect
                  selection={data.orderingFor === "now" ? "" : data.orderDate}
                  options={
                    restaurant.can_order
                      ? [
                          { label: "ASAP", value: "" },
                          ...getNext30DaysOptions(restaurant.tz),
                        ]
                      : getNext30DaysOptions(restaurant.tz)
                  }
                  onChange={(value) => {
                    if (value === "") {
                      updateData({
                        orderingFor: "now",
                        orderDate: undefined,
                        eta: undefined,
                      });
                    } else {
                      updateData({
                        orderingFor: "later",
                        orderDate: value,
                        eta: undefined,
                      });
                    }
                  }}
                />
              </div>
              {data.orderingFor === "later" && (
                <div className="form-group w-50">
                  <OptionSelect
                    selection={data.eta}
                    placeholder="Choose your ETA ..."
                    options={Object.entries(allowedOrderTimes).map(
                      ([value, label]) => ({ value, label })
                    )}
                    onChange={(value) => {
                      updateData({ eta: value });
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}
        <div className="d-grid gap-1">
            <button
              className="btn btn-primary"
              type="button"
              disabled={updating || !canSave}
              onClick={async () => {
                setUpdating(true);
                setConfigBasic(true);
                await updateOrder({
                  type: data.orderType,
                  orderingFor: data.orderingFor,
                  orderDate: data.orderDate,
                  eta: data.eta,
                  primaryAddress: data.primaryAddress,
                  secondaryAddress: data.secondaryAddress,
                  zip: data.zip,
                });

                setUpdating(false);
              }}
            >
              Confirm Order Time
            </button>
        </div>
      </div>
    </>
  );
};
export default OrderTimeSelector;
