import { updateUserProfilePTX } from "api/endpoints";
import FormErrorDisplay from "components/form/Error";
import MultipleAddressFields from "components/form/MultipleAddresses";
import MultiplePhoneFields from "components/form/MultiplePhoneFields";
import { useAppContext } from "contexts/app-context";
import { useAuthContext } from "contexts/auth-context";
import { BrazeEvent, brazeEvent } from "integrations/braze";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { mapErrorToContactField } from "utils/paytronixErrors";

type ProfileContactInfoFormInputs = {
  phones: string[];
  addresses: {
    id: string;
    address: string;
    address_line_2: string;
    company: string;
    zip: string;
  }[];
};

const ProfileContactForm: React.FC = () => {
  const methods = useForm<ProfileContactInfoFormInputs>({
    mode: "onChange",
  });
  const { clearErrors, checkForPaytronixError, fieldErrors } =
    useAuthContext();
  const { cardNumber, setNetworkError, profile, accessToken } =
    useAppContext();
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid },
  } = methods;
  const [toast, setToast] = useState<{ type: string; message: string } | null>(
    null
  );
  const updateContact = useCallback(
    async (change: {
      addresses: {
        // id: string;
        address: string;
        // address_line_2: string;
        zip: string;
        // company?: string;
      }[];
      phones: string[];
    }) => {
      try {
        clearErrors("actions.auth.update_profile");
        if (!profile) return false;

        const submit = {
          address: change.addresses[0].address ?? "",
          zip: change.addresses[0].zip ?? "",
          phone: change.phones[0] ?? "",
          email: profile?.email,
          username: profile?.email,
          access_token: accessToken,
        };
        const update = await updateUserProfilePTX(
          submit,
          cardNumber
        ).fetch();
        brazeEvent(BrazeEvent.Online_Order_Update_Account, {
          address: submit.address ?? undefined,
          phone: submit.phone ?? undefined,
          zip: submit.zip ?? undefined,
        });
        return checkForPaytronixError(update, "actions.auth.update_profile");
      } catch (error: any) {
        setNetworkError(error.message, error);
        if (error.response && error.response.data)
          checkForPaytronixError(
            error.response.data,
            "actions.auth.update_profile"
          );
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    Object.keys(fieldErrors).forEach((key) => {
      const field = mapErrorToContactField(key);
      methods.setError(field, {
        type: "manual",
        message: fieldErrors[key],
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldErrors]);
  const onSubmit: SubmitHandler<ProfileContactInfoFormInputs> = async (
    data
  ) => {
    if (!isDirty) return;
    const success = await updateContact(data);
    if (success)
      setToast({ type: "success", message: "Profile updated successfully" });
  };
  return (
    <div
      className="tab-pane active"
      role="tabpanel"
      aria-labelledby="contact-info-tab"
    >
      {toast && (
        <div className={`alert alert-${toast.type}`} role="alert">
          {toast.message}
        </div>
      )}
      <FormErrorDisplay error="actions.auth.update_contact" />
      <FormProvider {...methods}>
        <form className="row g-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-12">
            <p className="mb-0 fs-md">
              This information will be used in case we need to contact you. Any
              updates will save to your account.
            </p>
          </div>
          <div className="col-12">
            <MultiplePhoneFields values={profile?.phones} />
          </div>
          <div className="col-md-12">
            <hr className="mb-1" />
          </div>
          <MultipleAddressFields values={profile?.addresses} />
          <div className="col-12 mt-3">
            <div className="hstack gap-2 mt-1">
              <button
                type="submit"
                className="btn btn-primary btn-sm ms-auto"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting ? "Updating..." : "Update Profile"}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default ProfileContactForm;

// Zoom on click
// Remove landmarks
// Make map smaller
