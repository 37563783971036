import Cookies from "js-cookie";
import {
  TStoreOrder,
  TStoreOrders,
  TStoreRestaurant,
  TStoreRestaurantCacheEntry,
} from "types";
import {
  STORAGE_KEY_NEAREST_RESTAURANT as STORAGE_KEY_CACHE_NEAREST_RESTAURANT,
  STORAGE_KEY_ORDERS,
} from "../constants";
import { TRestaurantType } from "types";

const MAX_ORDERS = 10;

const getOrders = () => {
  try {
    let cookieStrValue = localStorage.getItem(STORAGE_KEY_ORDERS);

    if (!cookieStrValue || cookieStrValue === "") {
      return [];
    }

    return JSON.parse(cookieStrValue) as TStoreOrders;
  } catch (e) {
    return [];
  }
};

const saveOrders = (value: TStoreOrders) => {
  localStorage.setItem(
    STORAGE_KEY_ORDERS,
    JSON.stringify(
      value
        .sort((a, b) =>
          a.timestamp > b.timestamp ? -1 : b.timestamp > a.timestamp ? 1 : 0
        )
        .slice(0, MAX_ORDERS)
    )
  );
};

const saveRestaurantOrder = (
  restaurantId: string,
  orderId: string,
  value: Partial<TStoreOrder>
) => {
  const orders = getOrders();

  const existingOrderIdx = orders.findIndex(
    (o) => o.restaurant.id === restaurantId
  );

  if (existingOrderIdx !== -1) {
    orders[existingOrderIdx] = {
      ...orders[existingOrderIdx],
      ...value,
    };
  } else {
    orders.push({
      id: orderId,
      restaurant: {
        id: restaurantId,
      },
      ...value,
      timestamp: new Date().getTime(),
    });
  }

  saveOrders(orders);
};

export const getLatestOrder = () => {
  const orders = getOrders();

  if (orders.length === 0) {
    return undefined;
  }

  return orders.reduce((maxItem, currentItem) => {
    if (
      !maxItem ||
      (currentItem.timestamp !== undefined &&
        currentItem.timestamp > maxItem.timestamp)
    ) {
      return currentItem;
    } else {
      return maxItem;
    }
  });
};

export const persistRestaurantOrder = (
  restaurantId: string,
  orderId: string,
  restaurantUrl?: string,
  restaurantName?: string,
  basics?: boolean,
  restaurantTypes?: TRestaurantType[]
) => {
  const orders = getOrders();

  const itemToOverwrite = orders.findIndex(
    (o) => o.restaurant.id === restaurantId
  );

  if (itemToOverwrite > -1) {
    orders[itemToOverwrite]!.id = orderId;
    orders[itemToOverwrite]!.basics = basics;

    if (!!restaurantName) {
      orders[itemToOverwrite]!.restaurant.name = restaurantName;
    }

    if (!!restaurantUrl) {
      orders[itemToOverwrite]!.restaurant.url = restaurantUrl;
    }

    if (!!restaurantTypes && restaurantTypes.length > 0) {
      orders[itemToOverwrite]!.restaurant.types = restaurantTypes;
    }
  } else {
    orders.push({
      id: orderId,
      restaurant: {
        id: restaurantId,
        url: restaurantUrl,
        name: restaurantName,
        types: restaurantTypes,
      },
      basics,
      timestamp: new Date().getTime(),
    });
  }

  saveOrders(orders);
};

export const getRestaurantOrder = (restaurantId: string) =>
  getOrders().find((o) => o.restaurant.id === restaurantId) ?? undefined;

export const refreshRestaurantOrder = (
  restaurantId: string,
  orderId: string,
  items: number
) =>
  saveRestaurantOrder(restaurantId, orderId, {
    items,
    timestamp: new Date().getTime(),
  });

export const clearRestaurantOrder = (restaurantId: string) =>
  saveOrders(getOrders().filter((o) => o.restaurant.id !== restaurantId));

export const updateOrderRestaurantDetails = (
  restaurantId: string,
  details: Partial<Omit<TStoreRestaurant, "id">>
) => {
  const orders = getOrders();

  const orderIndex = orders.findIndex((o) => o.restaurant.id === restaurantId);

  if (orderIndex === -1) {
    return;
  }

  Object.entries(details).forEach(([key, value]) => {
    if (!!value) {
      orders[orderIndex].restaurant = {
        ...orders[orderIndex].restaurant,
        ...{ [key]: value },
      };
    }
  });

  saveOrders(orders);
};

export const getLatestConfigBasic = () => {
  const latestOrder = getLatestOrder();

  if (!latestOrder) {
    return false;
  }

  return latestOrder.basics ?? false;
};

export const setCookieConfigBasic = (
  restaurantId: string,
  orderId: string,
  value: boolean
) => {
  saveRestaurantOrder(restaurantId, orderId, {
    basics: value,
  });
};

export const getClientLatLong = () => {
  const value = Cookies.get("latlon");

  if (!value) {
    return;
  }

  const [latitude, longitude] = value.split(",").map((val) => parseFloat(val));

  if (!latitude || !longitude) {
    return;
  }

  return {
    latitude,
    longitude,
  };
};

export const isClientNoGeo = () => Cookies.get("nogeo") === "1";

export const getNearestRestaurant = (
  location:
    | {
        latitude: number;
        longitude: number;
      }
    | undefined
): TStoreRestaurant | undefined => {
  try {
    if (!location) {
      return;
    }

    let value = localStorage.getItem(STORAGE_KEY_CACHE_NEAREST_RESTAURANT);

    if (!value) {
      return;
    }

    const restaurant = JSON.parse(value) as TStoreRestaurantCacheEntry;

    if (!restaurant.id || !restaurant.url || !restaurant.types) {
      // don't return objects we deem not usable
      return;
    }

    if (restaurant.cacheKey !== getGeoLocationCacheKey(location)) {
      // geo location has changed
      return;
    }

    if (new Date().getTime() - 43200000 > restaurant.cacheTimestamp) {
      // cache is more than 12hrs old
      return;
    }

    return {
      id: restaurant.id,
      url: restaurant.url,
      name: restaurant.name,
      types: restaurant.types,
    };
  } catch {
    return;
  }
};

export const saveNearestRestaurant = (
  location: {
    latitude: number;
    longitude: number;
  },
  restaurant: TStoreRestaurant
) => {
  localStorage.setItem(
    STORAGE_KEY_CACHE_NEAREST_RESTAURANT,
    JSON.stringify({
      id: restaurant.id,
      url: restaurant.url,
      name: restaurant.name,
      types: restaurant.types,
      cacheKey: getGeoLocationCacheKey(location),
      cacheTimestamp: new Date().getTime(),
    } as TStoreRestaurantCacheEntry)
  );
};

const getGeoLocationCacheKey = (geoloc: {
  latitude: number;
  longitude: number;
}) => btoa(JSON.stringify(geoloc));
