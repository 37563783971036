import { useAppContext } from "contexts/app-context";
import { useAuthContext } from "contexts/auth-context";
import { useEffect } from "react";
import {
  Navigate,
  Outlet,
  ScrollRestoration,
  useSearchParams,
} from "react-router-dom";

function AnonLayout() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { authenticated } = useAppContext();
  const { setRedirect, redirect } = useAuthContext();
  useEffect(() => {
    if (searchParams.has("auth_redirect")) {
      setRedirect(searchParams.get("auth_redirect"));
    }
  }, [setSearchParams, searchParams, setRedirect]);
  if (authenticated && !redirect) {
    return <Navigate to="/profile" replace />;
  }
  
  return (
    <>
      <section className="py-6 py-md-8 bg-body-secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-md-auto">
              <div className="p-3 p-md-6 bg-white rounded-3 shadow position-relative overflow-hidden">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-body-secondary">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <hr className="my-0" />
            </div>
          </div>
        </div>
      </div>
      <ScrollRestoration />
    </>
  );
}
export default AnonLayout;
