import { SelectOption } from "types";
import { formatDate, getNext30Days } from "./dates";

export const getNext30DaysOptions = (timezone: string): SelectOption[] => {
  return getNext30Days(timezone).map((date, i) => {
    const formattedDate = formatDate(date, timezone);

    if (i === 0) {
      return { label: "Today", value: formattedDate };
    }

    if (i === 1) {
      return { label: "Tomorrow", value: formattedDate };
    }

    return { label: formattedDate.slice(0, 5), value: formattedDate }; // Omitting year
  });
};
