import { paytronixResetPassword } from "api/endpoints";
import FormErrorDisplay from "components/form/Error";
import InputField from "components/form/InputField";
import { useAppContext } from "contexts/app-context";
import { useAuthContext } from "contexts/auth-context";
import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
type ResetInputs = {
  email: string;
};
const ForgotPassword = () => {
  const { setNetworkError } = useAppContext();
  const { registerError, clearErrors } = useAuthContext();
  const [toast, setToast] = useState<string | null>(null);
  const methods = useForm<ResetInputs>();
  const onSubmit: SubmitHandler<ResetInputs> = async ({ email }) => {
    clearErrors("actions.auth.reset");
    setToast(null);
    await paytronixResetPassword(email)
      .fetch()
      .then((res) => {
        // This has to return true if the request succeeds
        // So no need to catch exceptions
        setToast(
          "We've sent you an email with instructions on how to reset your password."
        );
      })
      .catch((err: any) => {
        setNetworkError(err.message, err);
        if (err.response && err.response.data.error) {
          registerError(err.response.data.error, "actions.auth.reset");
        } else {
          registerError(
            "Sorry, we couldn't reset your password. Please try again later.",
            "actions.auth.reset"
          );
        }
      });
  };

  return (<>
    <div
      className="block-background d-none d-lg-block"
      style={{ width: "39%" }}
    >
      <img
        className="block-background-image"
        src="/wp-content/themes/nouria/assets/images/content/section-bg-4.jpeg"
        alt="#"
      />
    </div>
    <div className="row justify-content-end gy-2">
      <div className="col-lg-7">
        <h4 className="mb-1">Password Reset</h4>
        <FormErrorDisplay error="actions.auth.reset" />
        {toast && <div className="alert alert-success">{toast}</div>}
        <p className="fs-md">
          Enter your email address below, and we'll send you an email that will
          tell you what to do next.
        </p>
        <hr className="my-3" />
        <FormProvider {...methods}>
          <form className="row g-2" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="col-12">
              <InputField
                name="email"
                label="Email"
                placeholder="Email..."
                disabled={methods.formState.isSubmitting}
                validation={{
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "That doesn't look like an email",
                  },
                }}
              />
            </div>
            <div className="col-12 mt-3">
              <div className="hstack gap-2">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm ms-auto"
                  disabled={methods.formState.isSubmitting}
                >
                  {methods.formState.isSubmitting ? (
                    <>Checking ...</>
                  ) : (
                    <>Reset Password</>
                  )}
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <hr className="mb-1" />
            </div>
            <div className="col-12">
              <div className="hstack gap-2">
                <Link
                  to="/login"
                  className="fs-sm link-text-muted ms-auto"
                >
                  Sign In
                </Link>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div></>
  );
};
export default ForgotPassword;
