import { getMenuItem } from "api/endpoints";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MenuItemConfigurator from "./MenuItemConfigurator";
import { TComboMenuItemConfiguration, TMenuItemConfiguration } from "types";

interface IComboConfigurator {
  comboMenuItem: any;
  action: {
    name: string;
    handler: (
      items: TComboMenuItemConfiguration[]
    ) => Promise<void>;
  };
}

const ComboConfigurator: React.FC<IComboConfigurator> = ({
  comboMenuItem,
  action,
}) => {
  const [menuItems, setMenuItems] = useState<
    { [key: number]: any[] } | undefined
  >(undefined);
  const [step, setStep] = useState<number>(0);
  const [slots, setSlots] = useState<
    ({ id: string; configuration?: TMenuItemConfiguration } | null)[]
  >(
    comboMenuItem.item_slots.map((s: any) => {
      if (!s.items || s.items.length === 0) {
        return [];
      }

      return s.items.length === 1 ? { id: s.items[0].item.$id } : null;
    })
  );

  useEffect(() => {
    if (!comboMenuItem) {
      return;
    }

    const fetchData = async () => {
      let groupedMenuItems: { [key: number]: any[] } = {}; // Step 1: Initialize an object to hold grouped menu items

      if (comboMenuItem) {
        const itemSlots = comboMenuItem.item_slots || [];

        await Promise.all(
          itemSlots.map(async (slot: any, slotIndex: number) => {
            const itemIds = slot.items.map((item: any) => item.item.$id);

            const items = await Promise.all(
              itemIds.map(async (itemId: string) => {
                return getMenuItem(itemId).fetch();
              })
            );

            // Step 2: Group menu items by item_slot index
            if (!groupedMenuItems[slotIndex]) {
              groupedMenuItems[slotIndex] = items;
            } else {
              groupedMenuItems[slotIndex] = [
                ...groupedMenuItems[slotIndex],
                ...items,
              ];
            }
          })
        );

        return groupedMenuItems; // Return grouped menu items
      }
    };

    fetchData().then((groupedMenuItems) => {
      setMenuItems(groupedMenuItems); // Step 3: Update state with grouped menu items
    });
  }, [comboMenuItem]);

  const isLastStep = useMemo(() => {
    return step === slots.length;
  }, [slots.length, step]);

  const currentSlotIdx = useMemo(() => {
    return step - 1;
  }, [step]);

  const currentSlotMenuItem = useMemo(() => {
    if (!menuItems) {
      return null;
    }

    if (!slots[currentSlotIdx]) {
      return null;
    }

    return menuItems[currentSlotIdx].find(
      (menuItem: any) => menuItem.id === slots[currentSlotIdx]!.id
    );
  }, [currentSlotIdx, menuItems, slots]);

  const updateCurrentSlotSelection = useCallback(
    (menuItemId: string) => {
      const newSlots = [...slots];
      newSlots[currentSlotIdx] = { id: menuItemId };
      setSlots(newSlots);
    },
    [currentSlotIdx, slots]
  );

  const handleContinue = useCallback(
    async (menuItemConfiguration?: TMenuItemConfiguration) => {
      if (step === 0) {
        setStep(1);
      }

      if (!currentSlotMenuItem) {
        return;
      }

      const newSlots = [...slots];
      newSlots[currentSlotIdx] = {
        id: currentSlotMenuItem.id,
        configuration: menuItemConfiguration,
      };
      setSlots(newSlots);

      if (!isLastStep) {
        setStep(step + 1);
      } else {
        action.handler(
          newSlots.map((slot) => {
            return {
              id: slot!.id,
              options: slot!.configuration?.options ?? {},
              notes: slot!.configuration?.notes,
            };
          })
        );
      }
    },
    [action, currentSlotIdx, currentSlotMenuItem, isLastStep, slots, step]
  );

  return (
    <>
      {step === 0 ? (
        <>
          <div className="row gy-4">
            <div className="col-lg-4">
              <figure
                className="mb-0 align-top menu-item-image"
                style={{ position: "sticky", top: "180px" }}
              >
                {comboMenuItem.images[0] ? (
                  <img src={comboMenuItem.images[0]} alt={comboMenuItem.name} />
                ) : (
                  <img
                    src="/wp-content/themes/nouria/assets/images/content/no-image-available.png"
                    alt={comboMenuItem.name}
                  />
                )}
              </figure>
            </div>
            <div className="col-lg-8">
              <h2 className="mb-0">{comboMenuItem.name}</h2>
              <p className="mt-1 fs-md">{comboMenuItem.description}</p>
              {comboMenuItem.is_sold_out && (
                <div className="alert alert-warning fs-sm mt-1">
                  Sorry, {comboMenuItem.name} is sold out
                </div>
              )}
              {comboMenuItem.tobacco && (
                <div className="alert alert-warning fs-sm mt-1">
                  You must show ID when picking up tobacco products
                </div>
              )}
              {comboMenuItem.alcohol && (
                <div className="alert alert-warning fs-sm mt-1">
                  You must show ID when picking up alcohol products
                </div>
              )}
              <hr className="my-3" />
              {comboMenuItem.item_slots.map((slot: any) => (
                <p>
                  {slot.qty}x {slot.name}
                </p>
              ))}
              <div className="hstack flex-wrap row-gap-2 column-gap-4">
                <div></div>
                <div className="me-auto"></div>
                {!!action && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={async () => {
                        await handleContinue();
                      }}
                    >
                      Continue
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {currentSlotMenuItem === null ? (
            <>
              {!!menuItems && !!menuItems[currentSlotIdx] && (
                <SlotItemSelector
                  menuItems={menuItems[currentSlotIdx]}
                  onSelection={(menuItemId) => {
                    updateCurrentSlotSelection(menuItemId);
                  }}
                />
              )}
            </>
          ) : (
            <>
              <MenuItemConfigurator
                name={currentSlotMenuItem.name}
                description={currentSlotMenuItem.description}
                image={currentSlotMenuItem.images?.at(0) ?? ""}
                prices={currentSlotMenuItem.prices}
                optionGroups={currentSlotMenuItem.option_groups}
                flags={{
                  soldOut: currentSlotMenuItem.is_sold_out ?? false,
                  tobacco: currentSlotMenuItem.tobacco ?? false,
                  alcohol: currentSlotMenuItem.alcohol ?? false,
                }}
                fields={{
                  quantity: comboMenuItem.enable_quantity !== false,
                  price: false,
                }}
                defaults={{}}
                action={{
                  name: isLastStep ? "Add to Cart" : "Continue",
                  handler: async (size, quantity, options, notes) => {
                    await handleContinue({ size, quantity, options, notes });
                  },
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ComboConfigurator;

interface ISlotItemSelector {
  menuItems: any[];
  onSelection: (menuItemId: string) => void;
}

const SlotItemSelector: React.FC<ISlotItemSelector> = ({
  menuItems,
  onSelection,
}) => {
  return (
    <>
      <div className="vstack gap-4">
        <div className="hstack gap-3 mb-2">
          <p className="flex-shrink-0 mb-0 fw-semibold text-text-hard">
            Pick one:
          </p>
          <hr className="w-100 py-0" />
        </div>
        <div className="row row-cols-sm-2 row-cols-md-3 row-cols-xl-4 g-3">
          {menuItems.map((menuItem: any, menuItemIdx: number) => {
            return (
              <div key={`combomenuitem-${menuItemIdx}`} id={menuItem.id}>
                <a
                  href="#select"
                  className={"menu-card"}
                  onClick={(e) => {
                    e.preventDefault();
                    onSelection(menuItem.id);
                  }}
                >
                  {menuItem.new ? (
                    <span className="menu-card-tag">New!</span>
                  ) : (
                    menuItem.is_sold_out && (
                      <span className="menu-card-tag">Out of Stock</span>
                    )
                  )}
                  <figure className="menu-card-image">
                    <img
                      src={menuItem.images[0]}
                      alt={menuItem.name}
                      className="mt-1"
                    />
                  </figure>
                  <h6 className="menu-card-title">{menuItem.name}</h6>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
