import { getMenuItem } from "api/endpoints";
import useRequest from "api/use-request";
import Breadcrumb from "components/Breadcrumb";
import ContextAlert from "components/ContextAlert";
import OrderDebugger from "components/OrderDebugger";
import ComboConfigurator from "components/menu-item/ComboConfigurator";
import MenuItemConfigurator from "components/menu-item/MenuItemConfigurator";
import config from "config";
import { useRestaurantContext } from "contexts/restaurant-context";
import { gaItemEvent } from "integrations/ga4";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { TCartItemOptions, TComboMenuItemConfiguration } from "types";

const MenuItem: React.FC = () => {
  const navigate = useNavigate();
  const [gaReported, setGaReported] = useState(false);
  const { menuItemId } = useParams();

  const {
    menu,
    order,
    addComboItemToOrder,
    addItemToOrder,
    updateOrderItem,
    restaurant,
    restaurantBaseUrl,
    gaResturantType,
  } = useRestaurantContext();

  const { data: menuItem } = useRequest(getMenuItem(menuItemId!));
  const [menuItemToEditId, setMenuItemToEditId] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (menuItem) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (restaurant && menuItem) {
        // fire GA event
        let default_price = 0;
        if (menuItem.price) {
          default_price = menuItem.price;
        } else if (menuItem.prices && menuItem.prices.length > 0) {
          default_price = menuItem.prices[0].price;
        }
        if (!gaReported) {
          gaItemEvent({
            event: "view_item",
            menu_type: gaResturantType,
            currency: "USD",
            store_location: restaurant?.name ?? "",
            value: parseFloat(default_price.toFixed(2)),
            items: [
              {
                index: 0,
                item_id: menuItem?.id ?? "",
                item_name: menuItem?.name ?? "",
                item_category: menuItem?.category ?? "",
                item_brand: gaResturantType,
                price: default_price,
                quantity: 1,
                location_id: restaurant?.id ?? "",
              },
            ],
          });
          setGaReported(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem, restaurant, gaResturantType]);

  useEffect(() => {
    if (order) {
      const params = new URLSearchParams(window.location.search);
      const id = params.get("edit") ?? undefined;
      setMenuItemToEditId(id);
    }
  }, [menuItemId, order]);

  const menuItemToEdit = useMemo(() => {
    return menuItemToEditId
      ? order?.items?.find((i: any) => i.id === menuItemToEditId) ?? undefined
      : undefined;
  }, [menuItemToEditId, order]);

  const menuItemToEditParentCombo = useMemo(() => {
    if (!menu || !menuItemToEdit || !menuItemToEdit.combo_id) {
      return undefined;
    }

    const comboItemId = order.combos.find(
      (c: any) => c.id === menuItemToEdit.combo_id
    )?.combo_item_id;

    if (!comboItemId) {
      return undefined;
    }

    const comboItem = menu.menu
      .flatMap((m: any) => m.items)
      .find((i: any) => i.id === comboItemId);

    return comboItem;
  }, [menu, menuItemToEdit, order]);

  const isComboItem = useMemo(() => {
    return menuItem && _.isArray(menuItem.item_slots);
  }, [menuItem]);

  const defaults = useMemo(() => {
    if (!menuItem) {
      return {};
    }

    if (!menuItemToEdit) {
      if (menuItem.prices.length <= 0) {
        return {};
      }

      const defaultSize =
        menuItem.prices.find((p: any) => p.is_default) || menuItem.prices[0];

      if (!defaultSize) {
        return {};
      }

      return {
        size: defaultSize.name,
      };
    }

    const options: TCartItemOptions = {};
    menuItemToEdit.options.forEach((o: any) => {
      options[o.group_name] ??= [];
      options[o.group_name].push({
        name: o.option_name,
        quantity: parseInt(o.quantity),
      });
    });

    return {
      size: menuItemToEdit.size,
      options: options,
      quantity: parseInt(menuItemToEdit.quantity),
      notes: menuItemToEdit.notes ?? "",
    };
  }, [menuItem, menuItemToEdit]);

  const addComboMenuItemHandler = useCallback(
    async (items: TComboMenuItemConfiguration[]) => {
      if (!menuItem) {
        return false;
      }

      const success = await addComboItemToOrder(menuItem.id, items);

      if (success) {
        navigate("/");
      }
    },
    [addComboItemToOrder, menuItem, navigate]
  );

  const addOrUpdateMenuItemHandler = useCallback(
    async (
      size: string,
      quantity: number,
      options: TCartItemOptions,
      notes: string
    ) => {
      if (!menuItem) {
        return false;
      }

      let success = false;

      if (menuItemToEdit) {
        // Update existing cart item
        success = await updateOrderItem(
          menuItemToEdit.id,
          menuItem.id,
          size ?? "",
          quantity,
          options,
          menuItemToEdit,
          notes
        );
      } else {
        success = await addItemToOrder(
          menuItem.id,
          size ?? "",
          quantity,
          options,
          menuItem,
          notes,
          menuItem.name
        );
      }

      if (success) {
        navigate("/");
      }
    },
    [addItemToOrder, menuItem, menuItemToEdit, navigate, updateOrderItem]
  );

  if (!restaurant || !menuItem) {
    return <LoadingSkeleton />;
  }

  return (
    <section className="hero bg-body-secondary">
      <Breadcrumb
        hero={true}
        items={[
          { uri: "/locations" ?? "", title: "Locations" },
          {
            uri: restaurantBaseUrl?.replace("/menu", "") ?? "",
            title: restaurant.name,
          },
          { uri: restaurantBaseUrl ?? "", title: "Menu" },
          { uri: `#`, title: menuItem.name },
        ]}
      />
      <div className="container">
        <ContextAlert />
        {isComboItem ? (
          <ComboConfigurator
            comboMenuItem={menuItem}
            action={{
              name: "Add to Cart",
              handler: async (items) => {
                await addComboMenuItemHandler(items);
              },
            }}
          />
        ) : (
          <MenuItemConfigurator
            key={menuItem.id}
            name={menuItem.name}
            description={menuItem.description}
            image={menuItem.images?.at(0) ?? ""}
            prices={menuItem.prices}
            optionGroups={menuItem.option_groups}
            flags={{
              soldOut: menuItem.is_sold_out ?? false,
              tobacco: menuItem.tobacco ?? false,
              alcohol: menuItem.alcohol ?? false,
            }}
            fields={{
              quantity: !!menuItemToEditParentCombo
                ? menuItemToEditParentCombo.enable_quantity !== false
                : menuItem.enable_quantity !== false,
              price: !menuItemToEditParentCombo,
            }}
            action={{
              name: menuItemToEdit ? "Update Order" : "Add to Cart",
              handler: async (size, quantity, options, notes) => {
                await addOrUpdateMenuItemHandler(
                  size,
                  quantity,
                  options,
                  notes
                );
              },
            }}
            defaults={defaults}
          />
        )}
      </div>
      {config.DEBUG && (
        <div className="row">
          <div className="col-12">
            <OrderDebugger />
          </div>
        </div>
      )}
    </section>
  );
};

const LoadingSkeleton = () => {
  return (
    <section className="hero bg-body-secondary">
      <Breadcrumb hero={true} items={[]} />
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-4">
            <figure
              className="mb-0 align-top menu-item-image"
              style={{ position: "sticky", top: "180px" }}
            >
              <Skeleton height={300} />
            </figure>
          </div>
          <div className="col-lg-8">
            <h2 className="mb-0">
              <Skeleton />
            </h2>
            <p className="mt-1 fs-md">
              <Skeleton />
            </p>
            <hr className="my-3" />

            {[...Array(2)].map((_, i) => (
              <React.Fragment key={`og-${i}`}>
                <div>
                  <div className="mb-1">
                    <h5 className="fs-sm text-uppercase">
                      <Skeleton width={200} />
                    </h5>
                  </div>
                  <div className="row row-cols-sm-2 row-cols-md-3 g-gutter-half">
                    {[...Array(3)].map((_, j) => (
                      <div key={`og-${i}-o-${j}`} className="form-check mb-1">
                        <div className="menu-card">
                          <div className="hstack justify-content-between flex-wrap row-gap-1 column-gap-2 w-100">
                            <div className="form-check mb-0">
                              <Skeleton width={100} height={20} />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <hr className="my-3" />
              </React.Fragment>
            ))}
            <div className="hstack flex-wrap row-gap-2 column-gap-4">
              <div>
                <Skeleton width={100} height={60} />
              </div>
              <div className="me-auto">
                <h2 className="mb-0">
                  <Skeleton width={120} height={60} />
                </h2>
              </div>
              <div>
                <Skeleton width={120} height={60} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MenuItem;
