import { useMap } from "@vis.gl/react-google-maps";
import { getLocations } from "api/endpoints";
import { HttpError } from "api/http-client";
import useRequest from "api/use-request";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const defaultZoom = 10;
export interface Location {
  id: number;
  soci_id: string;
  title: string;
  link: string;
  address_formatted: string;
  phone: string;
  new_concept: boolean;
  online_order_link: string;
  can_order: boolean;
  filters: string[];
  filters_ids: number[];
  address: LocationAddress;
}
export interface LocationAddress {
  address_line_1: string;
  address_line_2: string;
  sublocality: string;
  city: string;
  state_province: string;
  postal_code: string;
  country: string;
  lat: number;
  long: number;
}

export interface FilterOptions {
  key: string;
  value: string;
}

interface ILocationsContext {
  isLoading: boolean;
  locationError: HttpError<any> | undefined;
  setIsLoading: (loading: boolean) => void;
  setLocationError: (error: HttpError<any> | undefined) => void;
  locations: Location[];
  setLocations: (locations: Location[]) => void;
  filters: string[];
  setFilters: (filters: string[]) => void;
  hoveredLocation: Location | null;
  setHoveredLocation: (location: Location | null) => void;
  selectedLocation: Location | null;
  setSelectedLocation: (location: Location | null) => void;
  latitude: number | undefined;
  longitude: number | undefined;
  address: string | undefined;
  radius: number | undefined;
  setLatitude: (latitude: number | undefined) => void;
  setLongitude: (longitude: number | undefined) => void;
  setAddress: (address: string | undefined) => void;
  setRadius: (radius: number | undefined) => void;
  state: string | undefined;
  setState: (state: string | undefined) => void;
  selectedRef: React.RefObject<HTMLDivElement>;
  setSelectedRef: (ref: React.RefObject<HTMLDivElement>) => void;
  doSearch: (update: any) => void;
}

export const LocationsContext = createContext<ILocationsContext | undefined>(
  undefined
);

interface ILocationsContextProviderProps {
  children: React.ReactNode;
}

export const LocationsContextProvider: React.FC<
  ILocationsContextProviderProps
> = ({ children }) => {
  const map = useMap();
  const [locations, setLocations] = useState<Location[]>([]);
  const [filters, setFilters] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<
    HttpError<any> | undefined
  >(undefined);
  const [hoveredLocation, setHoveredLocation] = useState<Location | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );
  const [state, setState] = useState<string | undefined>(undefined);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [latitude, setLatitude] = useState<number | undefined>(undefined);
  const [longitude, setLongitude] = useState<number | undefined>(undefined);
  const [radius, setRadius] = useState<number | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedRef, setSelectedRef] = useState<
    React.RefObject<HTMLDivElement>
  >(React.createRef());

  const shouldFetch = () => {
    if (
      searchParams.get("address") !== null &&
      searchParams.get("address") === "Current Location"
    ) {
      // don't search for current address
      return false;
    }
    return true;
  };

  const {
    data,
    isLoading: locationsLoading,
    error,
    // Disable revalidation on focus, to limit the geocoding requests
  } = useRequest(
    getLocations({
      address: searchParams.get("address") || undefined,
      filters: searchParams.getAll("filters"),
      state,
      latitude: searchParams.get("latitude")
        ? Number(searchParams.get("latitude"))
        : undefined,
      longitude: searchParams.get("longitude")
        ? Number(searchParams.get("longitude"))
        : undefined,
      radius: searchParams.get("radius")
        ? Number(searchParams.get("radius"))
        : undefined,
    }),
    shouldFetch(),
    false,
    false,
    false
  );

  const doSearch = (update: any) => {
    const params = searchParams;
    Object.keys(update).forEach((key) => {
      if (update[key]) {
        if (Array.isArray(update[key])) {
          params.delete(key);
          update[key].forEach((value: string) => {
            params.append(key, value);
          });
        } else {
          params.set(key, update[key]);
        }
      } else {
        params.delete(key);
      }
    });
    setSearchParams(params);
  };

  /**
   * Get the user's location from the browser geolocation API
   */
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          doSearch({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            address: undefined,
          });
        },
        () => {
          console.log("Geolocation not supported1");
        }
      );
    } else {
      console.log("Geolocation not supported2");
    }
  };

  useEffect(() => {
    console.log(searchParams.get("address"));
    if (searchParams.get("address") === "Current Location") {
      getLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  /**
   * Initialize the locations context with the locations from the server
   */
  useEffect(() => {
    if (data) {
      setLocations(data);
    }
    setIsLoading(locationsLoading);
    setLocationError(error);
  }, [data, locationsLoading, error]);

  /**
   * If the selected location changes, we should center on it
   */
  useEffect(() => {
    // find location
    if (selectedLocation) {
      if (map) {
        map.panTo({
          lat: Number(selectedLocation.address.lat),
          lng: Number(selectedLocation.address.long),
        });
        map.setZoom(defaultZoom + 3);
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectedLocation]);

  /**
   * If the locations change, we should update the map bounds
   */
  useEffect(() => {
    if (!map || locationsLoading || error) return;
    const bounds = new google.maps.LatLngBounds();
    let found = false;
    for (var i = 0; i < locations.length; i++) {
      if (
        locations[i].address &&
        locations[i].address.lat &&
        locations[i].address.long
      ) {
        bounds.extend({
          lat: Number(locations[i].address.lat),
          lng: Number(locations[i].address.long),
        });
        // and least one good location;
        found = true;
      }
    }
    if (found) {
      map.fitBounds(bounds);
      //const zoom = map.getZoom();
    } else if (latitude && longitude) {
      map.setCenter({
        lat: Number(latitude),
        lng: Number(longitude),
      });
      map.setZoom(defaultZoom);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [locations]);

  return (
    <LocationsContext.Provider
      value={{
        locations,
        setLocations,
        filters,
        setFilters,
        hoveredLocation,
        setHoveredLocation,
        selectedLocation,
        setSelectedLocation,
        isLoading,
        locationError,
        setIsLoading,
        setLocationError,
        latitude,
        longitude,
        address,
        radius,
        setLatitude,
        setLongitude,
        setAddress,
        setRadius,
        selectedRef,
        setSelectedRef,
        state,
        setState,
        doSearch,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};

export const useLocationsContext = () => {
  const context = useContext(LocationsContext);
  if (!context) {
    throw new Error(
      "useLocationsContext must be used within a LocationContextProvider."
    );
  }
  return context;
};
