import React from "react";
import { useRestaurantContext } from "../contexts/restaurant-context";

const ContextAlert: React.FC = () => {
  const { hasErrors, getErrors } = useRestaurantContext();

  if (!hasErrors()) {
    return <></>;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="mt-4">
          {getErrors().map(([error, action], i) => (
            <div key={i} className="alert alert-danger" role="alert">
              {error}
              {action && (
                <>
                  &nbsp;
                  <a
                    href="#action"
                    className="alert-link"
                    onClick={(e) => {
                      e.preventDefault();
                      action.handler();
                    }}
                  >
                    {action.name}
                  </a>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContextAlert;
