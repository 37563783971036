import classNames from "classnames";
import OptionSelect from "components/OptionSelect";
import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import { Fragment, useState } from "react";

interface IExtraFieldsProps {}

const ExtraFields: React.FC<IExtraFieldsProps> = () => {
  const { restaurant } = useRestaurantContext();
  const { data, updateData } = useCheckoutContext();
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});

  if (!data) {
    return <></>;
  }

  return (
    <>
      {restaurant.fields && (
        <>
          {restaurant.fields
            .filter((field: any) => {
              if (field.order_type === "both") return true;
              if (field.order_type === data.orderType) return true;
              return false;
            })
            .map((field: any) => (
              <Fragment key={field.form_name}>
                <ExtraField
                  def={field}
                  value={
                    (data.fields ?? {})[field.form_name] ? (data.fields ?? {})[field.form_name] :
                    field.type === "select"
                      ? field.default_option
                      : ""
                  }
                  error={fieldErrors[field.form_name]}
                  onChange={(value) => {
                    // Ok, some simple validation
                    // Clear this field
                    setFieldErrors({
                      ...fieldErrors,
                      [field.form_name]: undefined,
                    });
                    if (
                      field.required !== undefined &&
                      (!value || value === "")
                    ) {
                      setFieldErrors({
                        ...fieldErrors,
                        [field.form_name]: "This field is required",
                      });
                    }
                    if (
                      field.character_limit &&
                      value.length > field.character_limit
                    ) {
                      setFieldErrors({
                        ...fieldErrors,
                        [field.form_name]: `${field.name} must be less than ${field.character_limit} characters.`,
                      });
                    }
                    updateData({
                      fields: { ...data.fields, [field.form_name]: value },
                    });
                  }}
                />
              </Fragment>
            ))}
        </>
      )}
      {restaurant.allow_notes && (
        <>
          <label htmlFor="notes" className="form-label">
            {restaurant.notes_label ?? "Notes"}
          </label>
          <textarea
            className="form-control"
            id="special_instructions"
            name="special_instructions"
            defaultValue={data.specialInstructions ?? ""}
            onBlur={(e) => {
              // Todo: Validation

              updateData({ specialInstructions: e.target.value });
            }}
          ></textarea>
        </>
      )}
      <hr />
    </>
  );
};

interface IExtraFieldProps {
  def: {
    name: string;
    label: string;
    type: string;
    required: string;
    order_type: string;
    form_name?: string;
    help_text?: string;
    options?: { [key: string]: string };
    default_option?: string;
  };
  value: string;
  error: string;
  onChange: (value: string) => void;
}

const ExtraField: React.FC<IExtraFieldProps> = ({
  def,
  value,
  error,
  onChange,
}) => {
  return (
    <div
      className={classNames("form-group", {
        "has-danger": typeof error !== "undefined",
      })}
    >
      <label htmlFor={def.form_name} className="form-label">
        {def.label}{" "}
        {def.required !== undefined && <span className="text-danger">*</span>}
      </label>
      <div className="input-group">
        {def.type === "text" ? (
          <input
            type="text"
            className={classNames("form-control", {
              "is-invalid": error !== undefined,
            })}
            id={def.form_name}
            defaultValue={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onChange(e.target.value)}
          />
        ) : def.type === "textarea" ? (
          <textarea
            className={classNames("form-control", {
              "is-invalid": error !== undefined,
            })}
            id={def.form_name}
            defaultValue={value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={(e) => onChange(e.target.value)}
          />
        ) : (
          def.type === "select" && (
            <OptionSelect
              selection={value}
              options={Object.entries(def.options ?? {}).map(
                ([value, label]) => {
                  return { value, label };
                }
              )}
              onChange={(value) => {
                onChange(value);
              }}
            />
          )
        )}
        {error !== undefined && <div className="invalid-feedback">{error}</div>}
      </div>
      {def.help_text && error === undefined && (
        <p className="c-note">{def.help_text}</p>
      )}
    </div>
  );
  // if (def.type === "select") {
  //   return (
  //     <>
  //       <label htmlFor={def.form_name} className="form-label">
  //         {def.label}
  //       </label>
  //       <OptionSelect
  //         selection={value}
  //         options={Object.entries(def.options ?? {}).map(([value, label]) => {
  //           return { value, label };
  //         })}
  //         onChange={(value) => {
  //           onChange(value);
  //         }}
  //       />
  //       {error && <div className="invalid-feedback">{error}</div>}
  //       {def.help_text && <p className="c-note">{def.help_text}</p>}
  //     </>
  //   );
  // }
  // if (def.type === "text") {
  //   return (
  //     <>
  //       <label htmlFor={def.form_name} className="form-label">
  //         {def.label}
  //       </label>
  //       <input
  //         type="text"
  //         className="form-control"
  //         id={def.form_name}
  //         defaultValue={value}
  //         onBlur={(e) => onChange(e.target.value)}
  //       />
  //       {error && <div className="invalid-feedback">{error}</div>}
  //       {def.help_text && <p className="c-note">{def.help_text}</p>}
  //     </>
  //   );
  // }
  // if (def.type === "textarea") {
  //   return (
  //     <div
  //       className={classNames("form-group", {
  //         "has-danger": typeof error !== "undefined",
  //       })}
  //     >
  //       <div className="input-group">
  //         <textarea
  //           className={classNames("form-control", {
  //             "is-invalid": error !== undefined,
  //           })}
  //           id={def.form_name}
  //           defaultValue={value}
  //           onChange={(e) => onChange(e.target.value)}
  //           onBlur={(e) => onChange(e.target.value)}
  //         />{" "}
  //         {error !== undefined && (
  //           <div className="invalid-feedback">{error}</div>
  //         )}
  //       </div>

  //       {def.help_text && error === undefined && (
  //         <p className="c-note">{def.help_text}</p>
  //       )}
  //     </div>
  //   );
  // }

  // return <></>;
};

export default ExtraFields;
