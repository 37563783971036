import classNames from "classnames";
import React, { useEffect } from "react";
import { SelectOption } from "types";

interface IOptionSelectProps {
  options: SelectOption[];
  selection?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  className?: string;
}

const OptionSelect: React.FC<IOptionSelectProps> = ({
  options,
  selection,
  placeholder,
  className,
  onChange,
}) => {
  const [selected, setSelected] = React.useState<string | undefined>(selection);
  useEffect(() => {
    const selected = options.find(
      (option) => String(option.value) === String(selection)
    );
    if (selected) {
      setSelected(selected.value);
    }
  }, [options, selection]);
  return (
    <>
      <select
        className={classNames("form-control", className)}
        disabled={options.length === 0}
        onChange={(e) => {
          setSelected(e.currentTarget.value);
          onChange(e.currentTarget.value);
        }}
        value={selected}
      >
        {placeholder && <option value={""}>{placeholder}</option>}
        {options?.map((option) => (
          <option key={option.value} value={String(option.value)}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default OptionSelect;
