import { useAppContext } from "contexts/app-context";
import { useAuthContext } from "contexts/auth-context";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, Navigate, Outlet, ScrollRestoration } from "react-router-dom";
import ProfileTabs from "./ProfileTabs";

const ProfileLayout = () => {
  const { getProfile, profileLoading, setRedirect } = useAuthContext();
  const { authenticated, profile } = useAppContext();
  const [error] = useState<string | null>(null);
  
  

  useEffect(() => {
    const ensureProfile = async () => {
      if(!profile){
        await getProfile();
      }
    }
    ensureProfile();
    setRedirect(null);
  }, [setRedirect, getProfile, profile]);

  if (!authenticated) {
    return <Navigate to="/" replace />;
  }
  return (
    <>
      <section className="py-6 py-md-8 bg-body-secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-md-auto">
              <div className="p-3 p-md-6 bg-white rounded-3 shadow">
                <div className="hstack justify-content-between flex-wrap column-gap-2 row-gap-1 mt-2">
                  <h4 className="mb-1">My Account</h4>
                  <Link to="/logout" className="btn btn-link">
                    Logout
                  </Link>
                </div>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <hr className="my-3" />

                {profileLoading ? (
                  <LoadingSkeleton />
                ) : (
                  <div className="row gx-xl-8 gy-2">
                    <div className="col-lg-4">
                      <ProfileTabs />
                    </div>
                    <div className="col-lg-8">
                      <div className="tab-content">
                        <Outlet />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-body-secondary">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <hr className="my-0" />
            </div>
          </div>
        </div>
      </div>
      <ScrollRestoration />
    </>
  );
};

const LoadingSkeleton: React.FC = () => {
  return (
    <div className="row gx-xl-8 gy-2">
      <div className="col-lg-4">
        <Skeleton height={80} />
      </div>
      <div className="col-lg-8">
        <div className="row">
          <div className="col-12">
            <Skeleton height={200} />
          </div>
          <div className="col-12">
            <Skeleton height={300} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLayout;
