import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import InputField from "./InputField";

function MultipleAddressFields({ name, values }: any) {
  const { control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "addresses", // unique name for your Field Array
  });
  useEffect(() => {
    remove();
    values.forEach((value: any) => {
      append({
        // ptxId: value.id,
        address: value.address,
        // address_line_2: value.address_line_2,
        // company: value.company,
        zip: value.zip,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <div>
      {fields.map((field: any, index: number) => (
        <div className="row g-2" key={field.id}>
          <div className="col-md-8">
            <InputField
              name={`addresses.${index}.address`}
              label="Address"
              placeholder="Address..."
              defaultValue={field.address}
              validation={{
                maxLength: {
                  value: 100,
                  message: "Address must be less than 100 characters",
                },
              }}
            />
          </div>
          <div className="col-md-4">
            <InputField
              name={`addresses.${index}.zip`}
              label="Zip Code"
              placeholder="Zip Code..."
              defaultValue={field.zip}
              validation={{
                pattern: {
                  value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                  message: "That doesn't look like a US zip code",
                },
              }}
            />
          </div>
          <div className="col-md-12">
            <hr className="mb-1" />
          </div>
        </div>
      ))}
      
      
    </div>
  );
}
export default MultipleAddressFields;
/*
<button
        type="button"
        className="btn btn-link btn-sm"
        onClick={() => {
          append({ value: "" });
        }}
      >
        Add Address
      </button>*/