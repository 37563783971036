import { paytronixAuthenticate } from "api/endpoints";
import FormErrorDisplay from "components/form/Error";
import InputField from "components/form/InputField";
import ArrowRightIcon from "components/icons/ArrowRight";
import { useAppContext } from "contexts/app-context";
import { useAuthContext } from "contexts/auth-context";
import { useCallback } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link, redirect } from "react-router-dom";

type LoginInputs = {
  username: string;
  password: string;
};

const LoginView = () => {
  const { clearErrors, checkForPaytronixError, getProfile } = useAuthContext();
  const appContext = useAppContext();
  const authContext = useAuthContext();
  const methods = useForm<LoginInputs>();
  /**
   * Attempt to login
   * @param username
   * @param password
   */
  const attemptLogin = useCallback(
    async (username: string, password: string) => {
      try {
        clearErrors("actions.auth.login");
        const authenticate = await paytronixAuthenticate(
          username,
          password
        ).fetch();
        const loginSuccess = checkForPaytronixError(
          authenticate,
          "actions.auth.update_profile"
        );
        if (loginSuccess) {
          appContext.setAuthCookie(authenticate);
          // Get the user's profile
          await getProfile(
            username,
            authenticate.printedCardNumber,
            authenticate.access_token
          );
          return true;
        }
        return false;
      } catch (error: any) {
        appContext.setNetworkError(error.message, error);
        if (error.response.data)
          checkForPaytronixError(error.response.data, "actions.auth.login");
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appContext, clearErrors, checkForPaytronixError]
  );

  const onSubmit: SubmitHandler<LoginInputs> = async ({
    username,
    password,
  }) => {
    const results = await attemptLogin(username, password);
    if (!results) {
      //setError("Invalid username or password");
    } else {
      if(authContext.redirect){
        window.location.href = authContext.redirect;
        return true;
      }
      return redirect("/profile");
    }
  };

  return (
    <>
      <div
        className="block-background d-none d-lg-block"
        style={{ width: "39%" }}
      >
        <img
          className="block-background-image"
          src="/wp-content/themes/nouria/assets/images/content/section-bg-4.jpeg"
          alt="#"
        />
      </div>
      <div className="row justify-content-end gy-2">
        <div className="col-lg-7">
          <h4 className="mb-1">Login</h4>
          <FormErrorDisplay error="actions.auth.login" />
          <hr className="my-3" />
          <FormProvider {...methods}>
            <form className="row g-2" onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="col-12">
                <InputField
                  name="username"
                  label="Email"
                  placeholder="Email..."
                  validation={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "That doesn't look like an email",
                    },
                  }}
                />
              </div>
              <div className="col-12">
                <InputField
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Password..."
                  validation={{
                    required: "Password is required",
                  }}
                />
              </div>
              <div className="col-12 mt-3">
                <div className="hstack gap-2">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm ms-auto"
                    disabled={methods.formState.isSubmitting}
                  >
                    {methods.formState.isSubmitting ? (
                      <>Checking ...</>
                    ) : (
                      <>
                        Sign In <ArrowRightIcon />
                      </>
                    )}
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <hr className="mb-1" />
              </div>
              <div className="col-12">
                <div className="hstack gap-2">
                  <Link to="/forgot" className="fs-sm link-text-muted">
                    Forgot Password?
                  </Link>
                  <Link to="/join" className="fs-sm link-text-muted ms-auto">
                    Join Now
                  </Link>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
export default LoginView;
