export const getNext30Days = (timezone: string): Date[] => {
  const dates: Date[] = [];
  for (let i = 0; i < 30; i++) {
    const date = new Date();
    date.setDate(date.getDate() + i);
    dates.push(date);
  }
  return dates;
};

export const formatDate = (date: Date, timezone: string): string => {
  return date.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    timeZone: timezone,
  });
};
export const formatDateTime = (date: Date, timezone: string): string => {
  return date.toLocaleString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    
    timeZone: timezone,
  });
};
