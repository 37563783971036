import { NavLink } from "react-router-dom";

const ProfileTabs = () => {
  return (
    <ul className="nav nav-pills flex-lg-column" role="tablist">
      <li className="nav-item" role="presentation">
      <NavLink
          className={({isActive, isPending}) => isActive ? "active nav-link w-lg-100 text-lg-start" : "nav-link w-lg-100 text-lg-start"}
          type="button"
          role="tab"
          aria-controls="account-info"
          aria-selected="true"
          to="/profile"
        >
          Account Information
        </NavLink>
      </li>
      <li className="nav-item" role="presentation">
        <NavLink
          className={({isActive, isPending}) => isActive ? "active nav-link w-lg-100 text-lg-start" : "nav-link w-lg-100 text-lg-start"}
          type="button"
          role="tab"
          aria-controls="contact-info"
          aria-selected="true"
          to="/contact"
        >
          Contact Information
        </NavLink>
      </li>
     
    </ul>
  );
};
export default ProfileTabs;
