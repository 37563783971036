import ModernHomeIcon from "components/icons/ModernHome";
import ScooterIcon from "components/icons/Scooter";
import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";

interface IOrderTypeSelector {
  checkout?: boolean;
}

const OrderTypeSelector: React.FC<IOrderTypeSelector> = ({ checkout }) => {
  const { restaurant } = useRestaurantContext();
  const { data, updateData } = useCheckoutContext();

  if (!data) {
    return <></>;
  }

  return (
    <>
      <h5 className="my-1">
        {checkout ? "Change your order" : "Start an order"}
      </h5>

      {restaurant.takeout === true && restaurant.delivery === true ? (
        <p className="fs-md">
          Your order can be waiting for you at {restaurant.address} or delivered
          to your front door.
        </p>
      ) : (
        <>
          {restaurant.takeout === true && (
            <p className="fs-md">
              Your order can be waiting for you at {restaurant.address}.
            </p>
          )}
          {restaurant.delivery === true && (
            <p className="fs-md">
              Your order can be delivered to your front door.
            </p>
          )}
        </>
      )}

      <div className="row row-cols-md-2 g-2">
        {restaurant.takeout === true && (
          <div>
            <div
              className="radio-button"
              onClick={() => updateData({ orderType: "takeout" })}
            >
              <input
                id={`ordertype-takeout`}
                type="checkbox"
                value={1}
                checked={data.orderType === "takeout"}
                onChange={(e) => updateData({ orderType: "takeout" })}
                className="radio-button-input"
              />
              <label
                htmlFor={`ordertype-takeout`}
                className="radio-button-label"
              >
                <div className="radio-button-icon">
                  <ScooterIcon />
                </div>
                <div className="radio-button-content">
                  <h6 className="radio-button-title">
                    {restaurant.takeout_label ?? "Takeout"}
                  </h6>
                  <p className="radio-button-text">{restaurant.address}</p>
                </div>
                <span className="radio-button-button"></span>
              </label>
            </div>
          </div>
        )}
        {restaurant.delivery === true && (
          <div>
            <div
              className="radio-button"
              onClick={() => updateData({ orderType: "delivery" })}
            >
              <input
                id={`ordertype-delivery`}
                type="checkbox"
                value={1}
                checked={data.orderType === "delivery"}
                onChange={(e) => updateData({ orderType: "delivery" })}
                className="radio-button-input"
              />
              <label htmlFor="delivery" className="radio-button-label">
                <div className="radio-button-icon">
                  <ModernHomeIcon />
                </div>
                <div className="radio-button-content">
                  <h6 className="radio-button-title">
                    {restaurant.delivery_label ?? "Delivery"}
                  </h6>
                  <p className="radio-button-text">Deliver to your location</p>
                </div>
                <span className="radio-button-button"></span>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderTypeSelector;
