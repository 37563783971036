import classNames from "classnames";
import * as React from "react";
import { useController, useFormContext } from "react-hook-form";

interface CheckboxFieldProps {
  name: string;
  label: string;
  helpText?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  inputProps?: Omit<
    JSX.IntrinsicElements["input"],
    "name" | "type" | "disabled" | "className" | "placeholder"
  >;
  small?: boolean;
  checkboxStyle?: "checkbox" | "toggle";
  validMessage?: string;
  validation?: any;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  name,
  label,
  helpText,
  disabled,
  className,
  inputProps,
  small,
  validMessage,
  validation,
  checkboxStyle,
}) => {
  const methods = useFormContext();
  const fieldController = useController({ name });
  const { value, ...fieldProps } = fieldController.field;
  const valid = !fieldController.fieldState.invalid;
  const inputElProps: JSX.IntrinsicElements["input"] = {
    ...inputProps,
    ...fieldProps,
    checked:
      typeof inputProps?.checked !== "undefined"
        ? inputProps.checked
        : value || false,
    id: fieldProps.name,
    disabled,
    onChange: (event) => {
      inputProps?.onChange?.(event);
      fieldProps.onChange(event);
    },
    onBlur: (event) => {
      inputProps?.onBlur?.(event);
      fieldProps.onBlur();
    },
  };
  const error = fieldController.fieldState.error;

  return (
    <div className={classNames("form-check", className)}>
      <input
        {...inputElProps}
        {...methods.register(name, validation)}
        type="checkbox"
        className="form-check-input"
      />
      <label
        htmlFor={name}
        className={classNames({
          "form-check-label": checkboxStyle === "toggle",
        })}
      >
        {label}
        {validation?.required && (
          <span className="c-form-element__required">*</span>
        )}
      </label>
      {typeof error !== "undefined" && (
        <div className="invalid-feedback">{error.message}</div>
      )}
      {valid && validMessage && <div className="valid-feedback">{valid}</div>}
      {typeof helpText !== "undefined" && <p className="c-note">{helpText}</p>}
    </div>
  );
};
