import * as Sentry from "@sentry/browser";
import axios, { AxiosError } from "axios";
import { TRestaurantType, TStoreRestaurant } from "types";
import _ from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as api from "../api/endpoints";
import useRequest from "../api/use-request";
import { EVENT_NAME_ORDER_UPDATED } from "../constants";
import {
  IOrder,
  TCartItemOptions,
  TComboMenuItemConfiguration,
} from "../types";
import * as storage from "../utils/storage";
import * as promise from "../utils/promise";
import { useAppContext } from "./app-context";
import { gaItemEvent, gaStartOrderEvent } from "integrations/ga4";

type TError = [
  string | undefined,
  { name: string; handler: () => void } | undefined
];

interface IRestaurantContext {
  restaurant: any;
  restaurantDetailsLoaded: boolean;
  restaurantBaseUrl: string | undefined;
  menu: any;
  optionImages: any;
  order?: any;
  dataDrought: boolean;
  registerError: (
    error: string | string[],
    key?: string,
    blocking?: boolean
  ) => void;
  hasErrors: (key?: string) => boolean;
  hasBlockingErrors: (key?: string) => boolean;
  getErrors: (key?: string) => TError[];
  clearErrors: (key?: string) => void;
  addComboItemToOrder: (
    id: string,
    items: { id: string; options: TCartItemOptions }[],
    name?: string
  ) => Promise<boolean>;
  addItemToOrder: (
    menuItemId: string,
    size: string,
    quantity: number,
    options: TCartItemOptions,
    menuItem: any,
    notes?: string,
    name?: string
  ) => Promise<boolean>;
  updateOrderItem: (
    cartItemId: string,
    menuItemId: string,
    size: string,
    quantity: number,
    options: TCartItemOptions,
    menuItem: any,
    notes?: string
  ) => Promise<boolean>;
  removeItemFromOrder: (
    itemId: string,
    isComboItem?: boolean
  ) => Promise<boolean>;
  updateOrder: (data: IOrder) => Promise<boolean>;
  applyCouponCode: (code: string) => Promise<boolean>;
  removeCoupon: (couponId: string) => Promise<boolean>;
  restaurantType?: TRestaurantType;
  restaurantTypes?: TRestaurantType[];
  gaResturantType: string;
  processOrder: (
    paymentToken: null | string,
    fullName: string,
    phone: string,
    email: string,
    tip?: number,
    fields?: { [formName: string]: string },
    special_instructions?: string,
    billingInfo?: {
      address?: string;
      city?: string;
      state?: string;
      zip?: string;
      country?: string;
    }
  ) => Promise<string|boolean>;
  configBasic: boolean;
  setConfigBasic: (value: boolean) => void;
  handleApiError: (
    error:
      | AxiosError<{
          error: {
            code: number;
            display_message: string | string[];
            messages: string | string[];
          };
        }>
      | string[]
      | string,
    key?: string
  ) => void;
}

export const RestaurantContext = createContext<IRestaurantContext | undefined>(
  undefined
);

interface IRestaurantContextProviderProps {
  data: {
    restaurant?: {
      id: string;
      url: string;
      types: TRestaurantType[];
    };
    order?: {
      id: string;
    };
  };
  options?: {
    createOrderOnEmpty?: boolean;
  };
  children: React.ReactNode;
}

export const RestaurantContextProvider: React.FC<
  IRestaurantContextProviderProps
> = ({ data, options, children }) => {
  const {
    authenticated,
    attemptGrant,
    cardNumber,
    setConnectionError,
    notifySuccess,
    notifyError,
  } = useAppContext();

  const [currentRestaurantData, setCurrentRestaurantData] = useState<
    TStoreRestaurant | undefined
  >(data.restaurant);

  const [configBasic, setConfigBasic] = useState<boolean>(
    storage.getLatestConfigBasic() ?? false
  );

  const [activeOrderId, setActiveOrderId] = useState<string | null | undefined>(
    data.order?.id ?? undefined
  );

  const [errors, setErrors] = useState<
    Record<
      string,
      [
        error: string,
        blocking: boolean,
        action?: { name: string; handler: () => void }
      ][]
    >
  >({});

  const [dataDrought, setDataDrought] = useState(false);

  const { data: restaurant, error: restaurantError } = useRequest(
    api.getRestaurant(currentRestaurantData?.id ?? ""),
    !!currentRestaurantData?.id
  );

  const {
    data: order,
    mutate: mutateOrder,
    error: orderError,
  } = useRequest(api.getOrder(activeOrderId!), !!activeOrderId);

  const { data: menu, error: menuError } = useRequest(
    api.getRestaurantMenu(
      currentRestaurantData?.id ?? "",
      order?.order_date ?? undefined,
      order?.eta ?? ""
    ),
    !!currentRestaurantData?.id
  );

  const { data: optionImages } = useRequest(
    api.getRestaurantMenuOptionImages(currentRestaurantData?.id ?? ""),
    !!currentRestaurantData?.id
  );

  const { data: newOrder, error: newOrderError } = useRequest(
    api.createOrder({
      restaurant_id: restaurant?.id,
      type: restaurant?.default_type ?? "takeout",
    }),
    restaurant !== undefined &&
      restaurant.accepting_orders &&
      activeOrderId === null &&
      options?.createOrderOnEmpty !== false
  );

  const restaurantType = useMemo(
    () =>
      !!currentRestaurantData?.types && currentRestaurantData?.types.length > 0
        ? currentRestaurantData?.types[0]
        : TRestaurantType.Normal,
    [currentRestaurantData]
  );

  const gaResturantType = useMemo(() => {
    switch (restaurantType) {
      case TRestaurantType.NouriaKitchen:
        return "Nouria Kitchen";
      case TRestaurantType.NouriaCafe:
        return "Cafe Nouria";
      case TRestaurantType.Amatos:
        return "Amatos";
      case TRestaurantType.KrispyKrunch:
        return "Krispy Krunch";
      default:
        return "Nouria";
    }
  }, [restaurantType]);

  useEffect(() => {
    if (
      restaurant !== undefined &&
      restaurant.accepting_orders &&
      activeOrderId === null &&
      options?.createOrderOnEmpty !== false
    ) {
      setConfigBasic(false);
      gaStartOrderEvent({
        menu_type: gaResturantType,
        store_location: restaurant?.name ?? "",
      });
    }
  }, [restaurant, activeOrderId, options, gaResturantType]);

  const registerError = useCallback(
    (
      error: string | string[] | { message: string } | { message: string }[],
      key: string = "",
      blocking: boolean = false,
      action?: {
        name: string;
        handler: () => void;
      }
    ) => {
      setErrors((e) => {
        const updatedErrors = { ...e };
        updatedErrors[key] ??= [] as [string, boolean][];
        if (_.isObject(error) && !_.isArray(error)) {
          updatedErrors[key].push([error.message, blocking, action]);
        } else if (_.isArray(error)) {
          error.forEach((message) =>
            updatedErrors[key].push(
              _.isObject(message)
                ? [message.message, blocking, action]
                : [message, blocking, action]
            )
          );
        } else {
          updatedErrors[key].push([error, blocking, action]);
        }
        return updatedErrors;
      });
    },
    []
  );

  const hasErrors = useCallback(
    (key?: string) => {
      return key === undefined
        ? !_.isEmpty(errors)
        : errors[key] && errors[key].length > 0;
    },
    [errors]
  );

  const hasBlockingErrors = useCallback(
    (key?: string) => {
      return key === undefined
        ? Object.values(errors).flatMap((e) =>
            Array.from(e).filter((t) => t[1] === true)
          ).length > 0
        : (Array.from(errors[key]).filter((t) => t[1] === true) ?? []).length >
            0;
    },
    [errors]
  );

  const getErrors = useCallback(
    (key?: string) => {
      return (
        key === undefined
          ? _.uniq(
              Object.values(errors).flatMap((e) =>
                e.map((t) => [t[0], t[2] ?? undefined])
              )
            )
          : errors[key].map((t) => [t[0], t[2]]) ?? []
      ) as TError[];
    },
    [errors]
  );

  const clearErrors = useCallback((key?: string) => {
    setErrors((e) => {
      return key === undefined ? {} : _.omit({ ...e }, [key]);
    });
  }, []);

  const handleApiError = useCallback(
    (
      error:
        | AxiosError<{
            error: {
              code: number;
              display_message: string | string[];
              messages: string | string[];
            };
          }>
        | string[]
        | string,
      key?: string
    ) => {
      clearErrors(key);
      Sentry.captureException(error);
      if (
        axios.isAxiosError(error) &&
        (error.code === "ERR_NETWORK" ||
          error.code === "ERR_INTERNET_DISCONNECTED")
      ) {
        setConnectionError(true);
        return;
      }
      registerError(
        _.isString(error) || _.isArray(error)
          ? error
          : error.response?.data?.error?.display_message ?? "",
        key
      );
    },
    [clearErrors, registerError, setConnectionError]
  );

  const addComboItemToOrder = useCallback(
    async (id: string, items: TComboMenuItemConfiguration[], name?: string) => {
      if (!order) {
        return false;
      }

      try {
        clearErrors("actions.order_item.add");

        const result = await api
          .addComboItemToOrder(order.id, {
            id,
            items,
          })
          .fetch();

        if (result.error !== undefined) {
          handleApiError(result.error, "actions.order_item.add");
          return false;
        }

        notifySuccess(
          name
            ? `${name} has been added to cart.`
            : `Item has been added to cart`
        );
        mutateOrder();
        return true;
      } catch (error: any) {
        handleApiError(error, "actions.order_item.add");
        return false;
      }
    },
    [clearErrors, handleApiError, mutateOrder, notifySuccess, order]
  );

  const addItemToOrder = useCallback(
    async (
      menuItemId: string,
      size: string,
      quantity: number,
      options: TCartItemOptions,
      menuItem: any,
      notes?: string,
      name?: string
    ) => {
      if (!order) {
        return false;
      }

      try {
        clearErrors("actions.order_item.add");

        const result = await api
          .addItemToOrder(order.id, {
            id: menuItemId,
            size,
            quantity,
            options,
            notes,
          })
          .fetch();

        if (result.error !== undefined) {
          handleApiError(result.error, "actions.order_item.add");
          return false;
        }

        // Add item to cart
        // fire GA event
        if (restaurant) {
          let item_price = 0;
          if (result.total_price) {
            if (result.quantity > 1) {
              item_price = result.total_price / result.quantity;
            } else {
              item_price = result.total_price;
            }
          } else {
            if (result?.price) {
              item_price = result?.price;
            }
            if (result.options.length > 0) {
              item_price = result.options.reduce((acc: number, option: any) => {
                if (option.price) {
                  return acc + option.price;
                } else if (option.base_price) {
                  return acc + option.base_price;
                }
                return acc;
              }, item_price);
            }
          }

          gaItemEvent({
            event: "add_to_cart",
            menu_type: gaResturantType,
            currency: "USD",
            store_location: restaurant?.name ?? "",
            value: result.total_price,
            items: [
              {
                index: 0,
                item_id: menuItem?.id ?? "",
                item_name: menuItem?.name ?? "",
                item_category: menuItem?.category ?? "",
                item_brand: gaResturantType,
                price: item_price ?? 0,
                quantity,
                location_id: restaurant?.id ?? "",
              },
            ],
          });
        }

        notifySuccess(
          name
            ? `${name} has been added to cart.`
            : `Item has been added to cart`
        );
        mutateOrder();
        return true;
      } catch (error: any) {
        console.log(error);
        handleApiError(error, "actions.order_item.add");
        return false;
      }
    },
    [
      clearErrors,
      handleApiError,
      mutateOrder,
      notifySuccess,
      order,
      gaResturantType,
      restaurant,
    ]
  );

  const updateOrderItem = useCallback(
    async (
      cartItemId: string,
      menuItemId: string,
      size: string,
      quantity: number,
      options: TCartItemOptions,
      menuItem: any,
      notes?: string
    ) => {
      if (!order) {
        return false;
      }

      try {
        clearErrors("actions.order_item.update");

        const result = await api
          .updateOrderItem(order.id, cartItemId, {
            id: menuItemId,
            size,
            quantity,
            options,
            notes,
          })
          .fetch();

        if (result.error !== undefined) {
          handleApiError(result.error, "actions.order_item.update");
          return false;
        }

        // Add item to cart
        // fire GA event
        if (restaurant) {
          let item_price = 0;
          if (menuItem?.price) {
            item_price = menuItem?.price;
          }
          if (result.options.length > 0) {
            item_price = result.options.reduce((acc: number, option: any) => {
              return acc + option.price;
            }, item_price);
          }
          gaItemEvent({
            event: "add_to_cart",
            menu_type: gaResturantType,
            currency: "USD",
            store_location: restaurant?.name ?? "",
            value: result.total_price,
            items: [
              {
                index: 0,
                item_id: menuItem?.id ?? "",
                item_name: menuItem?.name ?? "",
                item_category: menuItem?.category ?? "",
                item_brand: gaResturantType,
                price: item_price,
                quantity,
                location_id: restaurant?.id ?? "",
              },
            ],
          });
        }

        mutateOrder();
        return true;
      } catch (error: any) {
        handleApiError(error, "actions.order_item.update");
        return false;
      }
    },
    [
      clearErrors,
      handleApiError,
      mutateOrder,
      order,
      gaResturantType,
      restaurant,
    ]
  );

  const removeItemFromOrder = useCallback(
    async (itemId: string, isComboItem: boolean = false) => {
      try {
        clearErrors("actions.order_item.remove");

        const result = await api
          .removeItemFromOrder(order.id, itemId, isComboItem)
          .fetch();

        if (result.error !== undefined) {
          handleApiError(result.error, "actions.order_item.remove");
          return false;
        }

        mutateOrder();
        return true;
      } catch (error: any) {
        handleApiError(error, "actions.order_item.remove");
        return false;
      }
    },
    [clearErrors, handleApiError, mutateOrder, order]
  );

  const updateOrder = useCallback(
    async (data: IOrder) => {
      try {
        clearErrors("actions.order.update");

        if (data.type === "delivery") {
          const deliveryAddressUpdateResult = await api
            .updateDeliveryAddress(order.id, {
              primaryAddress: data.primaryAddress ?? "",
              secondaryAddress: data.secondaryAddress,
              zip: data.zip ?? "",
            })
            .fetch();

          if (deliveryAddressUpdateResult.error !== undefined) {
            handleApiError(
              deliveryAddressUpdateResult.error,
              "actions.order.update"
            );
            return false;
          }

          const updateOrderResult = await api
            .updateOrder(order.id, {
              type: data.type,
              orderingFor: data.orderingFor,
              orderDate: data.orderDate,
              eta: data.eta,
              customerAddress: `${data.primaryAddress ?? ""} ${
                data.secondaryAddress ?? ""
              } ${data.zip ?? ""}`,
            })
            .fetch();

          if (updateOrderResult.error !== undefined) {
            handleApiError(updateOrderResult.error, "actions.order.update");
            return false;
          }
        }

        if (data.type === "takeout") {
          const updateOrderResult = await api
            .updateOrder(order.id, {
              type: data.type,
              orderingFor: data.orderingFor,
              orderDate: data.orderDate,
              eta: data.eta,
            })
            .fetch();

          if (updateOrderResult.error !== undefined) {
            handleApiError(updateOrderResult.error, "actions.order.update");
            return false;
          }
        }

        mutateOrder();
        return true;
      } catch (error: any) {
        handleApiError(error, "actions.order.update");
        mutateOrder();
        return false;
      }
    },
    [clearErrors, handleApiError, mutateOrder, order]
  );

  const applyCouponCode = useCallback(
    async (code: string) => {
      if (!restaurant || !order) {
        return false;
      }

      try {
        clearErrors("actions.order.coupon_code");

        const result = await api.applyCouponToOrder(order.id, code).fetch();

        if (result.error !== undefined) {
          handleApiError(result.error, "actions.order.coupon_code");
          notifyError("Coupon code could not be applied.");
          return false;
        }

        mutateOrder();
        notifySuccess("Coupon code has been applied.");
        return true;
      } catch (error: any) {
        handleApiError(error, "actions.order.coupon_code");
        notifyError("Coupon code could not be applied.");
        mutateOrder();
        return false;
      }
    },
    [
      clearErrors,
      handleApiError,
      mutateOrder,
      order,
      restaurant,
      notifyError,
      notifySuccess,
    ]
  );

  const removeCoupon = useCallback(
    async (couponId: string) => {
      if (!restaurant || !order) {
        return false;
      }

      try {
        clearErrors("actions.order.coupon_code");

        const result = await api
          .removeCouponFromOrder(order.id, couponId)
          .fetch();

        if (result.error !== undefined) {
          handleApiError(result.error, "actions.order.coupon_code");
          notifyError("Coupon code could not be removed.");
          return false;
        }

        mutateOrder();
        notifySuccess("Coupon code has been removed.");
        return true;
      } catch (error: any) {
        handleApiError(error, "actions.order.coupon_code");
        notifyError("Coupon code could not be removed.");
        mutateOrder();
        return false;
      }
    },
    [
      clearErrors,
      handleApiError,
      mutateOrder,
      order,
      restaurant,
      notifyError,
      notifySuccess,
    ]
  );

  const processOrder = useCallback(
    async (
      paymentToken: null | string,
      fullName: string,
      phone: string,
      email: string,
      tip?: number,
      fields?: { [formName: string]: string },
      special_instructions?: string,
      billingInfo?: {
        address?: string;
        city?: string;
        state?: string;
        zip?: string;
        country?: string;
      }
    ) => {
      if (!restaurant || !order) {
        return false;
      }

      try {
        clearErrors("actions.checkout.submit");

        const [firstName, lastName] = fullName.split(/\s+(.*)/g);
        const orderInfo: api.IOrderData = {
          type: order.type,
          orderingFor: order.ordering_for,
          orderDate: order.order_data ?? undefined,
          eta: order.eta ?? undefined,
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          email: email,
          tip,
          paymentType: paymentToken ? "cc" : "pip",
          card: paymentToken
            ? {
                token: paymentToken,
              }
            : undefined,
          tokenType: paymentToken ? "spreedly" : undefined,
          fields,
          specialInstructions: special_instructions ?? "",
          billingInfo,
        };
        if (order.type === "delivery") {
          orderInfo.address = order.address;
          orderInfo.address_line_2 = order.address2;
          orderInfo.zip = order.zip;
        }
        // If the user is authenticated, we need to get a grant token
        let result: any;
        if (authenticated) {
          // Get a grant token.  Grant token will return a TAuthGrant object (which can be successful or failed)
          // or false if the user is not authenticated.
          var grant = await attemptGrant();
          if (
            !grant ||
            !grant.authorizationGrant ||
            grant.error !== undefined
          ) {
            handleApiError("Please login again", "actions.checkout.submit");
            return false;
          }
          // Use the auth token to submit the order on the users behalf
          result = await api
            .submitOrder(
              order.id,
              orderInfo,
              grant.authorizationGrant,
              cardNumber // We store their card number in the cookie when the user is authenticated
            )
            .fetch();
        } else {
          // This is a normal order
          result = await api.submitOrder(order.id, orderInfo).fetch();
        }

        if (result.error !== undefined) {
          handleApiError(result.error, "actions.checkout.submit");
          return false;
        }

        return result.token;
      } catch (error: any) {
        handleApiError(error, "actions.checkout.submit");
        mutateOrder();

        return false;
      }
    },
    [
      clearErrors,
      handleApiError,
      mutateOrder,
      attemptGrant,
      authenticated,
      cardNumber,
      order,
      restaurant,
    ]
  );

  const restaurantDetailsLoaded = useMemo(
    () =>
      !!currentRestaurantData?.id &&
      (!!currentRestaurantData?.url || !!currentRestaurantData?.types),
    [currentRestaurantData]
  );

  const restaurantBaseUrl = useMemo(() => {
    return currentRestaurantData ? currentRestaurantData.url : undefined;
  }, [currentRestaurantData]);

  const restaurantTypes = useMemo(
    () => currentRestaurantData?.types,
    [currentRestaurantData]
  );

  useEffect(() => {
    clearErrors("request.restaurant");
    clearErrors("request.menu");
    clearErrors("request.order");
    clearErrors("request.new_order");
    setConnectionError(null);

    if (restaurantError) {
      handleApiError(restaurantError, "request.restaurant");
    }

    if (menuError) {
      handleApiError(menuError, "request.menu");
    }

    if (orderError) {
      // check error and force create a new one if needed
      if (
        !!restaurant &&
        (orderError.response?.status === 403 ||
          orderError.response?.status === 404)
      ) {
        storage.clearRestaurantOrder(restaurant.id);
        promise
          .waitForCondition(() => !storage.getRestaurantOrder(restaurant.id))
          .then(() => {
            setConfigBasic(false);
            setActiveOrderId(null);
          })
          .catch((_) => {
            setConfigBasic(false);
            setActiveOrderId(null);
          });
      } else {
        handleApiError(orderError, "request.order");
      }
    }

    let timeout: NodeJS.Timeout;
    if (newOrderError) {
      handleApiError(newOrderError, "request.new_order");
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [
    clearErrors,
    handleApiError,
    menuError,
    newOrderError,
    orderError,
    restaurant,
    restaurantError,
    setConnectionError,
  ]);

  // Side effect that executed every time change in restaurant is detected.
  // If restaurant is not undefined, restaurant details loaded from paytronix will be persisted to storage.
  useEffect(() => {
    if (restaurant) {
      storage.updateOrderRestaurantDetails(restaurant.id, {
        name: restaurant.name,
      });
    }
  }, [restaurant]);

  // Side effect to handle restaurant order refresh on load and every change made to the order.
  // This helps us track which order is the latest order with which the user has interacted with.
  useEffect(() => {
    if (!restaurant || !order) {
      return;
    }

    storage.refreshRestaurantOrder(
      restaurant.id,
      order.id,
      (order.items ?? []).reduce((total: number, currentItem: any) => {
        return total + parseInt(currentItem.quantity);
      }, 0) ?? 0
    );

    window.dispatchEvent(new Event(EVENT_NAME_ORDER_UPDATED));
  }, [currentRestaurantData, order, restaurant]);

  useEffect(() => {
    if (restaurant === undefined) {
      return;
    }

    clearErrors("info.restaurant.accepting_orders");

    if (!activeOrderId) {
      setActiveOrderId(storage.getRestaurantOrder(restaurant.id)?.id ?? null);
    }

    if (!restaurant.accepting_orders) {
      registerError(
        restaurant.offline_message,
        "info.restaurant.accepting_orders"
      );
    }
  }, [activeOrderId, clearErrors, registerError, restaurant]);

  // Side effect that loads the restaurant id in cases when the context is mounted without any restaurant data (e.g. order checkout page).
  // In case if order information is provided, restaurant id will be loaded collected the current order.
  // In case if no order information is provided, latest order will be used in case if there is such order found in storage.
  // In case if there is no latest order found, closest restaurant will be loaded assuming client's geo location information can be loaded.
  // In case if geo location information is unavailable, context will contain 0 information about any restaurants and/or oders.
  useEffect(() => {
    if (currentRestaurantData !== undefined) {
      // Update order restaurant details if possible and/or needed
      storage.updateOrderRestaurantDetails(currentRestaurantData.id, {
        url: currentRestaurantData.url,
        types: currentRestaurantData.types,
      });
      return;
    }

    if (order) {
      const restaurantOrder = storage.getRestaurantOrder(order.restaurant);

      if (restaurantOrder?.restaurant) {
        setCurrentRestaurantData({
          id: restaurantOrder.restaurant.id,
          url: restaurantOrder.restaurant.url,
          types: restaurantOrder.restaurant.types,
        });
      } else {
        setCurrentRestaurantData({
          id: order.restaurant,
        });
      }

      return;
    }

    let retries = 0;
    let timeoutId: NodeJS.Timeout | null = null;

    const checkStorage = () => {
      if (retries >= 3) {
        setDataDrought(true);
        return;
      }

      const latestOrder = storage.getLatestOrder();

      if (latestOrder && latestOrder.restaurant) {
        setCurrentRestaurantData({
          id: latestOrder.restaurant.id,
          url: latestOrder.restaurant.url,
          types: latestOrder.restaurant.types,
        });
        return;
      }

      if (storage.isClientNoGeo()) {
        setDataDrought(true);
        return;
      }

      const clientLatLong = storage.getClientLatLong();

      const cachedClosestRestaurant =
        storage.getNearestRestaurant(clientLatLong);

      if (cachedClosestRestaurant) {
        setCurrentRestaurantData({
          id: cachedClosestRestaurant.id,
          url: cachedClosestRestaurant.url,
          types: cachedClosestRestaurant.types,
        });
        return;
      }

      if (clientLatLong) {
        api
          .getLocations(clientLatLong)
          .fetch()
          .then((locations) => {
            const first =
              _.isArray(locations) && !_.isEmpty(locations)
                ? locations.filter(
                    (location) =>
                      location.paytronix_id !== "" && // has to have a paytronix (restaurant) id
                      !!location.online_order_link // has to have a usable menu link (url)
                  )[0]
                : undefined;

            if (!first) {
              setDataDrought(true);
              return;
            }

            const restaurantData = {
              id: first.paytronix_id,
              url: (first.online_order_link as string).startsWith("/")
                ? first.online_order_link
                : new URL(first.online_order_link).pathname,
              types: (first.filters ?? [])
                .map((filter: string) => {
                  switch (filter) {
                    case "nouria_kitchen":
                      return TRestaurantType.NouriaKitchen;
                    case "nouria_kitchen_amatos":
                      return TRestaurantType.Amatos;
                    case "cafe_nouria":
                      return TRestaurantType.NouriaCafe;
                    case "krispy_krunchy":
                      return TRestaurantType.KrispyKrunch;
                    default:
                      return null;
                  }
                })
                .filter((type: TRestaurantType | null) => type !== null),
            };

            storage.saveNearestRestaurant(clientLatLong, restaurantData);

            setCurrentRestaurantData(restaurantData);
          })
          .catch(() => {
            setDataDrought(true);
            return;
          });
      } else {
        retries++;
        timeoutId = setTimeout(checkStorage, 2000); // Retry after 2 seconds
      }
    };

    checkStorage();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [currentRestaurantData, order]);

  useEffect(() => {
    if (restaurant && newOrder && newOrder.order_id) {
      // Remove any legacy restaurant checkout data so we can start fresh
      window.localStorage.removeItem("checkoutData-" + restaurant.id);
      storage.persistRestaurantOrder(
        restaurant.id,
        newOrder.order_id,
        currentRestaurantData?.url,
        restaurant.name,
        configBasic,
        currentRestaurantData?.types
      );
      setActiveOrderId(newOrder.order_id);
    }
  }, [currentRestaurantData, restaurant, newOrder, configBasic]);

  useEffect(() => {
    if (restaurant === undefined || order === undefined) {
      return;
    }
    // when something toggles the config basic, set value to the cookie so
    // it can be used in other pages
    storage.setCookieConfigBasic(restaurant.id, order.id, configBasic);
  }, [configBasic, order, restaurant]);

  useEffect(() => {
    if (menu === undefined || order === undefined) {
      return;
    }

    clearErrors("info.checkout.items_availability");

    const orderItemIds = (order.items as []).map(
      (oi: any) => oi.menuitem as string
    );

    const menuItemsInOrder = menu.menu
      .flatMap((m: any) => m.items)
      .filter((mi: any) => orderItemIds.includes(mi.id))
      .map((mi: any) => mi.id);

    const unavailableOrderItems = order.items.filter(
      (oi: any) => !menuItemsInOrder.includes(oi.menuitem)
    );

    if (unavailableOrderItems.length > 0) {
      const names = unavailableOrderItems.map((oi: any) => oi.name).join(", ");
      registerError(
        `Following items are not available: ${names}.`,
        "info.checkout.items_availability",
        true,
        {
          name: "Remove all unavailable items.",
          handler: async () => {
            await Promise.all(
              unavailableOrderItems.map(async (oi: any) => {
                return await removeItemFromOrder(oi.id);
              })
            );
          },
        }
      );
    } else {
      clearErrors("info.checkout.items_availability");
    }
  }, [menu, order, clearErrors, registerError, removeItemFromOrder]);

  return (
    <RestaurantContext.Provider
      value={{
        restaurant,
        restaurantDetailsLoaded,
        restaurantBaseUrl,
        menu,
        optionImages,
        order,
        dataDrought,
        getErrors,
        registerError,
        clearErrors,
        hasErrors,
        hasBlockingErrors,
        addComboItemToOrder,
        addItemToOrder,
        updateOrderItem,
        removeItemFromOrder,
        updateOrder,
        applyCouponCode,
        removeCoupon,
        processOrder,
        restaurantType,
        restaurantTypes,
        gaResturantType,
        configBasic,
        setConfigBasic,
        handleApiError,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};

export const useRestaurantContext = () => {
  const context = useContext(RestaurantContext);
  if (!context) {
    throw new Error(
      "useRestaurantContext must be used within a RestaurantContextProvider."
    );
  }
  return context;
};
