import DecimalInput from "components/DecimalInput";
import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import { useEffect, useRef } from "react";

interface ITipFieldProps {}

const TipField: React.FC<ITipFieldProps> = () => {
  const { order } = useRestaurantContext();
  const { data, canTip, maxTip, applyTip, removeTip } = useCheckoutContext();

  const tipRef = useRef(data?.tip ?? 0);
  const customInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data?.tipSelection !== "custom") {
      tipRef.current = data?.tip ?? 0;
    }
  }, [data]);

  if (!data) {
    return <></>;
  }

  if (!canTip) {
    return <></>;
  }

  return (
    <>
      <h5 className="mb-1">Tip</h5>
      <div className="row">
        <div className="col-md-8">
          <div className="d-flex flex-wrap gap-1 mb-1">
            <button
              className={`btn btn-outline-primary btn-sm flex-grow-1 ${!data?.tipSelection}`}
              onClick={() => {
                removeTip();
              }}
            >
              0%
            </button>
            {(order.checkout_behaviors?.tips?.percents ?? []).map(
              (value: number, i: number) => (
                <button
                  key={i}
                  className={`btn btn-outline-primary btn-sm flex-grow-1 ${
                    data?.tipSelection === String(value) && "active"
                  }`}
                  onClick={() => {
                    applyTip(value);
                  }}
                >
                  {value}%
                </button>
              )
            )}
          </div>
          <button
            className={`btn btn-outline-primary btn-sm w-100 ${
              data?.tipSelection === "custom" && "active"
            }`}
            onClick={() => {
              applyTip(data.tip ?? 0, true);
              setTimeout(() => {
                customInputRef.current?.focus();
              }, 100);
            }}
          >
            Other
          </button>
        </div>
        <div className="col-md-4">
          <div className="input-group">
            <span className="input-group-text">$</span>

            <DecimalInput
              ref={customInputRef}
              disabled={data.tipSelection !== "custom"}
              minValue={0}
              maxValue={maxTip}
              value={
                data.tipSelection === "custom" ? tipRef.current : data?.tip ?? 0
              }
              onChange={(value) => {
                applyTip(value, true);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default TipField;
