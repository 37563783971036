import { Fragment } from "react";

const ConnectionError = () => {
  return (
    <Fragment>
      <section className="connection-error container">
        <div className="col-12 mb-4 text-center">
          <div className="alert alert-danger">
            <h5>We seem to have lost connection to the Nouria Store.</h5>
            {/* eslint-disable-next-line */}
            <h6>Please check your internet connection and click <a href="#" onClick={() => window.location.reload()}>here</a> to reload.</h6>
          </div>
        </div>
      </section>
      <div className="connection-error-bg"></div>
    </Fragment>
  );
};
export default ConnectionError;
