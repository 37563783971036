import ValueAdjuster from "components/ValueAdjuster";
import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import { reduceOrderItemOptionsForUpdate } from "utils/paytronix";

interface IOrderItemsSummaryProps {}

const OrderItemsSummary: React.FC<IOrderItemsSummaryProps> = () => {
  const { order, restaurantBaseUrl, removeItemFromOrder } =
    useRestaurantContext();
  const { data } = useCheckoutContext();

  if (!data) {
    return <></>;
  }

  return (
    <>
      <h5>Your Order</h5>
      {order?.items.length === 0 ? (
        <p>
          No items in order.{" "}
          <a href={restaurantBaseUrl}> Continue shopping &rsaquo;</a>
        </p>
      ) : (
        <div className="vstack gap-4 p-3 mb-4 bg-body-tertiary rounded-3">
          {order?.items
            .filter((item: any) => !item.combo_id)
            .map((item: any) => (
              <Item key={item.id} item={item} />
            ))}
          {order?.combos.map((combo: any) => (
            <div key={`combo_${combo.id}`}>
              <div className="hstack gap-3 mb-2">
                <p className="flex-shrink-0 mb-0 fw-semibold text-text-hard">
                  {combo.name}{" "}
                  <a
                    href="#delete"
                    onClick={async (e) => {
                      e.preventDefault();
                      await removeItemFromOrder(combo.id, true);
                    }}
                  >
                    &nbsp;(Delete)
                  </a>
                </p>
                <hr className="w-100 py-0" />
              </div>
              {order?.items
                .filter((item: any) => item.combo_id === combo.id)
                .map((item: any) => (
                  <Item key={item.id} item={item} />
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

interface IItemProps {
  item: any;
}

const Item: React.FC<IItemProps> = ({ item }) => {
  const { restaurantBaseUrl, updateOrderItem, removeItemFromOrder } =
    useRestaurantContext();
  const { updating, setUpdating } = useCheckoutContext();

  return (
    <div
      key={item.id}
      className="hstack flex-wrap flex-md-nowrap column-gap-3 row-gap-2"
    >
      <div className="flex-shrink-0">
        {item.images && item.images.length > 0 && (
          <img src={item.images[0]} alt={item.name} width={100} />
        )}
      </div>
      <div className="me-auto">
        <p className="mb-1 fs-md fw-normal">
          {item.name}
          {item.size && item.size !== "" && <>&nbsp;({item.size})</>}
        </p>
        <div className="hstack gap-2">
          {!!restaurantBaseUrl && (
            <a
              className="fs-sm link-text-muted"
              href={`${restaurantBaseUrl}${item.menuitem}?edit=${item.id}`}
            >
              Edit
            </a>
          )}
          {!item.combo_id && (
            <a
              className="fs-sm link-text-muted"
              href="#delete"
              onClick={async (e) => {
                e.preventDefault();
                if (updating) return;
                setUpdating(true);
                await removeItemFromOrder(item.id);
                setUpdating(false);
              }}
            >
              Delete
            </a>
          )}
        </div>

        {item.options.length > 0 && (
          <p className="mb-0">
            {item.options.map((option: any) => option.name).join(", ")}
          </p>
        )}
      </div>
      <div className="hstack gap-2">
        {!item.combo_id && (
          <ValueAdjuster
            value={parseInt(item.quantity)}
            min={1}
            disabled={updating}
            onChange={async (quantity) => {
              setUpdating(true);
              await updateOrderItem(
                item.id,
                item.menuitem,
                item.size,
                quantity,
                reduceOrderItemOptionsForUpdate(item.options),
                item,
              );
              setTimeout(() => {
                setUpdating(false);
              }, 200);
            }}
          />
        )}
        <h5 className="mb-0">${item.total_price.toFixed(2)}</h5>
      </div>
    </div>
  );
};

export default OrderItemsSummary;
