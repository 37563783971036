import Skeleton from "react-loading-skeleton";

interface IHeaderProps {
  cta?: {
    label: string;
    url: string;
  };
  loading?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ cta, loading }) => {
  return (
    <header className="header">
      <div className="header-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header-bottom-inner">
                <a className="header-branding" href="/">
                  <img
                    src="/wp-content/themes/nouria/assets/images/layout/nouria-logo.png"
                    alt="#"
                  />
                </a>
                {!!loading ? (
                  <span className="header-cart">
                    <Skeleton width={85} />
                  </span>
                ) : (
                  <>
                    {!!cta && (
                      <a className="header-cart" href={cta.url}>
                        {cta.label}
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
