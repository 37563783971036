import classNames from "classnames";
import React from "react";
import { useController, useFormContext } from "react-hook-form";
interface InputFieldSharedProps {
  name: string;
  icon?: React.ReactNode;
  label?: string;
  placeholder?: string;
  helpText?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  elStyle?: "fill";
  small?: boolean;
  large?: boolean;
  defaultValue?: string;
  validMessage?: string;
  validation?: any;
  formatter?: (value: string) => string;
  AdditionalButton?: React.ReactNode;
  onChange?: () => void;
}

interface InputHtmlInputFieldProps extends InputFieldSharedProps {
  type?: "text" | "email" | "password" | "file" | "number" | "date" | "tel";
  inputProps?: Omit<
    JSX.IntrinsicElements["input"],
    "name" | "type" | "disabled" | "className" | "placeholder"
  >;
}

interface InputHtmlTextareaFieldProps extends InputFieldSharedProps {
  type?: "textarea";
  inputProps?: Omit<
    JSX.IntrinsicElements["textarea"],
    "name" | "type" | "disabled" | "className" | "placeholder" | "children"
  >;
}

type InputFieldProps = InputHtmlInputFieldProps | InputHtmlTextareaFieldProps;
export const InputField = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputFieldProps
>(
  ({
    name,
    type = "text",
    label,
    placeholder,
    helpText,
    disabled,
    className,
    inputProps,
    validation,
    large,
    small,
    defaultValue,
    validMessage,
    formatter,
    AdditionalButton,
    onChange
  }, ref) =>
    //ref
    {
      const methods = useFormContext();
      const {
        control,
        formState: { isSubmitting },
      } = methods;
      const wrapRef = React.useRef<HTMLDivElement>(null);
      const fieldController = useController({ control, name, defaultValue });
      const { value, ...fieldProps } = fieldController.field;
      const valid = !fieldController.fieldState.invalid && fieldController.fieldState.isTouched;
      const inputElProps = {
        ...inputProps,
        ...fieldProps,
        value:
          type === "file" || type === "password"
            ? undefined
            : type === "number"
            ? typeof value === "number" && !Number.isNaN(value)
              ? value
              : ""
            : value || "",
        placeholder: typeof label !== "undefined" ? label : placeholder,
        disabled: disabled || isSubmitting,
        ref,
      };
      const error = fieldController.fieldState.error;
      return (
        <div
          className={classNames("form-group", {
            // [`c-form-element--style-${elStyle}`]: elStyle,
            // 'c-form-element--file': type === 'file',
            "has-success": valid,
            "has-danger": typeof error !== "undefined",
          })}
          ref={wrapRef}
        >
          <label
            htmlFor={name}
            className={classNames("form-label", {
              "col-form-label-lg": large,
              "col-form-label-sm": small,
            })}
          >
            {label}
            {validation?.required && (
              <span className="c-form-element__required">*</span>
            )}
          </label>
          <div className="input-group">
            {methods && (
              <input
                className={classNames(
                  "form-control",
                  {
                    "is-invalid": error !== undefined,
                    "is-valid": valid,
                    "form-control-sm": small,
                    "form-control-lg": large,
                  },
                  className
                )}
                {...(inputElProps as JSX.IntrinsicElements["input"])}
                {...methods.register(name, validation)}
                type={type}
                placeholder={placeholder}
                aria-required={validation?.required ? "true" : "false"}
                onChange={(e) => {
                  if (formatter) e.target.value = formatter(e.target.value);
                  fieldProps.onChange(e);
                  onChange && onChange();
                }}
              />
            )}
            {AdditionalButton !== undefined && AdditionalButton}
            {typeof error !== "undefined" && (
              <div className="invalid-feedback">{error.message}</div>
            )}
            {valid && validMessage && (
              <div className="valid-feedback">{validMessage}</div>
            )}
          </div>
          {typeof helpText !== "undefined" && (
            <p className="c-note">{helpText}</p>
          )}
        </div>
      );
    }
);

export default InputField;
