export const waitForCondition = (
  condition: () => boolean,
  interval = 100,
  timeout = 5000
) => {
  return new Promise<void>((resolve, reject) => {
    const startTime = Date.now();
    const checkCondition = () => {
      if (condition()) {
        resolve();
      } else if (Date.now() - startTime > timeout) {
        reject(new Error("Timeout waiting for condition"));
      } else {
        setTimeout(checkCondition, interval);
      }
    };
    checkCondition();
  });
};
