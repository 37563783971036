interface IProgressProps {}

interface IProgressProps {
  title?: string;
  steps?: { name: string; active: boolean }[];
  onStepChange?: (step: number) => void;
}

const Progress: React.FC<IProgressProps> = ({ title, steps, onStepChange }) => {
  return (
    <section className="py-4 bg-secondary">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="hstack justify-content-between flex-wrap gap-2">
              <h3 className="mb-0 text-text-hard-invert">{title}</h3>
              <nav className="cart-pagination">
                <ul className="cart-pagination-list">
                  {steps?.map((step, index) => {
                    const stepNumber = index + 1;
                    return step.active ? (
                      <li key={index} className="cart-pagination-item">
                        <span>{stepNumber}</span> {step.name}
                      </li>
                    ) : (
                      <li key={index} className="cart-pagination-item">
                        <a
                          href={`#step-${stepNumber}`}
                          onClick={(e) => {
                            e.preventDefault();
                            if (!!onStepChange) {
                              onStepChange(stepNumber);
                            }
                          }}
                        >
                          <span>{stepNumber}</span> {step.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Progress;
