import { paytronixAuthenticate, paytronixResetConfirm } from "api/endpoints";
import FormErrorDisplay from "components/form/Error";
import InputField from "components/form/InputField";
import { useAppContext } from "contexts/app-context";
import { useAuthContext } from "contexts/auth-context";
import { useCallback, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
type ResetInputs = {
  email: string;
  password: string;
  confirm_password: string;
};
const ConfirmReset = () => {
  const [searchParams] = useSearchParams();
  const { setNetworkError, setAuthCookie, notifySuccess } = useAppContext();
  const { registerError, clearErrors, checkForPaytronixError, getProfile } =
    useAuthContext();
  const [toast, setToast] = useState<string | null>(null);
  const methods = useForm<ResetInputs>({ mode: "onChange" });
  const code = searchParams.get("resetCode") ?? "";

  /**
   * Attempt to login
   * @param username
   * @param password
   */
  const attemptLogin = useCallback(
    async (username: string, password: string) => {
      try {
        clearErrors("actions.auth.login");
        const authenticate = await paytronixAuthenticate(
          username,
          password
        ).fetch();
        const loginSuccess = checkForPaytronixError(
          authenticate,
          "actions.auth.update_profile"
        );
        if (loginSuccess) {
          setAuthCookie(authenticate);
          // Get the user's profile
          await getProfile(
            username,
            authenticate.printedCardNumber,
            authenticate.access_token
          );
          return true;
        }
        return false;
      } catch (error: any) {
        setNetworkError(error.message, error);
        if (error.response.data)
          checkForPaytronixError(error.response.data, "actions.auth.login");
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearErrors, checkForPaytronixError]
  );

  const onSubmit: SubmitHandler<ResetInputs> = async ({ email, password }) => {
    clearErrors("actions.auth.confirm");
    setToast(null);
    try {
      const reset = await paytronixResetConfirm(code, password).fetch();
      // This has to return true if the request succeeds
      // So no need to catch exceptions
      if (reset.errorMessage) {
        registerError(reset.errorMessage, "actions.auth.confirm");
        return false;
      }
      notifySuccess("Password reset successfully. Logging you in ...");
      await attemptLogin(email, password);
      notifySuccess("Welcome back!");
    } catch (err: any) {
      setNetworkError(err.message, err);
      if (err.response && err.response.data.errorMessage) {
        registerError(err.response.data.errorMessage, "actions.auth.confirm");
      } else {
        registerError(
          "Sorry, we couldn't reset your password. Please try again later.",
          "actions.auth.confirm"
        );
      }
    }
  };

  return (
    <>
      <div
        className="block-background d-none d-lg-block"
        style={{ width: "39%" }}
      >
        <img
          className="block-background-image"
          src="/wp-content/themes/nouria/assets/images/content/section-bg-4.jpeg"
          alt="#"
        />
      </div>
      <div className="row justify-content-end gy-2">
        <div className="col-lg-7">
          <h4 className="mb-1">Password Reset</h4>
          <FormErrorDisplay error="actions.auth.confirm" />
          {toast && <div className="alert alert-success">{toast}</div>}
          <p className="fs-md">
            To reset your password, enter your email address and a new password
            below.
          </p>
          <hr className="my-3" />
          <FormProvider {...methods}>
            <form className="row g-2" onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="col-12">
                <InputField
                  name="email"
                  label="Email"
                  placeholder="Email..."
                  disabled={methods.formState.isSubmitting}
                  validation={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "That doesn't look like an email",
                    },
                  }}
                />

                <InputField
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="Password..."
                  validation={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                  }}
                />
                <InputField
                  name="confirm_password"
                  label="Repeat Password"
                  type="password"
                  placeholder="Repeat Password..."
                  validation={{
                    required: "required",
                    validate: (value: string) =>
                      value === methods.getValues("password") ||
                      "The passwords do not match",
                  }}
                />
              </div>
              <div className="col-12 mt-3">
                <div className="hstack gap-2">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm ms-auto"
                    disabled={
                      methods.formState.isSubmitting ||
                      !methods.formState.isValid
                    }
                  >
                    {methods.formState.isSubmitting ? (
                      <>Trying to Update Password ...</>
                    ) : (
                      <>Update Password</>
                    )}
                  </button>
                </div>
              </div>
              <div className="col-md-12">
                <hr className="mb-1" />
              </div>
              <div className="col-12">
                <div className="hstack gap-2">
                  <Link to="/forgot" className="fs-sm link-text-muted">
                    Reset Password
                  </Link>
                  <Link to="/login" className="fs-sm link-text-muted ms-auto">
                    Sign In
                  </Link>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
export default ConfirmReset;
