import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { formatPhoneNumber } from "utils/string";
import InputField from "./InputField";

function MultiplePhoneFields({ name, values }: any) {
  const { control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "phones", // unique name for your Field Array
  });
  if(fields.length === 0) 
    append({ value: "" });

    useEffect(() => {
    remove();
    values.forEach((value: any) => {
      append({ value: formatPhoneNumber(value) });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  return (
    <div>
      {fields.map((field: any, index: number) => (
        <InputField
          key={field.id} // important to include key with field's id
          name={`phones.${index}`}
          type="tel"
          label="Phone Number"
          placeholder="Phone Number..."
          formatter={formatPhoneNumber}
          defaultValue={field.value}
          validation={{
            pattern: {
              value: /^\+?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,6}$/im,
              message: "That doesn't look like a phone number",
            },
          }}
        />
      ))}

    </div>
  );
}
export default MultiplePhoneFields;
/**
 * <button
        type="button"
        className="btn btn-link btn-sm"
        onClick={() => {
          append({ value: "" });
        }}
      >
        Add Phone
      </button>
 */