import { useLocationsContext } from "contexts/locations-context";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const ModalFilters = () => {
  const { setFilters: contextFilters, doSearch } = useLocationsContext();
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<string[]>(searchParams.getAll("filters") || []);
  const setFilter = (filter: string) => {
    if (filters.includes(filter)) {
      setFilters(filters.filter((f) => f !== filter));
    } else {
      setFilters([...filters, filter]);
    }
  };

  const filterOptions = [
    { value: "nouria-fuel", label: "Nouria Fuel" },
    { value: "ev-charging", label: "EV Charging Stations" },
    { value: "golden-nozzle-car-wash", label: "Golden Nozzle Car Wash" },
    {
      value: "nourias-kitchen",
      label: "Nouria Kitchen",
      children: [
        {
          value: "nourias-kitchen-amatos",
          label: "Nouria Kitchen featuring Amatos",
        },
        {
          value: "nourias-kitchen-krispy-krunchy-chicken",
          label: "Nouria Kitchen featuring Krispy Krunchy Chicken",
        },
      ],
    },
    { value: "cafe-nouria", label: "Cafe Nouria" },
    {
      value: "beer-wine",
      label: "Beer/Wine",
      children: [{ value: "liquor", label: "Liquor" }],
    },
    { value: "ebt-accepted", label: "EBT Accepted" },
    { value: "premium-cigars", label: "Premium Cigars" },
    { value: "atm", label: "ATM" },
    { value: "dunkin-donuts", label: "Dunkin Donuts" },
  ];
  const submitFilters = () => {
    contextFilters(filters);
    doSearch({
      filters,
    });
  };
  const clearFilters = () => {
    setFilters([]);
    contextFilters([]);
  };
  return (
    <div
      className="modal fade"
      id="filterModal"
      tabIndex={-1}
      aria-labelledby="filtereModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="filterModalLabel">
              Filter Results
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-n1">
              {filterOptions.map((option) => (
                <div className="form-check mb-1" key={option.value}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={option.value}
                    id={"checkbox_" + option.value}
                    onChange={() => setFilter(option.value)}
                    checked={filters.includes(option.value)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={"checkbox_" + option.value}
                  >
                    {option.label}
                  </label>
                  {option.children && (
                    <div className="mt-1">
                      {option.children.map((child) => (
                        <div className="form-check mb-1" key={child.value}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={child.value}
                            id={"checkbox_" + child.value}
                            onChange={() => setFilter(child.value)}
                            checked={filters.includes(child.value)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={"checkbox_" + child.value}
                          >
                            {child.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={clearFilters}
            >
              Clear Filters
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={submitFilters}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalFilters;
