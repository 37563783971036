export const nouriaPaytronixFieldError: {
  [key: string]: { [key: string]: string };
} = {
  register: {
    email: "email",
    "setUserFields/email": "email",
    "setUserFields/username": "email",
    "setUserFields/first_name": "first_name",
    "setUserFields/last_name": "last_name",
    "setUserFields/postalCode": "zip",
    "setUserFields/mobilePhone": "mobile_phone",
    "setUserFields/password": "password",
  },
  contact: {
    "setUserFields/phone": "phones[0]",
    "setUserFields/address": "addresses[0].address",
    "setUserFields/zip": "addresses[0].zip",
  },
};

export const lookupErrorField = (
  key: string,
  form: "register" | "contact"
): string => {
  if (nouriaPaytronixFieldError[form][key]) {
    return nouriaPaytronixFieldError[form][key];
  }
  return key;
};

export const mapErrorToRegistrationField = (
  key: string
):
  | "email"
  | "first_name"
  | "last_name"
  | "zip"
  | "mobile_phone"
  | "password"
  | "confirm_password"
  | "root"
  | "terms" => {
  key = lookupErrorField(key, "register");
  return key as
    | "first_name"
    | "last_name"
    | "zip"
    | "mobile_phone"
    | "password"
    | "confirm_password"
    | "root"
    | "terms";
};

export const mapErrorToContactField = (
  key: string
):
  | "root"
  | `root.${string}`
  | "phones"
  | "addresses"
  | `phones.${number}`
  | `addresses.${number}`
  | `addresses.${number}.zip`
  | `addresses.${number}.id`
  | `addresses.${number}.address`
  | `addresses.${number}.address_line_2`
  | `addresses.${number}.company` => {
  key = lookupErrorField(key, "contact");
  return key as
    | "root"
    | `root.${string}`
    | "phones"
    | "addresses"
    | `phones.${number}`
    | `addresses.${number}`
    | `addresses.${number}.zip`
    | `addresses.${number}.id`
    | `addresses.${number}.address`
    | `addresses.${number}.address_line_2`
    | `addresses.${number}.company`;
};

export const transformPaytronixErrorKey = (key: string): string => {
  let newkey = lookupErrorField(key, 'register');
  if(key === newkey) newkey = lookupErrorField(key, 'contact');
  if(key === newkey) return key;
  let value = newkey.charAt(0).toUpperCase() + newkey.slice(1);
  return value.replace(/_/g, " ").replace("[0]", "");
};
