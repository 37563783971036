import useRequest from "api/use-request";
import OrderWarnings from "components/checkout/OrderWarnings";
import _ from "lodash";
import React, { Fragment } from "react";
import { formatDateTime } from "utils/dates";
import { formatPhoneNumber } from "utils/string";
import * as api from "../api/endpoints";
import { useParams, useSearchParams } from "react-router-dom";

interface IHistoryOrderProps {
  orderId?: string | null;
  token?: string | null;
}

const HistoryOrder: React.FC<IHistoryOrderProps> = ({ orderId, token }) => {
  let params = useParams();
  const [searchParams] = useSearchParams();
  orderId = params.orderId;
  token = searchParams.get("token");

  const { data: order } = useRequest(
    api.getOrder(orderId!, token!),
    !!orderId && !!token
  );

  const { data: restaurant } = useRequest(
    api.getRestaurant(order?.restaurant),
    !!order
  );

  if (!order || !restaurant) {
    return <></>;
  }

  const dueSeconds = order.due_timestamp - Date.now() / 1000;
  return (
    <div className="container">
      <div className="row pt-2 pb-3">
        <div className="col-md-12">
          <h2>Thanks, We're preparing your order</h2>
          <div className="alert alert-info">
            Order Status: {order.status ?? "N/A"}
          </div>
          {dueSeconds < 0 && (
            <div className="alert alert-warning">
              Sorry. Your order is a bit overdue. We're working to finish it.
              Please <a href={`tel:${restaurant.phone}`}>call us</a> if you have
              questions.
            </div>
          )}
        </div>
        <OrderWarnings restaurant={restaurant} order={order} />
        <div className="col-md-6">
          <h3>Ordered from</h3>
          <div className="pt-2 pb-1 my-2 px-3 bg-light rounded">
            <p>
              <strong>{restaurant.name}</strong>
              <br />
              {restaurant.address} {restaurant.city} {restaurant.state}{" "}
              {restaurant.zip} <br />
              <a href={`tel:${restaurant.phone}`}>
                {formatPhoneNumber(restaurant.phone)}
              </a>
            </p>
            {order.type === "delivery" ? (
              <>
                {dueSeconds < 0 ? (
                  <p>
                    Sorry. Your order is a bit overdue. We're working to finish
                    it. Please call us if you have questions.
                  </p>
                ) : order.ordering_for && order.ordering_for === "later" ? (
                  <p>
                    Your Order will be delivered approximately at{" "}
                    {order.order_date} {order.eta}
                  </p>
                ) : order.ordering_for && order.ordering_for === "now" ? (
                  <p>
                    Your order will be delivered in{" "}
                    {Math.round(dueSeconds / 60)}
                    minutes
                  </p>
                ) : (
                  <p>
                    Your Order will be delivered approximately at{" "}
                    {formatDateTime(
                      new Date(order.due_timestamp * 1000),
                      restaurant.tz
                    )}
                  </p>
                )}
              </>
            ) : (
              <>
                {dueSeconds < 0 ? (
                  <p>
                    Sorry. Your order is a bit overdue. We're working to finish
                    it. Please call us if you have questions.
                  </p>
                ) : order.ordering_for && order.ordering_for === "later" ? (
                  <p>
                    Your order will be ready at {order.order_date} {order.eta}
                  </p>
                ) : order.ordering_for && order.ordering_for === "now" ? (
                  <p>
                    Your order will be ready in {Math.round(dueSeconds / 60)}{" "}
                    minutes
                  </p>
                ) : (
                  <p>
                    Your order will be ready at{" "}
                    {formatDateTime(
                      new Date(order.due_timestamp * 1000),
                      restaurant.tz
                    )}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="col-md-6">
          <h3>Order Information</h3>
          <div className="pt-2 pb-1 my-2 px-3 bg-light rounded">
            <p>
              <strong>Your Order ID: #{order.short_id ?? "N/A"}</strong> <br />
              {order.type === "delivery" && (
                <>Deliver to: {order.location?.formatted_address ?? "N/A"}</>
              )}
            </p>
            <p>
              Order Type:{" "}
              {order.type.charAt(0).toUpperCase() + order.type.slice(1)} <br />
              Submitted at {order.submit_time} <br />
              {order.fields &&
                order.fields
                  ?.filter((field: any) => field.value)
                  .map((field: any, index: number) => (
                    <Fragment key={`field-${index}`}>
                      {field.name}: {field.value} <br />
                    </Fragment>
                  ))}
            </p>
          </div>
        </div>
        <div className="col-md-12">
          <h3>Order Summary</h3>
          <div className="py-2 my-2 bg-light rounded">
            {order?.items.map((item: any) => (
              <div key={item.id} className="d-flex align-items-cente-r pb-2">
                <div className="px-2">
                  {item.images && item.images.length > 0 && (
                    <img src={item.images[0]} alt={item.name} width={30} />
                  )}
                </div>
                <div className="flex-grow-1">
                  <p className="mb-0">
                    <strong>
                      {item.name}
                      {item.size && item.size !== "" && (
                        <>&nbsp;({item.size})</>
                      )}
                      &nbsp;x{item.quantity}
                    </strong>
                  </p>
                  {item.options.length > 0 && (
                    <p className="mb-0">
                      {item.options
                        .map((option: any) => option.name)
                        .join(", ")}
                    </p>
                  )}
                </div>
                <div className="px-2">
                  <strong>${item.price}</strong>
                </div>
              </div>
            ))}

            <div className="col-4 ms-auto vstack gap-1">
              <div className="hstack justify-content-between gap-2">
                <div className="px-2">
                  <p className="mb-0">
                    <strong>Subtotal:</strong>
                  </p>
                </div>
                <div className="px-2">
                  <strong>${Number(order.subtotal).toFixed(2)}</strong>
                </div>
              </div>
              {order.type === "delivery" && (
                <div className="hstack justify-content-between gap-2">
                  <div className="px-2">
                    <p className="mb-0">
                      <strong>Delivery fee:</strong>
                    </p>
                  </div>
                  <div className="px-2">
                    <strong>${Number(order.delivery_fee).toFixed(2)}</strong>
                  </div>
                </div>
              )}

              <div className="hstack justify-content-between gap-2">
                <div className="px-2">
                  <p className="mb-0">
                    <strong>Convenience Fee:</strong>
                  </p>
                </div>
                <div className="px-2">
                  <strong>${Number(order.fee).toFixed(2)}</strong>
                </div>
              </div>
              <div className="hstack justify-content-between gap-2">
                <div className="px-2">
                  <p className="mb-0">
                    <strong>Tax:</strong>
                  </p>
                </div>
                <div className="px-2">
                  <strong>${Number(order.tax).toFixed(2)}</strong>
                </div>
              </div>
              {order.active_taxes?.map((activeTax: any) => (
                <div
                  key={`tax-${activeTax.tax_id}`}
                  className="hstack justify-content-between gap-2"
                >
                  <div className="px-2">
                    <p className="mb-0">
                      <strong>
                        {activeTax.name} {activeTax.tax_id}:
                      </strong>
                    </p>
                  </div>
                  <div className="px-2">
                    <strong>${Number(activeTax.amount).toFixed(2)}</strong>
                  </div>
                </div>
              ))}
              {!!order.tip && order.tip > 0 && (
                <div className="hstack justify-content-between gap-2">
                  <div className="px-2">
                    <p className="mb-0">
                      <strong>Tip:</strong>
                    </p>
                  </div>
                  <div className="px-2">
                    <strong>${Number(order.tip).toFixed(2)}</strong>
                  </div>
                </div>
              )}
              <div className="hstack justify-content-between gap-2">
                <div className="px-2">
                  <p className="mb-0">
                    <strong>Total:</strong>
                  </p>
                </div>
                <div className="px-2">
                  <strong>${_.round(order.total_amount, 2).toFixed(2)}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryOrder;
