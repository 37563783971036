import { useAppContext } from "contexts/app-context";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

function AuthLogout() {
  const { resetAuthState, authenticated, notifySuccess } = useAppContext();
  useEffect(() => {
    resetAuthState(true);
    notifySuccess("Logged Out");
  }, [resetAuthState, notifySuccess]);
  if (authenticated) return <>Logging Out</>;
  return <Navigate to="/" replace />;
}
export default AuthLogout;
