import { useAuthContext } from "contexts/auth-context";
import { useEffect, useState } from "react";
import { transformPaytronixErrorKey } from "utils/paytronixErrors";

const FormErrorDisplay = ({ error }: { error: string }) => {
  const { errors: authErrors, getErrors, fieldErrors } = useAuthContext();
  const [errors, setErrors] = useState<string[]>([]);
  useEffect(() => {
    const localError = getErrors(error);
    setErrors(localError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authErrors, fieldErrors]);
  return (
    <>
      {(errors.length > 0 ||
        Object.keys(fieldErrors).length > 0) && (
          <div className="alert alert-danger" role="alert">
            {errors.length > 0 &&
              errors.map((error, index) => (
                <div key={`error-${index}`}>{error}</div>
              ))}
              
            {Object.keys(fieldErrors).length > 0 &&
              Object.keys(fieldErrors).map((key) => (
                <div key={`error-${key}`}>
                  {transformPaytronixErrorKey(key)}: {fieldErrors[key]}
                </div>
              ))}
          </div>
        )}
    </>
  );
};

export default FormErrorDisplay;
