import ValueAdjuster from "components/ValueAdjuster";
import { useAppContext } from "contexts/app-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import React, { useEffect, useMemo } from "react";
import { TCartItemOptions } from "types";

interface IMenuItemConfiguratorProps {
  name: string;
  description: string;
  image: string;
  prices: any[];
  optionGroups: any[];
  fields: {
    quantity: boolean;
    price: boolean;
  };
  flags?: {
    soldOut: boolean;
    tobacco: boolean;
    alcohol: boolean;
  };
  action?: {
    name: string;
    handler: (
      size: string,
      quantity: number,
      options: TCartItemOptions,
      note: string
    ) => Promise<void>;
  };
  defaults?: {
    size?: string;
    quantity?: number;
    options?: TCartItemOptions;
    notes?: string;
  };
}

const MenuItemConfigurator: React.FC<IMenuItemConfiguratorProps> = ({
  name,
  description,
  image,
  prices,
  optionGroups,
  fields,
  flags,
  action,
  defaults,
}) => {
  const { notifyWarning } = useAppContext();
  const { restaurant, order, optionImages } = useRestaurantContext();

  const [size, setSize] = React.useState<string | undefined>(
    defaults?.size ?? undefined
  );
  const [quantity, setQuantity] = React.useState<number>(
    defaults?.quantity ?? 1
  );
  const [options, setOptions] = React.useState<TCartItemOptions>(
    defaults?.options ?? {}
  );
  const [notes, setNotes] = React.useState<string>(defaults?.notes ?? "");

  const [processing, setProcessing] = React.useState<boolean>(false);

  useEffect(() => {
    if (defaults) {
      setSize(defaults.size);
      setQuantity(defaults.quantity ?? 1);
      setOptions(defaults.options ?? {});
      setNotes(defaults.notes ?? "");
    }
  }, [defaults]);

  useEffect(() => {
    const imageMap = new Map();
    if (optionImages && optionImages.option_images) {
      if (optionImages.option_images.length > 0) {
        // eslint-disable-next-line array-callback-return
        optionImages.option_images.map((obj: any) => {
          imageMap.set(obj.name, obj.url);
        });
      } else if (Object.values(optionImages.option_images).length > 0) {
        // eslint-disable-next-line array-callback-return
        Object.values(optionImages.option_images).map((obj: any) => {
          imageMap.set(obj.name, obj.url);
        });
      }
      optionGroups.forEach((group) => {
        group.options.forEach((option: any) => {
          if (imageMap.get(option.name)) {
            option.image = (
              <figure className="menu-card-image">
                <img src={imageMap.get(option.name)} alt={option.name} />
              </figure>
            );
          }
        });
      });
    }
  }, [optionImages, optionGroups]);

  useEffect(() => {
    if (size === undefined) {
      if (prices.length <= 0) {
        return;
      }

      const defaultSize = prices.find((p: any) => p.is_default) || prices[0];

      if (!defaultSize) {
        return;
      }

      setSize(defaultSize.name);
    }
  }, [prices, size]);

  const groupTotalQuantityMap: Record<string, number> = useMemo(() => {
    return (
      optionGroups.reduce((a: Record<string, number>, c: any) => {
        return {
          ...a,
          [c.name]:
            options[c.name]?.reduce(
              (total, currentValue) => total + currentValue.quantity,
              0
            ) ?? 0,
        };
      }, {}) ?? {}
    );
  }, [optionGroups, options]);

  const isValid = useMemo(() => {
    if (processing) {
      return false;
    }

    if (flags?.soldOut) {
      return false;
    }

    if (size === undefined) {
      return false;
    }

    if (quantity < 0) {
      return false;
    }

    if (!order?.id) {
      return false;
    }

    let result = true;

    optionGroups?.forEach((og: any) => {
      const totalForOptionGroup = groupTotalQuantityMap[og.name];

      // check if the total is less than min
      if (totalForOptionGroup < og.min) {
        result = false;
      }

      // check if total is greater than max (only if max is greater than 0)
      if (og.max > 0 && totalForOptionGroup > og.max) {
        result = false;
      }
    });

    return result;
  }, [
    processing,
    flags,
    size,
    quantity,
    order?.id,
    optionGroups,
    groupTotalQuantityMap,
  ]);

  const totalPrice: number = useMemo(() => {
    if (size === undefined) {
      return undefined;
    }

    let totalPrice =
      prices.find((p: any) => p.name === size)?.price ?? undefined;

    if (totalPrice === undefined) {
      return undefined;
    }

    Object.keys(options).forEach((o) => {
      const optionsGroupDefinition = optionGroups?.find(
        (og: any) => og.name === o
      );

      if (optionsGroupDefinition) {
        totalPrice += options[o].reduce((p, v) => {
          const definition = optionsGroupDefinition.options.find(
            (o: any) => o.name === v.name
          );

          if (definition?.price !== undefined) {
            return p + definition.price;
          }

          if (definition?.prices !== undefined && size !== undefined) {
            return p + definition.prices[size];
          }

          return p;
        }, 0);
      }
    });

    totalPrice *= quantity;

    return totalPrice.toFixed(2);
  }, [optionGroups, options, prices, quantity, size]);

  const getOptionGroupOption = (key: string, name: string): number => {
    if (options[key]) {
      const value = options[key].find((v) => v.name === name);
      return value ? value.quantity : 0;
    }

    return 0;
  };

  const updateOptionGroupOption = (
    key: string,
    name: string,
    quantity: number
  ): void => {
    const optionGroupDefinition = optionGroups?.find(
      (og: any) => og.name === key
    );
    const normalizedQuantity = Math.max(0, quantity);
    let updatedOptions: TCartItemOptions = { ...options };

    const existingItem = updatedOptions[key];
    const multiselect = optionGroupDefinition?.multiselect ?? true;

    if (updatedOptions[key]) {
      const existingValueIndex = existingItem.findIndex((v) => v.name === name);

      if (existingValueIndex !== -1) {
        // If quantity is 0, remove the value array item
        if (quantity === 0) {
          updatedOptions[key].splice(existingValueIndex, 1);
        } else {
          // If value entry with the same name exists, update quantity
          updatedOptions[key][existingValueIndex].quantity = normalizedQuantity;
        }
      } else {
        // If value entry with the same name doesn't exist and quantity is not 0, add new entry
        if (quantity !== 0) {
          if (
            multiselect &&
            optionGroupDefinition?.max > 0 &&
            updatedOptions[key].length >= optionGroupDefinition?.max
          ) {
            notifyWarning(
              `You can only select up to ${optionGroupDefinition?.max} items from ${key}`,
              "top-center"
            );
            return;
          }
          updatedOptions[key].push({
            name,
            quantity: normalizedQuantity,
          });
          // If multiselect is false, remove any existing value records
          if (!multiselect) {
            updatedOptions[key] = [{ name, quantity: normalizedQuantity }];
          }
        }
      }
    } else {
      // If item with the same key doesn't exist and quantity is not 0, add new item
      if (quantity !== 0) {
        updatedOptions[key] = [{ name, quantity: normalizedQuantity }];
      }
    }

    setOptions(updatedOptions);
  };

  return (
    <>
      <div className="row gy-4">
        <div className="col-lg-4">
          <figure
            className="mb-0 align-top menu-item-image"
            style={{ position: "sticky", top: "180px" }}
          >
            {image ? (
              <img src={image} alt={name} />
            ) : (
              <img
                src="/wp-content/themes/nouria/assets/images/content/no-image-available.png"
                alt={name}
              />
            )}
          </figure>
        </div>
        <div className="col-lg-8">
          <h2 className="mb-0">{name}</h2>
          <p className="mt-1 fs-md">{description}</p>
          {flags?.soldOut && (
            <div className="alert alert-warning fs-sm mt-1">
              Sorry, {name} is sold out
            </div>
          )}
          {flags?.tobacco && (
            <div className="alert alert-warning fs-sm mt-1">
              You must show ID when picking up tobacco products
            </div>
          )}
          {flags?.alcohol && (
            <div className="alert alert-warning fs-sm mt-1">
              You must show ID when picking up alcohol products
            </div>
          )}
          <hr className="my-3" />

          {optionGroups && (
            <>
              {prices.length > 1 && (
                <>
                  {prices.map((sizeOption: any, sizeOptionIdx: number) => (
                    <div
                      key={`size-${sizeOptionIdx}`}
                      className={"form-check mb-1"}
                    >
                      <input
                        id={`size-${sizeOptionIdx}`}
                        className="form-check-input"
                        type="checkbox"
                        value={sizeOption.name}
                        checked={size === sizeOption.name}
                        onChange={(e) => setSize(e.currentTarget.value)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`size-${sizeOptionIdx}`}
                      >
                        {sizeOption.name} (${sizeOption.price})
                      </label>
                    </div>
                  ))}
                </>
              )}
              {optionGroups.map((group: any, optionGroupIdx: number) => (
                <React.Fragment key={`og-${optionGroupIdx}`}>
                  <div>
                    <div className="mb-1">
                      <h5 className="fs-sm text-uppercase">{group.name}</h5>

                      {group.quantities && group.max > 0 && (
                        <>
                          &nbsp;{groupTotalQuantityMap[group.name]}/{group.max}
                        </>
                      )}
                      {group.min > 0 && <>&nbsp; ({group.min} required)</>}
                    </div>
                    <div className="row row-cols-sm-2 row-cols-md-3 g-gutter-half">
                      {group.options.map((option: any, optionIdx: number) => (
                        <div
                          key={`og-${optionGroupIdx}-o-${optionIdx}`}
                          className={
                            !group.quantities ? "form-check mb-1" : "mb-1"
                          }
                        >
                          <div className="menu-card">
                            {option.image && option.image}
                            <div className="hstack justify-content-between flex-wrap row-gap-1 column-gap-2 w-100">
                              {group.quantities ? (
                                <span>
                                  <ValueAdjuster
                                    value={getOptionGroupOption(
                                      group.name,
                                      option.name
                                    )}
                                    onChange={(newValue) => {
                                      updateOptionGroupOption(
                                        group.name,
                                        option.name,
                                        newValue
                                      );
                                    }}
                                  />
                                  <span className="mx-1"></span>
                                  <label>
                                    {option.name}
                                    {!!option.price && option.price !== 0 && (
                                      <>&nbsp;(${option.price})</>
                                    )}
                                    {!!option.prices &&
                                      !!size &&
                                      option.prices[size] && (
                                        <>&nbsp;(${option.prices[size]})</>
                                      )}
                                  </label>
                                </span>
                              ) : (
                                <div className="form-check mb-0">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={1}
                                    id={`og-${optionGroupIdx}-o-${optionIdx}`}
                                    checked={
                                      getOptionGroupOption(
                                        group.name,
                                        option.name
                                      ) > 0
                                    }
                                    onChange={() =>
                                      updateOptionGroupOption(
                                        group.name,
                                        option.name,
                                        getOptionGroupOption(
                                          group.name,
                                          option.name
                                        ) === 0
                                          ? 1
                                          : 0
                                      )
                                    }
                                  />
                                  <label
                                    className="form-check-label fs-sm"
                                    htmlFor={`og-${optionGroupIdx}-o-${optionIdx}`}
                                  >
                                    {option.name}
                                  </label>
                                </div>
                              )}
                              <div className="fs-sm">
                                {!!option.price && option.price !== 0 && (
                                  <>&nbsp;(${option.price})</>
                                )}
                                {!!option.prices &&
                                  !!size &&
                                  option.prices[size] && (
                                    <>&nbsp;(${option.prices[size]})</>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="my-3" />
                </React.Fragment>
              ))}
            </>
          )}
          {restaurant.allow_notes && (
            <>
              <h5 className="fs-sm text-uppercase">
                {restaurant.notes_label ??
                  "Please Enter Any Special Instructions"}
              </h5>
              <textarea
                className="form-control"
                rows={3}
                maxLength={restaurant.notes_limit ?? 200}
                onChange={(e) => setNotes(e.currentTarget.value)}
                value={notes}
              ></textarea>
              <hr className="my-3" />
            </>
          )}
          <div className="hstack flex-wrap row-gap-2 column-gap-4">
            <div>
              {fields.quantity !== false && (
                <ValueAdjuster
                  min={1}
                  value={quantity}
                  onChange={(newValue) => {
                    setQuantity(newValue);
                  }}
                />
              )}
            </div>
            <div className="me-auto">
              {fields.price !== false && (
                <h2 className="mb-0">${totalPrice}</h2>
              )}
            </div>
            {!!action && (
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!isValid}
                  onClick={async () => {
                    if (isValid) {
                      setProcessing(true);
                      await action.handler(
                        size ?? "",
                        quantity,
                        options,
                        notes
                      );
                      setProcessing(false);
                    }
                  }}
                >
                  {flags?.soldOut ? <>Sold Out</> : <>{action.name}</>}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuItemConfigurator;
