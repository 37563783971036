import ContextAlert from "components/ContextAlert";
import ExtraFields from "components/checkout/ExtraFields";
import Header from "components/checkout/Header";
import OrderBasics from "components/checkout/OrderBasics";
import OrderItemsSummary from "components/checkout/OrderItemsSummary";
import OrderSummary from "components/checkout/OrderSummary";
import OrderWarnings from "components/checkout/OrderWarnings";
import CheckoutStepPayment from "components/checkout/Payment";
import PaymentTypeSelector from "components/checkout/PaymentTypeSelector";
import Progress from "components/checkout/Progress";
import TipField from "components/checkout/TipField";
import ArrorRightIcon from "components/icons/ArrowRight";
import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import { gaItemEvent, itemsArray, itemsTotal } from "integrations/ga4";
import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { formatPhoneNumber } from "utils/string";

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import HistoryOrder from "./HistoryOrder";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/checkout/:orderId" element={<Checkout />} />
      <Route path="/history/:orderId" element={<HistoryOrder />} />
    </Route>
  ),
  {
    basename: "/",
  }
);

const CheckoutPage = () => {
  return <RouterProvider router={router} />;
};

function Checkout() {
  const {
    restaurant,
    restaurantBaseUrl,
    order,
    hasBlockingErrors,
    gaResturantType,
  } = useRestaurantContext();

  const {
    data,
    checkoutStep,
    checkoutContinue,
    checkoutBack,
    isDirty,
    isValid,
  } = useCheckoutContext();

  const [gaReported, setGaReported] = useState(false);

  useEffect(() => {
    if (!gaReported && restaurant && order && checkoutStep === 0 && data) {
      const items = itemsArray(restaurant, order, gaResturantType);
      const total = itemsTotal(items);
      // let tax = order.tax ?? 0;
      // tax = order.active_taxes.reduce((accumulator: number, tax: any) => {
      //   return tax.type === "tax" ? accumulator + parseFloat(tax.amount) : 0;
      // }, tax);
      gaItemEvent({
        event: "view_cart",
        menu_type: gaResturantType,
        currency: "USD",
        store_location: restaurant?.name ?? "",
        value: total,
        // tax: tax,
        // shipping: order.delivery_fee,
        // tip: data && data.tip ? data.tip : undefined,
        items,
      });
      setGaReported(true);
    }
  }, [restaurant, order, gaResturantType, gaReported, checkoutStep, data]);

  const canContinueCheckout = useMemo(() => {
    return (
      restaurant &&
      restaurant.accepting_orders &&
      !isDirty &&
      isValid &&
      !hasBlockingErrors()
    );
  }, [hasBlockingErrors, isDirty, isValid, restaurant]);

  if (!restaurant || !order || !data) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Header
        cta={
          restaurantBaseUrl
            ? { label: "Back To Menu", url: restaurantBaseUrl }
            : undefined
        }
      />
      <Progress
        title={checkoutStep === 0 ? "Your Cart" : "Checkout"}
        steps={[
          { name: "Your Cart", active: checkoutStep === 0 },
          { name: "Payment Info", active: checkoutStep === 1 },
        ]}
        onStepChange={async (nextStep) => {
          const currentStep = checkoutStep + 1; // checkoutStep is a index

          // we can move one step forward
          if (nextStep === currentStep + 1) {
            // we can only move if we are allowed to continue
            if (canContinueCheckout) {
              await checkoutContinue();
            }
          }

          // we can also move any amount of steps backward
          if (nextStep < currentStep) {
            checkoutBack(currentStep - nextStep);
          }
        }}
      />
      <section className="py-6 py-md-8 overflow-hidden">
        <div className="container">
          <div className="row g-lg-10 gy-4">
            <div className="col-lg-7">
              <ContextAlert />
              {restaurant?.max_amount &&
              order.subtotal > restaurant.max_amount ? (
                <>
                  <h5 className="mb-1">Checkout</h5>
                  <div className="alert alert-info">
                    This order is too large over the website. Please call us at{" "}
                    <a href={`tel:${restaurant.phone}`}>
                      {formatPhoneNumber(restaurant.phone)}
                    </a>{" "}
                    to place this order.
                  </div>
                  <OrderItemsSummary />
                </>
              ) : (
                checkoutStep === 0 && (
                  <>
                    <OrderBasics checkout={true} />
                    <PaymentTypeSelector />
                    <TipField />
                    <ExtraFields />
                    <OrderItemsSummary />
                    <OrderWarnings restaurant={restaurant} order={order} />
                    <div className="text-end">
                      <button
                        className="btn btn-primary"
                        disabled={!canContinueCheckout}
                        onClick={async () => await checkoutContinue()}
                      >
                        Continue To Checkout
                        <ArrorRightIcon />
                      </button>
                    </div>
                  </>
                )
              )}
              {checkoutStep === 1 && <CheckoutStepPayment />}
            </div>
            <div className="col-lg-5 pt-4 pt-lg-0 position-relative">
              <div className="block-background block-background-sidebar bg-body-secondary"></div>
              <div className="bg-light rounded px-2 py-2">
                <OrderSummary />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const LoadingSkeleton: React.FC = () => {
  return (
    <>
      <Header loading={true} />
      <Progress
        title={"Your Cart"}
        steps={[
          { name: "Your Cart", active: false },
          { name: "Payment Info", active: false },
        ]}
      />
      <section className="py-6 py-md-8 overflow-hidden">
        <div className="container">
          <div className="row g-lg-10 gy-4">
            <div className="col-lg-7">
              <Skeleton count={4} height={150} />
            </div>
            <div className="col-lg-5 pt-4 pt-lg-0 position-relative">
              <div className="block-background block-background-sidebar bg-body-secondary"></div>
              <div className="bg-light rounded px-2 py-2">
                <Skeleton count={4} height={40} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckoutPage;
