/* eslint-disable jsx-a11y/anchor-is-valid */
import ArrorRightIcon from "components/icons/ArrowRight";
import ClosedIcon from "components/icons/Closed";
import { Location, useLocationsContext } from "contexts/locations-context";
import React, { useEffect, useRef } from "react";

const LocationSearchResults = (props: { locations: Location[] }) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const { selectedLocation: selected } = useLocationsContext();
  useEffect(() => {
    if (scrollRef.current && selected) {
      const selectElement = window.document.getElementById(
        `location-${selected.id}`
      );
      if (selectElement) {
        scrollRef.current.scrollTop = selectElement.offsetTop;
      }
    }
  }, [selected]);
  const [showAll, setShowAll] = React.useState(false);
  return (
    <>
      <div className="hstack justify-content-between flex-wrap column-gap-2 row-gap-1 mt-2">
        <p className="mb-0">
          <strong className="text-text-hard">{props.locations.length}</strong>{" "}
          stores found
        </p>
        <a
          className="fs-sm"
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#filterModal"
        >
          <FilterIcon /> Filter Results
        </a>
      </div>
      <hr className="my-3" />
      <div
        className={
          "store-locator-locations" +
          (showAll ? " store-locator-locations--show-more" : "")
        }
      >
        <div ref={scrollRef}>
          {props.locations.map((location: any) => (
            <LocationCard key={"loc_card" + location.id} location={location} />
          ))}
        </div>

        <a
          className="btn btn-secondary my-1 w-100 store-locator-show-more"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowAll(true);
          }}
        >
          Show More
        </a>
      </div>
    </>
  );
};

export function LocationCard(props: { location: any }) {
  const {
    selectedLocation: selected,
    hoveredLocation: hovered,
    setHoveredLocation: hover,
    setSelectedLocation: select,
  } = useLocationsContext();
  return (
    <div>
      <div
        id={`location-${props.location.id}`}
        className={
          "location-card" +
          (selected?.id === props.location.id ||
          hovered?.id === props.location.id
            ? " location-card-higlighted"
            : "")
        }
        onMouseOver={() => hover(props.location)}
        onMouseOut={() => hover(null)}
        onClick={() => select(props.location)}
      >
        <div className="location-card-content">
          <h6 className="location-card-title">
            <PinIcon
              fill={
                selected?.id !== props.location.id &&
                hovered?.id !== props.location.id
                  ? "#ccc"
                  : undefined
              }
            />
            <a href={props.location.link}>{props.location.title}</a>
          </h6>
          {props.location.opening_status === "temporarily_closed" && (
            <span className="location-card-notification">
              <ClosedIcon />
              Temporarily closed
            </span>
          )}
          {props.location.address && (
            <p className="location-card-text">
              {props.location.address_formatted}
              {props.location.phone !== "" && (
                <>
                  <br />
                  <a href={`tel:${props.location.phone}`}>
                    {props.location.phone.replace("+1", "")}
                  </a>
                </>
              )}
            </p>
          )}
          {Array.isArray(props.location.filters) ? (
            <div className="hstack gap-2 pb-2">
              {props.location.filters
                .filter((filter: string) => {
                  return (
                    filter === "nourias-kitchen" ||
                    filter === "cafe-nouria" ||
                    filter === "whatley-diner" ||
                    filter === "nourias-kitchen-amatos" ||
                    filter === "nourias-kitchen-krispy-krunchy-chicken"
                  );
                })
                .map((filter: any, index: number) => (
                  <img
                    key={`filter-${index}`}
                    src={
                      filter === "nourias-kitchen"
                        ? "/wp-content/themes/nouria/assets/images/content/stores/nouria-kitchen.svg"
                        : filter === "nourias-kitchen-amatos"
                        ? "/wp-content/themes/nouria/assets/images/content/stores/nouria-kitchen-amatos.svg"
                        : filter === "nourias-kitchen-krispy-krunchy-chicken"
                        ? "/wp-content/themes/nouria/assets/images/content/stores/nouria-kitchen-krispy-krunchy-chicken.svg"
                        : filter === "cafe-nouria"
                        ? "/wp-content/themes/nouria/assets/images/content/stores/cafe-nouria.svg"
                        : filter === "whatley-diner"
                        ? "/wp-content/themes/nouria/assets/images/content/stores/whatley.svg"
                        : ""
                    }
                    alt={
                      filter.substring(0, 1).toUpperCase() +
                      filter.substring(1).replaceAll("-", " ")
                    }
                    width="30"
                  />
                ))}
            </div>
          ) : null}
          <div className="hstack column-gap-2 row-gap-1 flex-wrap">
            <a className="btn btn-link btn-sm" href={props.location.link}>
              More Info <ArrorRightIcon />
            </a>
            {props.location.online_order_link && props.location.can_order && (
              <a
                className="btn btn-link btn-sm"
                href={props.location.online_order_link}
              >
                Order Online <ArrorRightIcon />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

/**
 * Create a svg filter icon
 * @returns React.FC
 */
export const FilterIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4375 4.75H14.3437M7.4375 4.75C7.4375 5.03179 7.32556 5.30204 7.1263 5.5013C6.92704 5.70056 6.65679 5.8125 6.375 5.8125C6.09321 5.8125 5.82296 5.70056 5.6237 5.5013C5.42444 5.30204 5.3125 5.03179 5.3125 4.75M7.4375 4.75C7.4375 4.46821 7.32556 4.19796 7.1263 3.9987C6.92704 3.79944 6.65679 3.6875 6.375 3.6875C6.09321 3.6875 5.82296 3.79944 5.6237 3.9987C5.42444 4.19796 5.3125 4.46821 5.3125 4.75M5.3125 4.75H2.65625M7.4375 13.25H14.3437M7.4375 13.25C7.4375 13.5318 7.32556 13.802 7.1263 14.0013C6.92704 14.2006 6.65679 14.3125 6.375 14.3125C6.09321 14.3125 5.82296 14.2006 5.6237 14.0013C5.42444 13.802 5.3125 13.5318 5.3125 13.25M7.4375 13.25C7.4375 12.9682 7.32556 12.698 7.1263 12.4987C6.92704 12.2994 6.65679 12.1875 6.375 12.1875C6.09321 12.1875 5.82296 12.2994 5.6237 12.4987C5.42444 12.698 5.3125 12.9682 5.3125 13.25M5.3125 13.25H2.65625M11.6875 9H14.3437M11.6875 9C11.6875 9.28179 11.5756 9.55204 11.3763 9.7513C11.177 9.95056 10.9068 10.0625 10.625 10.0625C10.3432 10.0625 10.073 9.95056 9.8737 9.7513C9.67444 9.55204 9.5625 9.28179 9.5625 9M11.6875 9C11.6875 8.71821 11.5756 8.44796 11.3763 8.2487C11.177 8.04944 10.9068 7.9375 10.625 7.9375C10.3432 7.9375 10.073 8.04944 9.8737 8.2487C9.67444 8.44796 9.5625 8.71821 9.5625 9M9.5625 9H2.65625"
        stroke="#7EAE2D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
/**
 * Create a svg pin icon
 * @returns React.FC
 */
export const PinIcon = ({ fill }: { fill?: string }) => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.05917 19.9197L8.12625 19.958L8.15308 19.9734C8.25949 20.0309 8.37855 20.061 8.49952 20.061C8.62049 20.061 8.73955 20.0309 8.84596 19.9734L8.87279 19.959L8.94083 19.9197C9.31565 19.6975 9.68139 19.4602 10.0372 19.2086C10.9582 18.5584 11.8187 17.8264 12.6084 17.0217C14.4714 15.1146 16.4062 12.2492 16.4062 8.5625C16.4062 6.46563 15.5733 4.45465 14.0906 2.97194C12.6079 1.48923 10.5969 0.65625 8.5 0.65625C6.40313 0.65625 4.39215 1.48923 2.90944 2.97194C1.42673 4.45465 0.59375 6.46563 0.59375 8.5625C0.59375 12.2483 2.52958 15.1146 4.39162 17.0217C5.18095 17.8264 6.04118 18.5583 6.96188 19.2086C7.31796 19.4603 7.68402 19.6975 8.05917 19.9197ZM8.5 11.4375C9.2625 11.4375 9.99376 11.1346 10.5329 10.5954C11.0721 10.0563 11.375 9.325 11.375 8.5625C11.375 7.8 11.0721 7.06873 10.5329 6.52957C9.99376 5.9904 9.2625 5.6875 8.5 5.6875C7.7375 5.6875 7.00623 5.9904 6.46707 6.52957C5.9279 7.06873 5.625 7.8 5.625 8.5625C5.625 9.325 5.9279 10.0563 6.46707 10.5954C7.00623 11.1346 7.7375 11.4375 8.5 11.4375Z"
        fill={fill ?? "#95C93D"}
      />
    </svg>
  );
};

export default LocationSearchResults;
