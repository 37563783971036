const AuthMenuIcon: React.FC = () => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33325 4.29134C9.33325 5.58001 8.28858 6.62467 6.99992 6.62467C5.71125 6.62467 4.66659 5.58001 4.66659 4.29134C4.66659 3.00268 5.71125 1.95801 6.99992 1.95801C8.28858 1.95801 9.33325 3.00268 9.33325 4.29134Z"
        stroke="white"
        strokeLinejoin="round"
      />
      <path
        d="M6.99992 7.79134C4.39268 7.79134 2.56282 9.84164 2.33325 12.458H11.6666C11.437 9.84164 9.60716 7.79134 6.99992 7.79134Z"
        stroke="white"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const rawAuthMenuIcon = `<svg
width="14"
height="15"
viewBox="0 0 14 15"
fill="none"
xmlns="http://www.w3.org/2000/svg"
>
<path
  d="M9.33325 4.29134C9.33325 5.58001 8.28858 6.62467 6.99992 6.62467C5.71125 6.62467 4.66659 5.58001 4.66659 4.29134C4.66659 3.00268 5.71125 1.95801 6.99992 1.95801C8.28858 1.95801 9.33325 3.00268 9.33325 4.29134Z"
  stroke="white"
  stroke-linejoin="round"
/>
<path
  d="M6.99992 7.79134C4.39268 7.79134 2.56282 9.84164 2.33325 12.458H11.6666C11.437 9.84164 9.60716 7.79134 6.99992 7.79134Z"
  stroke="white"
  stroke-linejoin="round"
/>
</svg>`;
export default AuthMenuIcon;
