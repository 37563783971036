const ArrorLeftIcon = () => (
  <svg
    className="svg-icon me-1"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="none"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 6.333H1.667m0 0L6.334 11M1.667 6.333l4.667-4.666"
    />
  </svg>
);

export default ArrorLeftIcon;
