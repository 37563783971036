const ModernHomeIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.3">
      <path
        d="M29.0667 29.0659H2.8C2.53333 29.0659 2.26667 28.7993 2.26667 28.5326V15.7326C2.26667 15.466 2.4 15.1993 2.66667 15.1993L28.9333 8.93262C29.0667 8.93262 29.2 8.93262 29.4667 9.06595C29.6 9.19928 29.7333 9.33262 29.7333 9.46595V28.5326C29.6 28.7993 29.3333 29.0659 29.0667 29.0659ZM3.33333 27.8659H28.4V9.99928L3.33333 15.9993V27.8659Z"
        fill="black"
      />
      <path
        d="M2.8 12.6662C2.53333 12.6662 2.26667 12.5329 2.26667 12.2662C2.13333 11.8662 2.4 11.5995 2.66667 11.4662L28.9333 5.19953C29.2 5.06619 29.4667 5.33286 29.6 5.59953C29.7333 5.86619 29.4667 6.26619 29.2 6.26619L2.93333 12.5329C2.93333 12.6662 2.8 12.6662 2.8 12.6662Z"
        fill="black"
      />
      <path
        d="M4.53333 12.1331C4.4 12.1331 4.26666 12.1331 4.13333 11.9997C4 11.8664 3.86666 11.7331 3.86666 11.5997V3.59974C3.86666 3.33307 4.13333 3.06641 4.4 3.06641H9.46666C9.73333 3.06641 10 3.33307 10 3.59974V10.3997C10 10.6664 9.86666 10.9331 9.6 10.9331L4.53333 12.1331ZM5.06666 4.13307V10.7997L8.93333 9.86641V4.13307H5.06666Z"
        fill="black"
      />
      <path
        d="M18.4 29.0669H13.4667C13.2 29.0669 12.9333 28.8002 12.9333 28.5335V19.7335C12.9333 19.3335 13.2 19.2002 13.4667 19.2002H18.4C18.6667 19.2002 18.9333 19.4669 18.9333 19.7335V28.5335C18.9333 28.8002 18.6667 29.0669 18.4 29.0669ZM14 27.8669H17.7333V20.1335H14V27.8669Z"
        fill="black"
      />
      <path
        d="M10.2667 24.2664H6.13333C5.86667 24.2664 5.6 23.9997 5.6 23.7331V19.5997C5.6 19.1997 5.86667 19.0664 6.13333 19.0664H10.2667C10.5333 19.0664 10.8 19.3331 10.8 19.5997V23.7331C10.9333 23.9997 10.6667 24.2664 10.2667 24.2664ZM6.66667 23.1997H9.6V20.1331H6.66667V23.1997Z"
        fill="black"
      />
      <path
        d="M25.7333 24.2664H21.6C21.3333 24.2664 21.0667 23.9997 21.0667 23.7331V19.5997C21.0667 19.1997 21.3333 19.0664 21.6 19.0664H25.7333C26 19.0664 26.2667 19.3331 26.2667 19.5997V23.7331C26.2667 23.9997 26 24.2664 25.7333 24.2664ZM22.1333 23.1997H25.0667V20.1331H22.1333V23.1997Z"
        fill="black"
      />
    </g>
  </svg>
);
export default ModernHomeIcon;