import { updateUserProfilePTX } from "api/endpoints";
import FormErrorDisplay from "components/form/Error";
import InputField from "components/form/InputField";
import { useAppContext } from "contexts/app-context";
import { useAuthContext } from "contexts/auth-context";
import { BrazeEvent, brazeEvent } from "integrations/braze";
import { useCallback, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { TAccountUpdatePTX } from "types";

type ProfilePersonalInfoFormInputs = {
  first_name: string;
  last_name: string;
  email: string;
  new_password?: string;
  confirm_password?: string;
};

const ProfilePersonalInfoForm: React.FC = () => {
  const { setNetworkError, profile, cardNumber, accessToken } = useAppContext();
  const { clearErrors, checkForPaytronixError } = useAuthContext();
  const [toast, setToast] = useState<{ type: string; message: string } | null>(
    null
  );
  const methods = useForm<ProfilePersonalInfoFormInputs>({
    mode: "onChange",
  });

  // const updateProfile = useCallback(
  //   async (change: {
  //     first_name?: string;
  //     last_name?: string;
  //     email?: string;
  //   }) => {
  //     try {
  //       clearErrors("actions.auth.update_profile");
  //       const grant = await attemptGrant();
  //       if (!grant || !grant.authorizationGrant || grant.error !== undefined) {
  //         handleApiError(
  //           "Could not get grant token",
  //           "actions.auth.update_profile"
  //         );
  //         return false;
  //       }
  //       const authorizationToken = grant.authorizationGrant;
  //       const profile = await updateUserProfile(
  //         {
  //           ...change,
  //           access_token: authorizationToken,
  //         },
  //         cardNumber
  //       ).fetch();

  //       return checkForPaytronixError(profile, "actions.auth.update_profile");
  //     } catch (error: any) {
  //       setNetworkError(error.message);
  //       if (error.response.data)
  //         checkForPaytronixError(
  //           error.response.data,
  //           "actions.auth.update_profile"
  //         );
  //       return false;
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );
  /**
   * Update the users profile
   */
  const updateAccountPTX = useCallback(
    async (change: {
      first_name: string;
      last_name: string;
      email: string;
      new_password?: string;
      confirm_password?: string;
    }) => {
      try {
        const {
          first_name,
          last_name,
          email,
          new_password: password,
          confirm_password,
        } = change;
        clearErrors("actions.auth.update_password");
        if (!profile) return false;
        const submit: TAccountUpdatePTX = {
          account_id: profile.id,
          email,
          first_name,
          last_name,
          username: profile?.email,
          access_token: accessToken,
        };
        if (password && confirm_password) {
          submit["password"] = password;
          submit["confirm_password"] = confirm_password;
        }
        const update = await updateUserProfilePTX(submit, cardNumber).fetch();
        brazeEvent(BrazeEvent.Online_Order_Update_Account, {
          first_name: submit.first_name ?? undefined,
          last_name: submit.last_name ?? undefined,
          email: submit.email ?? undefined,
        });
        return checkForPaytronixError(update, "actions.auth.update_password");
      } catch (error: any) {
        setNetworkError(error.message, error);
        if (error.response.data)
          checkForPaytronixError(
            error.response.data,
            "actions.auth.update_profile"
          );
        return false;
      }
    },
    [
      accessToken,
      cardNumber,
      checkForPaytronixError,
      clearErrors,
      profile,
      setNetworkError,
    ]
  );

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<ProfilePersonalInfoFormInputs> = async (
    data
  ) => {
    if (!isDirty) return;
    const submit = { ...data, username: profile?.email };
    let success = await updateAccountPTX(submit);

    if (success)
      setToast({ type: "success", message: "Profile updated successfully" });
  };

  return (
    <div
      className="tab-pane active"
      id="account-info"
      role="tabpanel"
      aria-labelledby="account-info-tab"
      tabIndex={0}
    >
      {toast && (
        <div className={`alert alert-${toast.type}`} role="alert">
          {toast.message}
        </div>
      )}
      <FormErrorDisplay error="actions.auth.update_profile" />
      <FormProvider {...methods}>
        <form className="row g-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-6">
            <InputField
              name="first_name"
              label="First Name"
              placeholder="First Name..."
              defaultValue={profile?.first_name}
              aria-invalid={errors.first_name ? "true" : "false"}
              validation={{
                required: "First Name is required",
                maxLength: {
                  value: 30,
                  message: "First Name must be less than 30 characters",
                },
              }}
            />
          </div>
          <div className="col-md-6">
            <InputField
              name="last_name"
              label="Last Name"
              placeholder="Last Name..."
              defaultValue={profile?.last_name}
              aria-invalid={errors.last_name ? "true" : "false"}
              validation={{
                required: "Last Name is required",
                maxLength: {
                  value: 30,
                  message: "Last Name must be less than 30 characters",
                },
              }}
            />
          </div>
          <div className="col-12">
            <InputField
              name="email"
              label="Email"
              placeholder="Email..."
              defaultValue={profile?.email}
              aria-invalid={errors.email ? "true" : "false"}
              validation={{
                required: "Email is required",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "That doesn't look like an email",
                },
                maxLength: {
                  value: 100,
                  message: "Email must be less than 100 characters",
                },
              }}
            />
            {errors.email && <span>This field is required</span>}
          </div>
          <div className="col-md-12">
            <hr className="mb-1" />
          </div>
          <div className="col-12">
            <p className="mb-0 fs-md">
              If you want to update your password, enter your current password
              and then your new password below.
            </p>
          </div>
          <div className="col-12">
            <InputField
              name="new_password"
              label="New Password"
              placeholder="New Password..."
              type="password"
              aria-invalid={errors.new_password ? "true" : "false"}
              validation={{
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              }}
            />
          </div>
          <div className="col-12">
            <InputField
              name="confirm_password"
              label="Repeat New Password"
              placeholder="Repeat New Password..."
              type="password"
              aria-invalid={errors.confirm_password ? "true" : "false"}
              validation={{
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
                validate: (value: string) => {
                  return (
                    value === methods.getValues("new_password") ||
                    "Passwords do not match"
                  );
                },
              }}
            />
          </div>
          <div className="col-12 mt-3">
            <div className="hstack gap-2">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-primary btn-sm ms-auto"
              >
                {isSubmitting ? "Updating..." : "Update Profile"}
              </button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default ProfilePersonalInfoForm;
