export const reduceOrderItemOptionsForUpdate = (options: any) => {
  return options.reduce((acc: any, item: any) => {
    const { group_name } = item;
    acc[group_name] = acc[group_name] || [];
    acc[group_name].push({
      name: item.option_name,
      quantity: item.quantity,
    });
    return acc;
  }, {} as { [group_name: string]: { option_name: string; quantity: number }[] });
};
