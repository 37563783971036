import * as Sentry from "@sentry/browser";
import { paytronixGrant, paytronixRefresh } from "api/endpoints";
import config from "config";
import { bindMenu } from "index";
import ConnectionError from "pages/ConnectionError";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ToastContainer, ToastPosition, toast } from "react-toastify";
import { TAccountInfo, TAuthCookie, TAuthGrant, TNullableString } from "types";
import { COOKIE_NAME_AUTH, LOCAL_STORAGE_AUTH } from "../constants";

import "react-toastify/dist/ReactToastify.css";

interface IAppContext {
  attemptRefresh: () => Promise<string | false>;
  attemptGrant: (
    username?: string,
    access_token?: string
  ) => Promise<TAuthGrant | false>;
  networkError: TNullableString;
  setNetworkError: (error: string | null, exception?: any) => void;
  clearNetworkError: () => void;
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  username: string;
  setUsername: (username: string) => void;
  profile: TAccountInfo | null;
  setProfile: (profile: TAccountInfo | null) => void;
  profileName: (profile?: TAccountInfo) => string;
  resetAuthState: (destroy?: boolean) => void;
  setAuthCookie: (auth: TAuthCookie) => void;
  accessToken: string;
  refreshToken: string;
  cardNumber: string;
  authExpiresAt: number;
  getCachedProfile: () => Promise<TAccountInfo | false>;
  cacheProfile: (profile: TAccountInfo) => boolean;
  connectionError: boolean | null;
  setConnectionError: (error: boolean | null) => void;
  notifySuccess: (message: string) => void;
  notifyError: (message: string) => void;
  notifyInfo: (message: string) => void;
  notifyWarning: (message: string, position?: ToastPosition) => void;
}

export const AppContext = createContext<IAppContext | undefined>(undefined);

interface IAppContextProviderProps {
  options?: {
    showToastNotifications?: boolean;
  };
  children: React.ReactNode;
}

export const AppContextProvider: React.FC<IAppContextProviderProps> = ({
  options,
  children,
}) => {
  const [networkError, setNetworkErrorState] = useState<TNullableString>(null);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<string>("");
  const [authExpiresAt, setAuthExpiresAt] = useState<number>(0);
  const [cardNumber, setCardNumber] = useState<string>("");
  const [profile, setProfile] = useState<TAccountInfo | null>(null);
  const [connectionError, setConnectionError] = useState<boolean | null>(null);

  /**
   * Handle network errors and report to sentry
   * @param error
   * @param networkException
   */
  const setNetworkError = (error: string | null, networkException?: any) => {
    if (networkException) {
      Sentry.captureException(networkException);
      if (config.DEBUG && networkException.getMessage)
        error = `${error} ${networkException.getMessage()}`;
    }
    setNetworkErrorState(error);
  };

  useEffect(() => {
    // Get access token from cookie
    const cookieStrValue = window.localStorage.getItem(COOKIE_NAME_AUTH);
    if (cookieStrValue) checkAuthCookie(cookieStrValue);
    const checkCookie = () => {
      if (document.visibilityState === "visible") {
        if (cookieStrValue) checkAuthCookie(cookieStrValue);
      }
    };
    // Check the cookie when the browser gets focus
    window.addEventListener("visibilitychange", checkCookie);
    return () => window.removeEventListener("visibilitychange", checkCookie);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    bindMenu();
  }, [authenticated]);

  const profileName = (cached?: TAccountInfo) => {
    const useProfile = cached ? cached : profile;
    if (useProfile) {
      return `${useProfile.first_name} ${useProfile.last_name}`;
    }
    return "";
  };
  /**
   * Invalidate the user's authentication state
   */
  const resetAuthState = (destroy?: boolean) => {
    setAuthenticated(false);
    setUsername("");
    setAccessToken("");
    setRefreshToken("");
    setCardNumber("");
    setAuthExpiresAt(0);
    Sentry.captureMessage("Killing user session");
    if (destroy) {
      window.localStorage.removeItem(COOKIE_NAME_AUTH);
      window.localStorage.removeItem(LOCAL_STORAGE_AUTH);
    }
  };

  /**
   * For an auth object, set the access token, refresh token, and expiration date
   * and commit to a cookie
   * @param auth
   */
  const setAuthCookie = (auth: TAuthCookie) => {
    if (auth.expires_in) {
      auth.expires_at = new Date().getTime() + auth.expires_in * 1000;
    } else {
      auth.expires_at = new Date().getTime() + 1800;
    }
    setAccessToken(auth.access_token);
    setRefreshToken(auth.refresh_token);
    setAuthExpiresAt(auth.expires_at);
    setCardNumber(auth.printedCardNumber);
    setAuthenticated(true);
    setUsername(auth.username);
    
    window.localStorage.setItem(COOKIE_NAME_AUTH, JSON.stringify(auth));
  };

  /**
   * Parse the auth cookie and set the state
   * @param cookieStrValue
   * @returns boolean | undefined
   */
  const checkAuthCookie = async (
    cookieStrValue: string
  ): Promise<boolean | undefined> => {
    try {
      if (!cookieStrValue) {
        throw new Error("No cookie string value for auth");
      }
      const auth = JSON.parse(cookieStrValue) as TAuthCookie;
      if (auth.expires_at > new Date().getTime()) {
        setAuthenticated(true);
        setUsername(auth.username);
        setAccessToken(auth.access_token);
        setRefreshToken(auth.refresh_token);
        setAuthExpiresAt(auth.expires_at);
        setCardNumber(auth.printedCardNumber);
        return true;
      } else {
        // Attempt to refresh the token if it is expired
        const refreshed = await attemptRefresh(
          auth.username,
          auth.refresh_token
        );
        // If we can't refresh, reset the auth state
        if (!refreshed) {
          return false;
        }
        return true;
      }
    } catch (e) {
      Sentry.captureException(e);
      return undefined;
    }
  };

  /**
   * Attempt to refresh the token
   * @returns
   */
  const attemptRefresh = useCallback(
    async (requestTimeUser?: string, requestTimeToken?: string) => {
      try {
        //
        if (!requestTimeUser) requestTimeUser = username;
        if (!requestTimeToken) requestTimeToken = refreshToken;
        if (!requestTimeUser && !requestTimeToken) {
          Sentry.captureMessage("No username or refresh token to refresh");
          return false;
        }
        const refreshData = await paytronixRefresh(
          requestTimeUser,
          requestTimeToken
        ).fetch();
        if (refreshData.error !== undefined) {
          if (
            refreshData.data &&
            refreshData.data.error ===
              "Your session has expired. Please login again."
          ) {
            resetAuthState(true);
          }
          setNetworkError("Refresh Error", refreshData.error);
          return false;
        }
        const expires_at = new Date().getTime() + refreshData.expires_in * 1000;
        setAuthCookie({ ...refreshData, expires_at });
        return refreshData.access_token;
      } catch (error: any) {
        if (
          error.response.data &&
          error.response.data.error ===
            "Your session has expired. Please login again."
        ) {
          resetAuthState(true);
        }
        setNetworkError("Refresh Error", error);
        return false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [username, refreshToken]
  );

  /**
   * This will try its darnedist to get an authorization grant
   * Its going to attempt to do the grant. If it fails, it will attempt to refresh the token
   * and then will attempt to do the grant again
   * @returns
   */
  const attemptGrant = useCallback(
    async (providedUsername?: string, providedAccessToken?: string) => {
      try {
        const tryUsername = providedUsername ? providedUsername : username;
        const tryAccessToken = providedAccessToken
          ? providedAccessToken
          : accessToken;
        if (!tryUsername || !tryAccessToken) {
          return false;
        }
        let grant = await paytronixGrant(tryUsername, tryAccessToken).fetch();
        if (grant.error !== undefined) {
          // We can't get a grant.  Lets try to refresh
          const refresh = await attemptRefresh();
          if (refresh) {
            grant = await paytronixGrant(tryUsername, refresh).fetch();
          } else {
            return false;
          }
        }
        if (!grant || grant.error !== undefined) {
          setNetworkError("Grant Failed", grant.error);
          return false;
        }
        return grant;
      } catch (error: any) {
        setNetworkError(error.message, error);

        return false;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [attemptRefresh, accessToken, username]
  );

  /**
   * Clear the network error
   */
  const clearNetworkError = () => {
    setNetworkError(null);
  };

  /**
   * Cache the user's profile
   */
  const cacheProfile = useCallback((profile: TAccountInfo) => {
    setProfile(profile);
    window.localStorage.setItem(LOCAL_STORAGE_AUTH, JSON.stringify(profile));
    return true;
  }, []);

  /**
   * Get the user's profile from cache
   */
  const getCachedProfile = useCallback(async () => {
    const profile = window.localStorage.getItem(LOCAL_STORAGE_AUTH);
    if (profile) {
      setProfile(JSON.parse(profile));
      return JSON.parse(profile);
    }
    return false;
  }, []);

  const notifySuccess = useCallback((message: string) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000,
      pauseOnFocusLoss: false,
      hideProgressBar: true,
      icon: false,
      theme: "colored",
    });
  }, []);

  const notifyInfo = useCallback((message: string) => {
    toast.info(message, {
      position: "bottom-right",
      autoClose: 3000,
      pauseOnFocusLoss: false,
      hideProgressBar: true,
      icon: false,
      theme: "colored",
    });
  }, []);

  const notifyError = useCallback((message: string) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 2000,
      pauseOnFocusLoss: false,
      hideProgressBar: true,
      icon: false,
      theme: "colored",
    });
  }, []);

  const notifyWarning = useCallback(
    (message: string, position?: ToastPosition) => {
      toast.warn(message, {
        position: position ?? "bottom-right",
        autoClose: 2000,
        pauseOnFocusLoss: false,
        hideProgressBar: true,
        icon: false,
        theme: "colored",
      });
    },
    []
  );

  return (
    <AppContext.Provider
      value={{
        notifyInfo,
        attemptRefresh,
        attemptGrant,
        networkError,
        setNetworkError,
        clearNetworkError,
        authenticated,
        setAuthenticated,
        username,
        setUsername,
        resetAuthState,
        setAuthCookie,
        accessToken,
        refreshToken,
        cardNumber,
        authExpiresAt,
        profile,
        setProfile,
        profileName,
        getCachedProfile,
        cacheProfile,
        connectionError,
        setConnectionError,
        notifySuccess,
        notifyError,
        notifyWarning,
        // TODO: Render errors
      }}
    >
      {connectionError ? <ConnectionError /> : null}
      {children}
      {options?.showToastNotifications !== false && <ToastContainer />}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider.");
  }
  return context;
};
