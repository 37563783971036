const ArrorRightIcon = () => (
  <svg className="svg-icon ms-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 5.667h9.333M5.666 1l4.667 4.667-4.667 4.666"
    />
  </svg>
);
export default ArrorRightIcon;
