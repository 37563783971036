import { TRestaurantType } from "types";

export const getHtmlElementRestaurantAttributes = (element: HTMLElement) => {
  const id = element.getAttribute("data-restaurant-id");
  const url = element.getAttribute("data-basename");
  const types = [
    ...(elementHasrestaurantTypeAttribute(element, "nouria-kitchen")
      ? [TRestaurantType.NouriaKitchen]
      : []),
    ...(elementHasrestaurantTypeAttribute(element, "amatos")
      ? [TRestaurantType.Amatos]
      : []),
    ...(elementHasrestaurantTypeAttribute(element, "nouria-cafe")
      ? [TRestaurantType.NouriaCafe]
      : []),
    ...(elementHasrestaurantTypeAttribute(element, "krispy-krunchy")
      ? [TRestaurantType.KrispyKrunch]
      : []),
  ];

  return !!url && !!id ? { id, url, types } : undefined;
};

export const getHtmlElementOrderAttributes = (element: HTMLElement) => {
  const id = element.getAttribute("data-order-id");

  return !!id ? { id } : undefined;
};

const elementHasrestaurantTypeAttribute = (element: HTMLElement, type: string) =>
  element.getAttribute(`data-${type}`) === "true";
