import useSWR, { SWRResponse } from "swr";
import HttpClient, { HttpError } from "./http-client";
import { hash } from "utils/string";
// import { useNavigate } from "react-router-dom";
// import { useAppContext } from "contexts/app-context";
// import { useStore } from "store";
// import debug from "utils/debug";

interface Return<Data, Error>
  extends Pick<
    SWRResponse<Data, Error>,
    "isValidating" | "error" | "isLoading"
  > {
  data: Data | undefined;
  mutate: (
    data?: Data,
    shouldRevalidate?: boolean
  ) => Promise<Data | undefined>;
}

let errorTimeout: NodeJS.Timeout | undefined;

export default function useRequest<T>(
  request: HttpClient<T>,
  shouldFetch: boolean = true,
  revalidateIfStale: boolean = true,
  revalidateOnFocus: boolean = true,
  revalidateOnReconnect: boolean = true
): Return<T, HttpError> {
  // const navigate = useNavigate();
  // const store = useAppContext();

  if (request.config && request.fetch) {
    try {
      const key = shouldFetch
        ? request && hash(JSON.stringify(request.config))
        : null; // Null key for SWR conditional fetching

      const {
        data: response,
        error,
        isValidating,
        isLoading,
        mutate,
      } = useSWR<T, HttpError>(key, request.fetch, {
        suspense: false,
        revalidateIfStale,
        revalidateOnFocus,
        revalidateOnReconnect,
      });

      if (error?.message === "Network Error") {
        if (errorTimeout) {
          clearTimeout(errorTimeout);
        }

        errorTimeout = setTimeout(() => {
          // store.clearNetworkError();
        }, 1000);
      } else if (error) {
        // debug(error);
      }

      return {
        data: response,
        error,
        isValidating,
        isLoading,
        mutate,
      };
    } catch (error: unknown) {
      if (error instanceof HttpError && error.response) {
        switch (error.response.status) {
          case 401:
            // navigate("/401");
            break;
          case 404:
            // navigate("/404");
            break;
        }
        // debug(error.response);
      }
      throw error;
    }
  } else {
    throw new Error("Need a fully configure request param.");
  }
}
