import classNames from "classnames";
import InputField from "components/form/InputField";
import AmericanExpressIcon from "components/icons/AmericanExpress";
import ArrorLeftIcon from "components/icons/ArrowLeft";
import DiscoveryIcon from "components/icons/Discovery";
import MasterCardIcon from "components/icons/MasterCard";
import VisaIcon from "components/icons/Visa";
import { useAppContext } from "contexts/app-context";
import * as storage from "../../utils/storage";
import * as promise from "../../utils/promise";
import {
  IPaymentInformationMetadata,
  useCheckoutContext,
} from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { formatPhoneNumber } from "utils/string";
import NouriaGooglePayButton from "./GooglePayField";
import { useNavigate } from "react-router-dom";

interface ICheckoutPayment {}

type PaymentInputs = {
  fullName: string;
  phone: string;
  email: string;
  full_name: string;
  expiration: string;
  billing_address?: string;
  billing_city?: string;
  billing_state?: string;
  billing_zip?: string;
  billing_country?: string;
};

const CheckoutPayment: React.FC<ICheckoutPayment> = () => {
  const { getCachedProfile } = useAppContext();
  const navigate = useNavigate();
  const [loadingProfile, setLoadingProfile] = useState(true);
  const { registerError, clearErrors, restaurant, order } =
    useRestaurantContext();
  const {
    data: checkoutData,
    updateData: updateCheckoutData,
    totalAmount,
    checkoutContinue,
    checkoutBack,
  } = useCheckoutContext();
  const methods = useForm<PaymentInputs>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = methods;
  const [ready, setReady] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [creditCardError, setCreditCardError] = useState<string | null>("");
  const [CVVCardError, setCVVCardError] = useState<string | null>("");
  const [cardType, setCardType] = useState<string>("");
  //const acceptsGooglePay =
  //order?.checkout_behaviors?.allowed_payment_types?.includes("gp") ?? false;
  const acceptsGooglePay = false; // TODO: Waiting for Nouria to activate google pay account
  const isValid = useMemo(() => {
    if (!checkoutData) {
      return false;
    }

    const data = watch();

    let result = !!data.fullName && !!data.phone && !!data.email;

    if (checkoutData.paymentType === "cc") {
      result = result && !!data.full_name && !!data.expiration;
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutData, watch()]);

  const completeCheckout = useCallback(
    async (token: string) => {
    storage.clearRestaurantOrder(restaurant.id);
    promise
      .waitForCondition(() => !storage.getRestaurantOrder(restaurant.id))
      .then(() => {
        console.log(`/history/${order.id}?token=${token}`);
        navigate(`/history/${order.id}?token=${token}`);
      })
      .catch((_) => {
        navigate(`/history/${order.id}?token=${token}`);
      });
  }, [navigate, order, restaurant.id]);

  const onSubmit: SubmitHandler<PaymentInputs> = async ({
    full_name,
    expiration,
    fullName,
    phone,
    email,
  }) => {
    if (!checkoutData) {
      return;
    }

    window.scrollTo(0, 0);

    clearErrors("info.checkout.spreedly");
    setProcessing(true);
    updateCheckoutData({ fullName, phone, email });

    // If its a PIP payment, process the order
    if (checkoutData.paymentType === "pip") {
      const checkout = await checkoutContinue({
        token: null,
        personalInfo: {
          fullName: fullName ?? "",
          phone: phone ?? "",
          email: email ?? "",
        },
        type: "PaymentInformation",
      } as IPaymentInformationMetadata);
      if (!checkout) {
        setProcessing(false);
      }
      if (typeof checkout === "string") {
        return completeCheckout(checkout);
      }
    } else if (checkoutData.paymentType === "cc") {
      // If its a credit card payment, tokenize the card
      // And submit the order to paytronix
      const [month, year] = expiration.split("/");
      if (!month || !year) {
        registerError("Invalid expiration date", "info.checkout.spreedly");
        setProcessing(false);
        return;
      }
      Spreedly.tokenizeCreditCard({
        full_name,
        month,
        year,
      });
    }
  };

  const googlePayTokenHandler = async (
    paymentData: google.payments.api.PaymentData
  ) => {
    const token = paymentData.paymentMethodData.tokenizationData.token;
    // TODO: Implement mobile pay
    // Nouria needs to sign up for an account
    // const tokenizeMobile = await mobilePayCard({
    //   payment_data: token,
    //   provider: "google",
    //   is_test: false,
    //   billing_name: "Brad Mering",
    //   billing_address: "293 S Lark Ave",
    //   billing_city: "Louisville",
    //   billing_state: "CO",
    //   billing_country: "US",
    //   billing_zip: "80027",
    // }).fetch();
    if (token) {
      alert("Payment successful.  GP for Paytronix is not implemented yet");
      // TODO: Implement mobile pay with successCallback
    }
    return false;
  };

  useEffect(() => {
    getCachedProfile().then(() => {
      setLoadingProfile(false);
      // This form needs a delayed scroll
      setTimeout(() => window.scrollTo(0, 0), 10);
    });
  }, [getCachedProfile]);

  const cardTransform = (card: string) => {
    switch (card) {
      case "visa":
        return "Visa";
      case "master":
        return "MasterCard";
      case "american_express":
        return "American Express";
      case "discover":
        return "Discover";
      default:
        return card;
    }
  };

  useEffect(() => {
    if ((window.Spreedly as any).isLoaded() || ready || !checkoutData) {
      return;
    }

    if (checkoutData.paymentType !== "cc") {
      setReady(true);
      return;
    }

    window.Spreedly.init("TGhaOmppDgApYUbIxwwb46mrTEG", {
      // U6KPhWMNQVTlMCEVoR9wDlW3mWe
      numberEl: "spreedly-number",
      cvvEl: "spreedly-cvv",
    });

    Spreedly.on("validation", function (inputProperties) {
      let valid = true;

      if (inputProperties.validNumber !== true) {
        setCreditCardError("This card number is invalid");
        valid = false;
      }
      if (inputProperties.validCvv !== true) {
        setCVVCardError("This CVV is invalid");
        valid = false;
      }
      if (inputProperties.cardType) {
        const card = inputProperties.cardType;
        setCardType(card);
        if (restaurant.cards.indexOf(card) === -1) {
          setCreditCardError(`${cardTransform(card)} cards are not accepted`);
          valid = false;
        }
      }
      if (valid) {
        clearErrors("info.checkout.spreedly");
        setCreditCardError(null);
        setCVVCardError(null);
      }
    });

    Spreedly.on("fieldEvent", function (name, type, activeEl, inputProperties) {
      if (name === "number" || name === "cvv") {
        if (type === "blur") {
          Spreedly.validate();
        }
      }
    });

    window.Spreedly.on("ready", function () {
      window.Spreedly.setStyle("number", "font-size: 16px; width: 97%");
      window.Spreedly.setStyle("cvv", "font-size: 16px; width: 97%");
      setReady(true);
    });

    window.Spreedly.on("errors", (errors) => {
      setProcessing(false);
      if (errors.length > 0) {
        errors.forEach((err) => {
          registerError(err.message, "info.checkout.spreedly");
        });
      }
    });

    window.Spreedly.on("paymentMethod", async (token, paymentMethod) => {
      const data = methods.getValues();
      const success = await checkoutContinue({
        token: token,
        personalInfo: {
          fullName: data.fullName,
          phone: data.phone,
          email: data.email,
        },
        billingInfo: {
          address: data.billing_address ?? "",
          city: data.billing_city ?? "",
          state: data.billing_state ?? "",
          zip: data.billing_zip ?? "",
          country: data.billing_country ?? "",
        },
        type: "PaymentInformation",
      } as IPaymentInformationMetadata);
      if (!success) {
        setProcessing(false);
      }
      if (typeof success === "string") {
        completeCheckout(success);
      }
    });

    return;
  }, [
    ready,
    registerError,
    clearErrors,
    methods,
    checkoutData,
    checkoutContinue,
    restaurant,
    completeCheckout
  ]);

  if (!checkoutData) {
    return <></>;
  }

  return (
    <>
      <h5 className="mb-1">Contact Information</h5>

      <FormProvider {...methods}>
        <form
          id="payment-form"
          className="row g-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          {loadingProfile ? (
            <p>Loading profile...</p>
          ) : (
            <>
              <div className="col-6">
                <InputField
                  type="text"
                  placeholder="Full name"
                  label="Full Name"
                  name="fullName"
                  validation={{
                    required: "Full Name is required",
                    validate: (value: string) => {
                      //check if the name is at least 2 words
                      const words = value.split(" ");
                      if (words.length < 2 || words[1].length === 0) {
                        return "Please enter your full name";
                      }
                    },
                  }}
                  defaultValue={checkoutData.fullName}
                />
              </div>
              <div className="col-6">
                <InputField
                  type="tel"
                  placeholder="Phone"
                  name="phone"
                  validation={{
                    required: "Phone is required",
                  }}
                  label="Phone Number"
                  defaultValue={checkoutData.phone}
                  formatter={formatPhoneNumber}
                />
              </div>
              <div className="col-12">
                <InputField
                  type="text"
                  placeholder="Email"
                  label="Email"
                  name="email"
                  defaultValue={checkoutData.email}
                  validation={{
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "That doesn't look like an email",
                    },
                  }}
                />
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </>
          )}
          {restaurant.billing_address ||
          restaurant.billing_city ||
          restaurant.billing_zip ? (
            <>
              <h5 className="mb-1">Billing Address</h5>
              {restaurant.billing_address && (
                <div className="col-12">
                  <InputField
                    type="text"
                    placeholder="Address"
                    label="Address"
                    name="billing_address"
                    defaultValue={checkoutData.billingAddress}
                    validation={{
                      required: " Address is required",
                    }}
                  />
                </div>
              )}
              {restaurant.billing_city && (
                <div className="col-4">
                  <InputField
                    type="text"
                    placeholder="City"
                    label="City"
                    name="billing_city"
                    defaultValue={checkoutData.billingCity}
                    validation={{
                      required: "City is required",
                    }}
                  />
                </div>
              )}
              {restaurant.billing_state && (
                <div className="col-4">
                  <InputField
                    type="text"
                    placeholder="State"
                    label="State"
                    name="billing_state"
                    defaultValue={checkoutData.billingState}
                    validation={{
                      required: "State is required",
                    }}
                  />
                </div>
              )}
              {restaurant.billing_zip && (
                <div className="col-4">
                  <InputField
                    type="text"
                    placeholder="Postal Code"
                    label="Postal Code"
                    name="billing_zip"
                    defaultValue={checkoutData.billingZip}
                    validation={{
                      required: "Postal Code is required",
                    }}
                  />
                </div>
              )}
              {restaurant.billing_country && (
                <div className="col-12">
                  <InputField
                    type="text"
                    placeholder="Country"
                    label="Country"
                    name="billing_country"
                    defaultValue={checkoutData.billingCountry}
                    validation={{
                      required: "Country is required",
                    }}
                  />
                </div>
              )}
              <div className="col-md-12">
                <hr />
              </div>
            </>
          ) : null}
          {checkoutData.paymentType === "cc" && (
            <>
              <h5 className="mb-1">Payment Information</h5>
              <input
                type="hidden"
                name="payment_method_token"
                id="payment_method_token"
              />
              {acceptsGooglePay && (
                <NouriaGooglePayButton
                  transactionInfo={{
                    totalPriceStatus: "FINAL",
                    totalPriceLabel: "Total",
                    totalPrice: totalAmount.toString(),
                    currencyCode: "USD",
                    countryCode: "US",
                  }}
                  onLoadPaymentData={googlePayTokenHandler}
                />
              )}
              <div className="col-12">
                <InputField
                  type="text"
                  className="form-control"
                  name="full_name"
                  label="Name on Card"
                  placeholder="Name on Card..."
                  validation={{
                    required: "Name on Card is required",
                    minLength: {
                      value: 3,
                      message: "Name on Card is too short",
                    },
                  }}
                />
              </div>

              <div
                className={classNames("col-12", {
                  "has-danger": creditCardError,
                })}
              >
                <div className="hstack gap-1">
                  <label className="form-label me-auto">Card Number</label>
                  {restaurant.cards.indexOf("visa") > -1 && (
                    <span
                      className={`${
                        cardType === "visa" ? "opacity-100" : "opacity-50"
                      }`}
                    >
                      <VisaIcon />
                    </span>
                  )}
                  {restaurant.cards.indexOf("master") > -1 && (
                    <span
                      className={`${
                        cardType === "master" ? "opacity-100" : "opacity-50"
                      }`}
                    >
                      <MasterCardIcon />
                    </span>
                  )}
                  {restaurant.cards.indexOf("american_express") > -1 && (
                    <span
                      className={`${
                        cardType === "american_express"
                          ? "opacity-100"
                          : "opacity-50"
                      }`}
                    >
                      <AmericanExpressIcon />
                    </span>
                  )}
                  {restaurant.cards.indexOf("discover") > -1 && (
                    <span
                      className={`${
                        cardType === "discover" ? "opacity-100" : "opacity-50"
                      }`}
                    >
                      <DiscoveryIcon />
                    </span>
                  )}
                </div>
                <div
                  id="spreedly-number"
                  style={{ height: 45 }}
                  className={classNames("form-control", {
                    "is-invalid": creditCardError,
                  })}
                ></div>

                {creditCardError && (
                  <div className="invalid-feedback">{creditCardError}</div>
                )}
              </div>

              <div className="col-md-6">
                <InputField
                  type="text"
                  className="form-control"
                  name="expiration"
                  label="Expiration Date"
                  placeholder="MM/YYYY"
                  validation={{
                    required: "Expiration date is required",
                    minLength: {
                      value: 2,
                      message: "Please enter as MM/YYYY",
                    },
                    maxLength: {
                      value: 7,
                      message: "Please enter as MM/YYYY",
                    },
                    validate: (value: string) => {
                      const [month, year] = value.split("/");
                      if (!month || !year) {
                        return "Expiration should be in MM/YYYY format";
                      }
                      if (parseInt(month) > 12) {
                        return "That doesn't look like a valid month";
                      }

                      if (parseInt(year) < new Date().getFullYear()) {
                        return "This card is expired";
                      } else if (
                        parseInt(year) === new Date().getFullYear() &&
                        parseInt(month) < new Date().getMonth()
                      ) {
                        return "This card is expired";
                      }
                      if (parseInt(year) > new Date().getFullYear() + 10) {
                        return "This card is invalid (too far in the future)";
                      }
                      return true;
                    },
                  }}
                  formatter={(value) => {
                    value = value.replace(/\D/g, "");
                    if (value.length === 2) {
                      value = value + "/";
                    }
                    if (value.length > 2) {
                      return (
                        value.slice(0, 2) +
                        "/" +
                        value.slice(2).replace(/\D/g, "")
                      );
                    }
                    if (value.length > 7) {
                      return value.slice(0, 7);
                    }
                    return value;
                  }}
                />
              </div>

              <div
                className={classNames("col-md-6", {
                  "has-danger": CVVCardError,
                })}
              >
                <label className="form-label">CVV</label>
                <div
                  id="spreedly-cvv"
                  style={{ height: 45 }}
                  className={classNames("form-control", {
                    "is-invalid": CVVCardError,
                  })}
                ></div>
                {CVVCardError && (
                  <div className="invalid-feedback">{CVVCardError}</div>
                )}
              </div>
              <div className="col-md-12">
                <hr />
              </div>
            </>
          )}
          <div className="hstack gap-2">
            <button
              className="btn btn-secondary"
              onClick={() => {
                window.scrollTo(0, 0);
                clearErrors();
                checkoutBack();
              }}
            >
              <ArrorLeftIcon />
              Previous Step
            </button>
            <button
              id="submit-button"
              type="submit"
              className="btn btn-primary ms-auto"
              disabled={
                !ready ||
                isSubmitting ||
                !isValid ||
                processing ||
                (checkoutData.paymentType === "cc" &&
                  (typeof creditCardError == "string" ||
                    typeof CVVCardError == "string"))
              }
            >
              {isSubmitting || processing ? "Processing ..." : "Finish"}
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default CheckoutPayment;
