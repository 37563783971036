import GooglePayButton from "@google-pay/button-react";
import config from "config";

const NouriaGooglePayButton = ({
  transactionInfo,
  onCancel,
  onError,
  onLoadPaymentData,
}: {
  transactionInfo: google.payments.api.TransactionInfo;
  onCancel?: ((reason: google.payments.api.PaymentsError) => void) | undefined;
  onError?: ((error: Error | google.payments.api.PaymentsError) => void) | undefined;
  onLoadPaymentData: ((paymentData: google.payments.api.PaymentData) => void) | undefined;
}) => {
  return (
    <GooglePayButton
      environment={config.GOOGLE_PAY_ENVIRONMENT}
      buttonSizeMode="fill"
      buttonColor="white"
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
              allowedCardNetworks: [
                "AMEX",
                "DISCOVER",
                "JCB",
                "MASTERCARD",
                "VISA"
              ],
            },
            tokenizationSpecification: {
              type: "PAYMENT_GATEWAY",
              parameters: {
                gateway: "spreedly",
                gatewayMerchantId: config.SPREEDLY_GATEWAY_MERCHANT_ID,
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: config.GOOGLE_PAY_MERCHANT_ID,
          merchantName: config.GOOGLE_PAY_MERCHANT_NAME,
        },
        transactionInfo,
      }}
      onCancel={onCancel}
      onError={onError}
      onLoadPaymentData={onLoadPaymentData}
    />
  );
};
export default NouriaGooglePayButton;
