const ScooterIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.275 23.5169H2.26665C1.84165 23.5169 1.55832 23.2336 1.55832 22.9503C1.55832 18.1336 5.38332 14.3086 10.2 14.3086C15.0167 14.3086 18.8417 18.1336 18.8417 22.9503C18.8417 23.2336 18.5583 23.5169 18.275 23.5169ZM2.97499 22.2419H17.5667C17.2833 18.5586 14.025 15.5836 10.2 15.5836C6.37499 15.5836 3.25832 18.5586 2.97499 22.2419Z"
      fill="black"
    />
    <path
      d="M9.77498 29.4661C7.08332 29.4661 4.81665 27.1995 4.81665 24.5078H6.09165C6.09165 26.4911 7.64998 28.1911 9.77498 28.1911C11.9 28.1911 13.4583 26.6328 13.4583 24.5078H14.7333C14.5916 27.1995 12.4666 29.4661 9.77498 29.4661Z"
      fill="black"
    />
    <path
      d="M9.77497 25.9245C9.06664 25.9245 8.35831 25.3578 8.35831 24.5078H9.63331C9.63331 24.6495 9.91664 24.6495 9.91664 24.5078H11.1916C11.1916 25.3578 10.4833 25.9245 9.77497 25.9245Z"
      fill="black"
    />
    <path
      d="M27.3417 29.4661C24.65 29.4661 22.3833 27.1995 22.3833 24.5078H23.6583C23.6583 26.4911 25.3583 28.1911 27.3417 28.1911C29.325 28.1911 31.025 26.6328 31.025 24.5078H32.3C32.1583 27.1995 30.0333 29.4661 27.3417 29.4661Z"
      fill="black"
    />
    <path
      d="M27.3417 25.9245C26.4917 25.9245 25.925 25.3578 25.925 24.5078H27.2C27.2 24.6495 27.4833 24.6495 27.4833 24.5078H28.7583C28.6167 25.3578 28.05 25.9245 27.3417 25.9245Z"
      fill="black"
    />
    <path
      d="M14.025 13.4587H6.51665C6.23332 13.4587 5.94998 13.1753 5.94998 12.7503C5.94998 12.467 6.23332 12.042 6.51665 12.042H14.025C14.45 12.042 14.7333 12.3253 14.7333 12.7503C14.5916 13.1753 14.3083 13.4587 14.025 13.4587Z"
      fill="black"
    />
    <path
      d="M31.5916 23.5163H27.2C26.775 23.5163 26.4916 23.233 26.4916 22.9497V18.4163C26.4916 17.9913 26.775 17.708 27.2 17.708C30.0333 17.708 32.1583 19.9747 32.1583 22.808C32.3 23.233 32.0166 23.5163 31.5916 23.5163ZM27.9083 22.2413H31.025C30.7416 20.683 29.4666 19.408 27.9083 19.1247V22.2413Z"
      fill="black"
    />
    <path d="M27.7666 22.2412H20.1166V23.5162H27.7666V22.2412Z" fill="black" />
    <path
      d="M27.9083 20.8245H26.6333V12.7495C26.6333 11.7578 25.7833 11.0495 24.9333 11.0495C24.0833 11.0495 23.0916 11.7578 23.0916 12.7495V20.8245H21.8167V12.7495C21.8167 11.0495 23.2333 9.63281 24.9333 9.63281C26.6333 9.63281 27.9083 11.0495 27.9083 12.7495V20.8245Z"
      fill="black"
    />
    <path
      d="M25.5 7.3666H24.225C24.225 6.37493 23.375 5.52493 22.3833 5.52493H18.4167V4.3916H22.3833C24.0833 4.3916 25.5 5.6666 25.5 7.3666Z"
      fill="black"
    />
    <path
      d="M27.4833 11.0499C25.6416 11.0499 24.225 9.6332 24.225 7.79154C24.225 5.94987 25.6416 4.5332 27.4833 4.5332C28.05 4.5332 28.6166 4.67487 29.1833 4.9582C29.325 5.09987 29.4666 5.24154 29.4666 5.52487V10.0582C29.4666 10.3415 29.325 10.4832 29.1833 10.6249C28.6166 10.9082 28.1916 11.0499 27.4833 11.0499ZM27.4833 5.66654C26.4916 5.66654 25.5 6.6582 25.5 7.79154C25.5 9.2082 26.9166 10.1999 28.1916 9.6332V5.8082C28.05 5.8082 27.7666 5.66654 27.4833 5.66654Z"
      fill="black"
    />
  </svg>
);
export default ScooterIcon;