import { IDeliveryAddressRequest, updateDeliveryAddress } from "api/endpoints";
import InputField from "components/form/InputField";
import { ICheckoutData, useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

interface IDeliveryAddressFieldProps {}

interface IDeliveryAddressFieldInput {
  address: string;
}

const DeliveryAddressField: React.FC<IDeliveryAddressFieldProps> = () => {
  const { order, registerError, clearErrors, updateOrder } =
    useRestaurantContext();
  const { data, updateData } = useCheckoutContext();
  const methods = useForm<IDeliveryAddressFieldInput>({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const [inputInfo, setInputInfo] = useState<string>("");
  const [address, setAddress] = useState<IDeliveryAddressRequest>();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const addressFieldError = (message: string) => {
    methods.setError("address", {
      type: "manual",
      message: message,
    });
  };

  const validateAddress = async (data: IDeliveryAddressFieldInput) => {
    // Find the zip code
    clearErrors("actions.order.update");
    const findZip = data.address.match(/\b\d{5}\b/g);
    if (!findZip || !findZip[findZip.length - 1]) {
      addressFieldError("The address must contain a zip code.");
      return false;
    }
    let parsed: IDeliveryAddressRequest = {
      primaryAddress: data.address
        .replace(findZip[findZip.length - 1], "")
        .trim(),
      zip: findZip[findZip.length - 1],
    };
    const delivery = await updateDeliveryAddress(order.id, parsed).fetch();
    setAddress(parsed);
    if (delivery.error !== undefined) {
      registerError(delivery.error, "actions.order.update");
      if (
        delivery.error.includes("check zip code") &&
        delivery.delivery_data?.location.house &&
        delivery.delivery_data?.location.street
      ) {
        const { house, street, uzip } = delivery.delivery_data.location;
        const primaryAddress = `${house} ${street}`;
        const formatSuggestions = `${primaryAddress} ${uzip}`;
        methods.setValue("address", formatSuggestions);
        setInputInfo("We've updated your address. Does this look right?");
        setAddress({ primaryAddress, zip: uzip });
        return true;
      } else {
        addressFieldError(delivery.error);
        return false;
      }
    }
    setInputInfo("Looks great. Click confirm to proceed.");
    return true;
  };

  const onSubmit = async () => {
    if (!data) {
      return;
    }

    clearErrors("actions.order.update");

    if (!address) return;

    updateData({
      ...address,
    });

    await updateOrder({
      type: data.orderType,
      orderingFor: data.orderingFor,
      orderDate: data.orderDate,
      eta: data.eta,
      primaryAddress: address.primaryAddress,
      secondaryAddress: data.secondaryAddress,
      zip: address.zip,
    });
  };

  const formatAddress = (data: ICheckoutData) => {
    return `${data.primaryAddress} ${data.secondaryAddress ?? ""} ${
      data.zip ?? ""
    }`;
  };

  if (!data) {
    return <></>;
  }

  if (data.orderType !== "delivery") {
    return <></>;
  }

  return (
    <div className="vstack gap-4 mt-4 p-3 mb-4 bg-body-tertiary rounded-3">
      {data.primaryAddress !== "" && data.zip !== "" ? (
        <div className="hstack flex-wrap flex-md-nowrap column-gap-3 row-gap-2">
          <div className="me-auto">
            Your order will be delivered to: <br />
            <strong>{formatAddress(data)}</strong>
          </div>
          <div className="hstack gap-2">
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => updateData({ primaryAddress: "", zip: "" })}
            >
              Change Address
            </button>
          </div>
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="mb-1 fs-md">
              Where do you want your order delivered?
            </p>
            <div className="hstack flex-wrap flex-md-nowrap column-gap-3 row-gap-2">
              <div className="w-100">
                <InputField
                  className="form-control"
                  type="text"
                  label="Deliver to:"
                  name="address"
                  placeholder="10 My Address St, Apt 1, City, State, Zip Code..."
                  defaultValue={formatAddress(data)}
                  validation={{
                    required: "Address and Zip are required",
                    validate: (value: string) => {
                      const findZip = value.match(/\b\d{5}\b/g);
                      if (findZip && findZip[findZip.length - 1]) {
                        if(value.replace(findZip[findZip.length - 1], '').trim().length < 4) {
                          return "We need a street address to deliver.";
                        }
                        return true;
                      } else {
                        return "The address must contain a zip code.";
                      }
                    },
                  }}
                  onChange={() => {
                    setInputInfo("");
                    setIsChecked(false);
                  }}
                  validMessage={inputInfo}
                  AdditionalButton={
                    <UseAddressButton
                      isValid={isValid}
                      isSubmitting={isSubmitting}
                      onClick={async () => {
                        const data = methods.getValues();
                        setIsChecked(await validateAddress(data));
                      }}
                    />
                  }
                />
              </div>
            </div>
            {isValid && (
              <div className="d-grid mt-1 gap-1">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isValid || isSubmitting || !isChecked}
                >
                  Confirm
                </button>
              </div>
            )}
          </form>
        </FormProvider>
      )}
    </div>
  );
};

const UseAddressButton: React.FC<{
  isValid: boolean;
  isSubmitting: boolean;
  onClick: () => void;
}> = ({ isSubmitting, isValid, onClick }) => {
  return (
    <button
      className="btn btn-secondary btn-sm"
      disabled={!isValid || isSubmitting}
      onClick={onClick}
      type="button"
    >
      {isSubmitting ? "Checking..." : "Check Address"}
    </button>
  );
};
export default DeliveryAddressField;
