import ModernHomeIcon from "components/icons/ModernHome";
import ScooterIcon from "components/icons/Scooter";
import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import { useEffect } from "react";
import DeliveryAddressField from "./DeliveryAddressField";
import OrderTimeSelector from "./OrderTimeSelector";
import OrderTypeSelector from "./OrderTypeSelector";
interface IOrderBasicsSelectorProps {
  checkout?: boolean;
}

const OrderBasics: React.FC<IOrderBasicsSelectorProps> = ({ checkout }) => {
  const { data } = useCheckoutContext();
  const { restaurant, order, configBasic, setConfigBasic } =
    useRestaurantContext();
  useEffect(() => {
    if (restaurant && restaurant.accepting_orders) {
      let elemDiv = document.getElementById("modal-backdrop");
      if (!elemDiv) {
        elemDiv = document.createElement("div");
      }
      if (!configBasic) {
        elemDiv.id = "modal-backdrop";
        elemDiv.className = "modal-backdrop fade show";
        document.body.classList.add("modal-open");
        document.body.style.overflow = "hidden";
        document.body.style.paddingRight = "15px";
        document.body.appendChild(elemDiv);
      } else {
        document.body.classList.remove("modal-open");
        document.body.style.overflow = "";
        document.body.style.paddingRight = "";
        if (elemDiv) {
          try {
            document.body.removeChild(elemDiv);
          } catch (e) {
            //console.error(e);
          }
        }
      }
    }else{
      document.body.classList.remove("modal-open");
      document.body.style.overflow = "";
        document.body.style.paddingRight = "";
      let elemDiv = document.getElementById("modal-backdrop");
      if (elemDiv) {
        try {
          document.body.removeChild(elemDiv);
        } catch (e) {
          //console.error(e);
        }
      }
    }
  }, [configBasic, restaurant]);

  if (!restaurant || !order || !data) {
    return <div></div>;
  }
  if (configBasic === undefined) {
    return <>Checking</>;
  }
  if (configBasic) {
    return (
      <div className="hstack gap-2 mt-0 p-3 mb-4 bg-body-tertiary rounded-3">
        {data.orderType === "delivery" ? (
          <>
            <ScooterIcon />
            <p className="fs-md me-auto">
              <strong>Ordered From: </strong> {restaurant.name}
              <br />
              {order.ordering_for === "now" ? (
                <>
                  <strong>Delivery Time: </strong> About {order.time} minutes
                  after checkout
                </>
              ) : (
                <>
                  <strong>Delivery Date: </strong> {order.order_date}{" "}
                  {order.eta}
                </>
              )}
              <br />
              {order.location &&
                `Your order will be delivered to ${order.location.formatted_address}`}
            </p>
          </>
        ) : (
          <>
            <ModernHomeIcon />
            <p className="fs-md me-auto">
              <strong>Ordered From: </strong> {restaurant.name} <br />
              {order.ordering_for === "now" ? (
                <>
                  <strong>Ready:</strong> About {order.time} minutes after
                  checkout
                </>
              ) : (
                <>
                  <strong>Ready At: </strong> {order.order_date} {order.eta}
                </>
              )}
              <br />
              Your order will be waiting for you at {restaurant.address}{" "}
              {restaurant.zip}
            </p>
          </>
        )}

        <div className="d-grid gap-1">
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <a
            href="/checkout"
            onClick={async (e) => {
              e.preventDefault();
              setConfigBasic(false);
            }}
          >
            Change your Order
          </a>
        </div>
      </div>
    );
  }
  return (
    <div
      className="modal fade show"
      id="orderBasicsModal"
      tabIndex={-1}
      aria-labelledby="filterModalLabel"
      aria-hidden="false"
      aria-modal="true"
      style={{ display: "block" }}
      role="dialog"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <OrderTypeSelector checkout={checkout} />
            <DeliveryAddressField />
            <OrderTimeSelector />
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderBasics;
