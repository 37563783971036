declare global {
  interface Window {
    dataLayer: any;
  }
}

export type GAItem = {
  index: number;
  item_id: string;
  item_name: string;
  coupon?: string;
  discount?: number;
  item_brand: string;
  item_category: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  location_id: string;
  price: number;
  quantity: number;
};

export interface GAItemEvent {
  event:
  | "view_item"
  | "add_to_cart"
  | "view_cart"
  | "begin_checkout"
  | "purchase";
  menu_type: string;
  currency: string;
  store_location: string;
  value: number;
  tip?: number;
  tax?: number;
  items: Array<GAItem>;
  shipping?: number;
}

export interface GAPurchaseEvent extends GAItemEvent {
  transaction_id: string;
  coupon: string;
  tax: number;
  shipping: number;
}

export interface GAStartOrderEvent {
  menu_type: string;
  store_location: string;
}

export const gaStartOrderEvent = ({
  menu_type,
  store_location,
}: GAStartOrderEvent) => {
  window.dataLayer.push({
    event: "start_order",
    ecommerce: {
      menu_type,
      store_location,
    },
  });
};

/**
 * Get a list of items from a restaurant an order
 * @param restaurant
 * @param order
 * @returns
 */
export const itemsArray = (
  restaurant: any,
  order: any,
  gaResturantType: string
): GAItem[] => {
  let index = -1;
  const items = order?.items.map((item: any) => {
    index++;
    let total = 0;
    let item_total = item.price;
    if (item.options && item.options.length > 0) {
      item_total += item.options.reduce((acc: number, option: any) => {
        if (option.price) {
          return acc + option.price;
        } else if (option.base_price) {
          return acc + option.base_price;
        }
        return acc;
      }, 0);
    }

    total += parseFloat(item_total.toFixed(2));
    return {
      index,
      item_id: item.id,
      item_name: item.name,
      item_category: item.category,
      item_brand: gaResturantType,
      price: total,
      quantity: Number(item.quantity),
      location_id: restaurant?.id,
    };
  });
  return items;
};

/**
 * Get the total price of an order
 * @param order
 * @returns
 */
export const itemsTotal = (items: GAItem[]): number => {
  const total = items.reduce((acc, item) => {
    return acc + (item.price * item.quantity);
  }, 0);
  return parseFloat(total.toFixed(2));
};

export const gaItemEvent = ({
  event,
  menu_type,
  currency,
  store_location,
  value,
  tip,
  tax,
  shipping,
  items,
}: GAItemEvent) => {
  let ecommerce: {
    currency: string;
    value: number;
    menu_type: string;
    store_location: string;
    items: GAItem[];
    tip?: number;
    tax?: number;
    shipping?: number;
  } = {
    currency,
    value,
    menu_type,
    store_location,
    items,
  };
  if (tip) {
    ecommerce.tip = tip;
  }
  if (tax) {
    ecommerce.tax = tax;
  }
  if (shipping) {
    ecommerce.shipping = shipping;
  }
  window.dataLayer.push({
    event,
    ecommerce,
  });
};

export const gaPurchaseEvent = ({
  transaction_id,
  menu_type,
  currency,
  store_location,
  value,
  items,
  coupon,
  tax,
  tip,
  shipping,
}: GAPurchaseEvent) => {
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      currency,
      value,
      menu_type,
      store_location,
      transaction_id,
      coupon,
      tax,
      tip: tip || undefined,
      shipping,
      items,
    },
  });
};
