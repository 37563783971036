import classNames from "classnames";
import { useCheckoutContext } from "contexts/checkout-context";
import { useRestaurantContext } from "contexts/restaurant-context";
import _ from "lodash";
import { useState } from "react";

interface IOrderSummaryProps {}

const OrderSummary: React.FC<IOrderSummaryProps> = () => {
  const { order, applyCouponCode, removeCoupon } = useRestaurantContext();
  const { data } = useCheckoutContext();
  const [couponCode, setCouponCode] = useState<string>("");
  const [couponError, setCouponError] = useState<string>("");
  const [applying, setApplying] = useState<boolean>(false);

  if (!data) {
    return <></>;
  }

  return (
    <>
      <h5 className="fs-md">Summary</h5>
      <hr />
      <div className="vstack gap-1">
        <div className="hstack justify-content-between gap-2">
          <div>Subtotal:</div>
          <div className="fs-md text-text-hard">
            ${Number(order.subtotal).toFixed(2)}
          </div>
        </div>
        {order.active_coupons?.length > 0 &&
          order.active_coupons.map((activeCoupon: any) => (
            <div
              className="hstack justify-content-between gap-2"
              key={`coupon-${activeCoupon.id}`}
            >
              <div>
                Coupon ({activeCoupon.name}){" "}
                <a
                  href="#remove-order-coupon"
                  onClick={(e) => {
                    e.preventDefault();
                    removeCoupon(activeCoupon.id);
                  }}
                >
                  (remove)
                </a>
                :
              </div>
              {activeCoupon.discount > 0 ? (
                <div className="fs-md text-text-hard">
                  -${activeCoupon.discount}
                </div>
              ) : (
                <div className="fs-md text-text-hard">-</div>
              )}
            </div>
          ))}
        {order.type === "delivery" && (
          <div className="hstack justify-content-between gap-2">
            <div>Delivery fee:</div>
            <div className="fs-md text-text-hard">
              ${Number(order.delivery_fee).toFixed(2)}
            </div>
          </div>
        )}
        <div className="hstack justify-content-between gap-2">
          <div>Convenience Fee:</div>
          <div className="fs-md text-text-hard">
            ${Number(order.fee).toFixed(2)}
          </div>
        </div>
        <div className="hstack justify-content-between gap-2">
          <div>Tax:</div>
          <div className="fs-md text-text-hard">
            ${Number(order.tax).toFixed(2)}
          </div>
        </div>
        {order.active_taxes?.map((activeTax: any) => (
          <div
            key={activeTax.name}
            className="hstack justify-content-between gap-2"
          >
            <div>
              {activeTax.name} {activeTax.tax_id}:
            </div>
            <div className="fs-md text-text-hard">
              ${Number(activeTax.amount).toFixed(2)}
            </div>
          </div>
        ))}
        {!!data.tip && data.tip > 0 && (
          <div className="hstack justify-content-between gap-2">
            <div>Tip:</div>
            <div className="fs-md text-text-hard">${data.tip.toFixed(2)}</div>
          </div>
        )}
      </div>
      <hr />
      <div
        className={classNames("form-group", {
          // [`c-form-element--style-${elStyle}`]: elStyle,
          // 'c-form-element--file': type === 'file',
          "has-success": couponError === "",
          "has-danger": couponError !== "",
        })}
      >
        <label htmlFor="couponCode" className="form-label">
          Coupon Code
        </label>
        <div
          className={classNames("hstack gap-1", {
            "is-invalid": couponError !== "",
          })}
        >
          <input
            type="text"
            id="couponCode"
            className={classNames("form-control", {
              "is-invalid": couponError !== "",
            })}
            value={couponCode}
            onChange={(e) => {
              setCouponCode(e.currentTarget.value);
            }}
          />

          <button
            className="btn btn-secondary btn-sm"
            disabled={applying}
            onClick={async (e) => {
              e.preventDefault();
              setApplying(true);
              setCouponError("");
              if (!couponCode) {
                setCouponError("That doesn't look like a valid code");
              } else {
                const valid = await applyCouponCode(couponCode);
                if (!valid) {
                  setCouponError("That doesn't look like a valid code");
                }
              }
              setApplying(false);
            }}
          >
            {applying ? "Checking..." : "Apply"}
          </button>
        </div>
        {couponError !== "" && (
          <div className="invalid-feedback w-100">{couponError}</div>
        )}
        <div className="form-text">
          If you have a coupon code enter it above.
        </div>
      </div>
      <hr />
      <div className="hstack justify-content-between gap-2">
        <div className="fs-md">Total</div>
        <div className="h5 mb-0">
          ${_.round(order.total_amount + (data.tip ?? 0), 2).toFixed(2)}
        </div>
      </div>

      {order.type === "delivery" && (
        <>
          {order.delivery_type === "dsp" && order.dsp && (
            <>
              <hr />
              <div className="d-flex align-items-center">
                <div className="flex-grow-1">Delivery provided by:</div>
                <div>{order.dsp}</div>
              </div>
            </>
          )}
          {order.delivery_exp_display && (
            <>
              <hr />
              <div className="">
                <div>Delivery fee and time valid until:</div>
                <div>{order.delivery_exp_display}</div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default OrderSummary;
