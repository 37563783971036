import { getAutocomplete } from "api/endpoints";
import classNames from "classnames";
import { useLocationsContext } from "contexts/locations-context";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const LocationSearchForm = ({
  geolocationLoading,
  setGeolocationLoading,
}: {
  geolocationLoading: boolean;
  setGeolocationLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searchParams] = useSearchParams();
  const [localAddress, setLocalAddress] = useState(
    searchParams.get("address") || ""
  );
  const { doSearch } = useLocationsContext();
  const [autocompleteResults, setAutocompleteResults] = useState<{city: string, state: string}[]>([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteIndex, setAutocompleteIndex] = useState(-1);
  const autocompleteSearch = useCallback(async () => {
    // Get some autocomplete results
    setAutocompleteIndex(-1);
    if (localAddress !== "") {
      setAutocompleteLoading(true);
      const results = await getAutocomplete(localAddress).fetch();
      setAutocompleteResults(results);
      setAutocompleteLoading(false);
    } else {
      setAutocompleteResults([]);
    }
  }, [localAddress]);
  const autocompleteAdvance = () => {
    if (autocompleteIndex < autocompleteResults.length - 1) {
      setAutocompleteIndex(autocompleteIndex + 1);
    } else {
      setAutocompleteIndex(0);
    }
  };
  const autocompleteRetreat = () => {
    if (autocompleteIndex > 0) {
      setAutocompleteIndex(autocompleteIndex - 1);
    } else {
      setAutocompleteIndex(0);
    }
  };

  useEffect(() => {
    autocompleteSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAddress]);

  /**
   * Get the user's location from the browser geolocation API
   */
  const getLocation = () => {
    setGeolocationLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocalAddress(
            position.coords.latitude + ", " + position.coords.longitude
          );
          setGeolocationLoading(false);
          doSearch({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            address: undefined,
          });
        },
        () => {
          console.log("Geolocation not supported");
          setGeolocationLoading(false);
        }
      );
    } else {
      console.log("Geolocation not supported");
      setGeolocationLoading(false);
    }
  };
  const submit = (e: any) => {
    e.preventDefault();
    // Don't mix long/lat and address
    doSearch({
      address: localAddress,
      latitude: undefined,
      longitude: undefined,
    });
  };
  return (
    <>
      <div className="hstack justify-content-between flex-wrap column-gap-2 row-gap-1 mb-2">
        <h4 className="mb-0">Find a Location</h4>
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a className="fs-sm" href="#" onClick={() => getLocation()}>
          Use My Location
        </a>
      </div>
      <form>
        <div
          className={classNames([
            "input-group",
            "autocomplete-wrapper",
            autocompleteResults.length > 0 ? "autocomplete-active" : null,
          ])}
        >
          <input
            type="text"
            className="form-control"
            id="location-search"
            placeholder={
              geolocationLoading
                ? "Trying to find you..."
                : "Enter city, state or zip code..."
            }
            value={localAddress}
            disabled={geolocationLoading}
            onChange={(e) => setLocalAddress(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if(autocompleteIndex > -1 && autocompleteResults[autocompleteIndex]) {
                  e.preventDefault();
                  doSearch({
                    address: `${autocompleteResults[autocompleteIndex].city}, ${autocompleteResults[autocompleteIndex].state}`,
                    latitude: undefined,
                    longitude: undefined,
                  });
                  setLocalAddress(`${autocompleteResults[autocompleteIndex].city}, ${autocompleteResults[autocompleteIndex].state}`);
                  setAutocompleteResults([]);
                }else{
                  submit(e);
                }
                
              }
              if(e.key === "Up" || e.key === "ArrowUp") {
                autocompleteRetreat();
              }
              if(e.key === "Down" || e.key === "ArrowDown") {
                autocompleteAdvance();
              }
            }}
            onFocus={() => {
              if (localAddress === "Current Location") {
                setLocalAddress("");
              }
            }}
          />
          {autocompleteLoading && (
            <div className="autocomplete-loading">
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          {autocompleteResults.length > 0 && (
            <div className="autocomplete-results">
              <ul>
                {autocompleteResults.map((result: any, index: number) => (
                  <li
                    key={`${result.city}, ${result.state}`}
                    className={classNames([
                      index === autocompleteIndex ? "active" : null,
                    ])}
                    onClick={() => {
                      doSearch({
                        address: `${result.city}, ${result.state}`,
                        latitude: undefined,
                        longitude: undefined,
                      });
                      setLocalAddress(`${result.city}, ${result.state}`);
                      setAutocompleteResults([]);
                    }}
                  >
                    {result.city}, {result.state}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            id=""
            onClick={submit}
          >
            Search
          </button>
        </div>
        <p className="c-note mt-1">Enter city, state or zip code</p>
      </form>
    </>
  );
};
export default LocationSearchForm;
