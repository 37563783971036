import React, {
  forwardRef,
  useEffect,
  useState,
} from "react";

interface IDecimalInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  minValue: number;
  maxValue?: number;
  value?: number;
  decimalPlaces?: number;
  disabled?: boolean;
  prefix?: string;
  onChange?: (value: number, raw: string) => void;
}

const DecimalInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IDecimalInputProps
> = (
  {
    value: initialValue,
    minValue,
    maxValue,
    decimalPlaces = 2,
    disabled = false,
    onChange,
    ...rest
  },
  ref
) => {
  const [currentValue, setCurrentValue] = useState<string>(
    initialValue !== undefined
      ? initialValue.toFixed(decimalPlaces)
      : minValue.toFixed(decimalPlaces)
  );

  useEffect(() => {
    setCurrentValue(
      initialValue !== undefined
        ? initialValue.toFixed(decimalPlaces)
        : minValue.toFixed(decimalPlaces)
    );
  }, [initialValue, minValue, decimalPlaces]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    // Remove leading zeros
    newValue = newValue.replace(/^0+(?=\d)/, "");

    // If input value is empty, set it to minValue
    if (newValue === "") {
      const minValueStr = String(minValue);
      setCurrentValue(minValueStr);
      if (onChange) {
        onChange(minValue, minValueStr);
      }
    } else {
      // Regex to allow only numbers, dots, and one decimal point
      const regex = new RegExp(`^[0-9]+(.[0-9]{0,${decimalPlaces}})?$`);
      if (regex.test(newValue)) {
        newValue = newValue.replace(/[^\d.]/g, ".");
        // Apply constraint for maxValue if it's defined
        if (
          maxValue !== undefined &&
          !isNaN(parseFloat(newValue)) &&
          parseFloat(newValue) > maxValue
        ) {
          newValue = String(maxValue);
        }
        setCurrentValue(newValue);
        // Send value back to parent component if onChange callback is provided
        if (onChange) {
          onChange(parseFloat(newValue), newValue);
        }
      }
    }
  };

  return (
    <input
      ref={ref}
      className="form-control"
      type="text"
      value={currentValue}
      onChange={handleChange}
      placeholder="Enter a number"
      disabled={disabled}
      {...rest}
    />
  );
};

export default forwardRef(DecimalInput);
