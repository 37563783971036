import { FC } from "react";

const Breadcrumb: FC<{ items: { uri: string; title: string }[]; hero?: boolean }> = ({
  items,
  hero,
}) => {
  return (
    <div className={hero ? "hero-breadcrumbs" : "pt-2"}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                {items
                  .filter((item) => item.uri !== "")
                  .map((item, index) => (
                    <li key={index} className="breadcrumb-item">
                      <a href={item.uri}>{item.title}</a>
                    </li>
                  ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Breadcrumb;
